import 'dayjs/locale/en'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/es'
import 'dayjs/locale/x-pseudo'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(localizedFormat)

const mapLocale = shopLocale => {
	const code = shopLocale.toLowerCase()
	switch (code) {
		case 'zz':
			return 'x-pseudo'
		case 'es-mx':
			return 'es'
		default:
			return code
	}
}

const setDayjsDefaults = ({ shopTimeZone, tenantLocale }) => {
	dayjs.tz.setDefault(shopTimeZone)

	const locale = mapLocale(tenantLocale)
	dayjs.locale(locale)
}

export { dayjs, setDayjsDefaults }
