import axios from 'app/api/axios'
import {
	EnterpriseLaborRate,
	EnterpriseLaborRatePayload
} from './Models/LaborRateModel'

const URL = 'api/internal/enterprise_labor_rates'

export const getLaborRates = async (): Promise<EnterpriseLaborRate[]> => {
	const data = await axios.get(URL)
	return data.data
}

export const createLaborRate = async (
	laborRate: EnterpriseLaborRatePayload
) => {
	const data = await axios.post(URL, laborRate)
	return data.data
}

export const updateLaborRate = async (
	id: number,
	payload: EnterpriseLaborRatePayload
) => {
	const data = await axios.put(`${URL}/${id}`, payload)
	return data.data
}

export const deleteLaborRate = async (id: number) => {
	const data = await axios.delete(`${URL}/${id}`)
	return data.data
}
