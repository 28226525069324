import { useCallback, useState } from 'react'

import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import {
	EnterpriseCannedServices,
	EnterpriseCannedServiceHazmat,
	EnterpriseCannedServiceLabor,
	EnterpriseCannedServiceSublet,
	EnterpriseCannedServiceGenericPart,
	CannedService
} from 'app/api/Models/CannedServiceModel'
import { toDollars, toCents } from 'app/screens/cannedService/helper'

type ValidationInfo = {
	name: string
	key: string
	otherFieldValue: number
	newValue: number
	currentValue: number
}

export function useSubtotal() {
	const [invalidFieldKey, setInvalidFieldKey] = useState('')
	const { subtotals, fixedPrice, laborRateAmount, fixedPricinglaborSubtotal } =
		useCanneryServicesContext()

	/*
	 *	This function gets the subtotal of a table
	 */
	const getSubtotal = useCallback(
		(
			type: CannedService | string,
			newData: EnterpriseCannedServices[]
		): number => {
			switch (type) {
				case CannedService.HAZMATS:
					return newData
						.filter(item => !item._destroy)
						.reduce(
							(acc: number, item: EnterpriseCannedServiceHazmat) =>
								acc + Number(item.quantity) * Number(item.fee_cents),
							0
						)
				case CannedService.LABORS:
					return newData
						.filter(item => !item._destroy)
						.reduce(
							(acc: number, item: EnterpriseCannedServiceLabor) =>
								acc + Number(item.hours) * toCents(laborRateAmount),
							0
						)
				case CannedService.SUBLETS:
					return newData
						.filter(item => !item._destroy)
						.reduce(
							(acc: number, item: EnterpriseCannedServiceSublet) =>
								acc + Number(item.price_cents),
							0
						)
				case CannedService.GENERIC_PARTS:
					return newData
						.filter(item => !item._destroy)
						.reduce(
							(acc: number, item: EnterpriseCannedServiceGenericPart) =>
								acc + Number(item.quantity) * Number(item.price_cents),
							0
						)
				default:
					return 0
			}
		},
		[laborRateAmount]
	)

	/*
	 *	This function gets the labor subtotal when there is fixed pricing
	 */
	const getFixedPricingLaborSubtotal = useCallback(
		(type: CannedService | string, subtotal: number): number => {
			const updatedSubtals = { ...subtotals, [type]: subtotal }
			const hazmatsSub = updatedSubtals[CannedService.HAZMATS]
			const subletsSub = updatedSubtals[CannedService.SUBLETS]
			const partsSub = updatedSubtals[CannedService.GENERIC_PARTS]
			return fixedPrice - (hazmatsSub + subletsSub + partsSub)
		},
		[fixedPrice, subtotals]
	)

	const recalculateFixPricingLaborSubtotal = useCallback(
		(newFixedPrice: number): number => {
			const hazmatsSub = subtotals[CannedService.HAZMATS]
			const subletsSub = subtotals[CannedService.SUBLETS]
			const partsSub = subtotals[CannedService.GENERIC_PARTS]
			return newFixedPrice - (hazmatsSub + subletsSub + partsSub)
		},
		[subtotals]
	)

	/*
	 *	This function validates the input value.
	 *  It will trigger an error when it makes labor subtotal negative
	 */
	const subtotalValidator = useCallback(
		({
			name,
			key,
			otherFieldValue,
			newValue,
			currentValue
		}: ValidationInfo) => {
			const currentTotal = Number(otherFieldValue) * Number(currentValue)
			const newTotal = Number(otherFieldValue) * Number(newValue)
			const newFixedPricinglaborSubtotal =
				fixedPricinglaborSubtotal +
				toDollars(currentTotal) -
				toDollars(newTotal)

			if (newFixedPricinglaborSubtotal >= 0) {
				return Promise.resolve()
			}
			setInvalidFieldKey(`${key}-${name}`)
			return Promise.reject(new Error(''))
		},
		[setInvalidFieldKey, fixedPricinglaborSubtotal]
	)

	return {
		subtotalValidator,
		invalidFieldKey,
		setInvalidFieldKey,
		getSubtotal,
		getFixedPricingLaborSubtotal,
		recalculateFixPricingLaborSubtotal
	}
}
