import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import { useCurrency } from 'app/hooks/useCurrency'
import { CannedService } from 'app/api/Models/CannedServiceModel'
import {
	EnterpriseCannedServiceLabor,
	EnterpriseCannedServiceGenericPart
} from 'app/api/Models/CannedServiceModel'

import styles from './styles.module.scss'

const { Text } = Typography

function ServiceSummary() {
	const { t } = useTranslation()
	const { formatCurrency } = useCurrency()
	const { cannedServices, fixedPrice, subtotals, isFixedPrice } =
		useCanneryServicesContext()

	const parts = cannedServices[CannedService.GENERIC_PARTS].filter(
		part => !part._destroy
	) as EnterpriseCannedServiceGenericPart[]
	const labors = cannedServices[CannedService.LABORS].filter(
		labor => !labor._destroy
	) as EnterpriseCannedServiceLabor[]
	const totalLaborHours = labors
		.reduce((acc, item) => acc + Number(item.hours), 0)
		.toFixed(2)
	const totalPartQuantity = parts
		.reduce((acc, item) => acc + Number(item.quantity), 0)
		.toFixed(1)
	const subtotal = Object.values(subtotals).reduce(
		(acc: number, val: number) => acc + val
	) as number
	const formattedFixedPrice = fixedPrice
		? formatCurrency(String(fixedPrice))
		: null

	return (
		<Row className={styles.summaryContainer}>
			<Row className={styles.upperRow} justify="space-between">
				<Col>
					<Text>{t('canned_service.details.summary.labor')}</Text>
					<Text>{totalLaborHours}</Text>
				</Col>
				<Col>
					<Text>{t('canned_service.details.summary.parts')}</Text>
					<Text>{totalPartQuantity}</Text>
				</Col>
				<Col>
					<Text>{t('canned_service.details.summary.total_before_tax')} </Text>
					<Text>
						{isFixedPrice
							? formattedFixedPrice
							: formatCurrency(String(subtotal))}
					</Text>
				</Col>
			</Row>
			{isFixedPrice && (
				<Row className={styles.lowerRow}>
					<Text className={styles.fixedPriceText}>
						{t('canned_service.details.summary.fixed_price')}
					</Text>
					<Text className={styles.fixedPriceValue}>{formattedFixedPrice}</Text>
				</Row>
			)}
		</Row>
	)
}

export default ServiceSummary
