import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Col, Row } from 'antd'
import Checkbox from 'app/components/Checkbox/index'
import { useTranslation } from 'react-i18next'

import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'

const ApplyToAllROs = () => {
	const { t } = useTranslation()
	const {
		autoApplied,
		setAutoApplied,
		cannedServiceEdited,
		setCannedServiceEdited
	} = useCanneryServicesContext()

	const onChangeHandle = (e: CheckboxChangeEvent) => {
		setAutoApplied(e.target.checked)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	return (
		<Row gutter={[8, 0]}>
			<Col span={3} />
			<Col span={18}>
				<Checkbox checked={autoApplied} onChange={onChangeHandle}>
					{t('canned_service.details.apply_to_all_ros')}
				</Checkbox>
			</Col>
		</Row>
	)
}

export default ApplyToAllROs
