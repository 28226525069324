import { Col, Row, Typography, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import DropDownn from 'app/assets/icons/FA6/dropdown.svg'
import { useQuery } from 'react-query'

import { useCurrency } from 'app/hooks/useCurrency'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import {
	CannedService,
	EnterpriseCannedServiceLabor
} from 'app/api/Models/CannedServiceModel'
import { toDollars } from 'app/screens/cannedService/helper'
import { getLaborRates } from 'app/api/laborRate'

import styles from './styles.module.scss'

const { Text } = Typography

type Options = {
	value: string
	label: string
}

const LaborRate = () => {
	const { t } = useTranslation()
	const { formatCurrency } = useCurrency()
	const {
		isFixedPrice,
		setLaborRateAmount,
		setLaborRateId,
		laborRateId,
		setSubtotals,
		subtotals,
		cannedServices,
		cannedServiceEdited,
		setCannedServiceEdited
	} = useCanneryServicesContext()
	const { data: laborRates } = useQuery('getLaborRates', getLaborRates, {
		refetchOnWindowFocus: false
	})

	const handleChange = (value: string) => {
		const laborRate = laborRates.find(lb => String(lb.id) === value)
		const selectedRate = laborRate.amount_cents
		const laborSubtotal = cannedServices[CannedService.LABORS]
			.filter(item => !item._destroy)
			.reduce(
				(acc: number, item: EnterpriseCannedServiceLabor) =>
					acc + Number(item.hours) * selectedRate,
				0
			)
		setSubtotals({
			...subtotals,
			[CannedService.LABORS]: toDollars(laborSubtotal)
		})
		setLaborRateAmount(toDollars(selectedRate))
		setLaborRateId(laborRate.id)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	const getOptions = (): Options[] => {
		if (!laborRates) return []
		const options = laborRates.map(lb => {
			const label =
				lb.label === 'enterprise_standard_labor_rate'
					? t('enterprise_settings.labor_rates.enterprise_standard')
					: lb.label

			return {
				value: String(lb.id),
				label: `${label} ${formatCurrency(String(toDollars(lb.amount_cents)))}`
			}
		})
		if (!laborRateId) {
			return options
		} else {
			return [
				options.find(({ value }) => value === String(laborRateId)),
				...options.filter(({ value }) => value !== String(laborRateId))
			]
		}
	}

	const options = getOptions()

	return (
		<>
			{!isFixedPrice && (
				<Row gutter={[16, 0]} align="middle">
					<Col span={3} className={styles.rightAligned}>
						<Text>{t('canned_service.details.labor_rate')}</Text>
					</Col>
					<Col span={18}>
						<Select
							dropdownMatchSelectWidth={false}
							className={styles.customSelect}
							onChange={handleChange}
							options={options}
							value={laborRateId ? String(laborRateId) : options[0]?.value}
							suffixIcon={<ReactSVG src={DropDownn} />}
							onKeyDown={e => {
								if (e.key === ' ') {
									e.preventDefault()
								}
							}}
						/>
					</Col>
				</Row>
			)}
		</>
	)
}

export default LaborRate
