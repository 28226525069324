import axios from 'app/api/axios'
import { EnterpriseCannedServicePayload } from './Models/CannedServiceModel'

const URL = '/api/internal/enterprise_canned_services'

interface EnterpriseCannedService {
	title: String
}

const getCannedService = async (id: string) => {
	const url = `/api/internal/enterprise_canned_services/${id}`
	const response = await axios.get<EnterpriseCannedService>(url)
	return response.data
}

const createEnterpriseCannedService = async (data: EnterpriseCannedService) => {
	const url = '/api/internal/enterprise_canned_services/'

	const response = await axios.post(url, {
		enterprise_canned_service: {
			title: data.title
		}
	})

	return response.data
}

const saveService = async (
	id: string,
	data: EnterpriseCannedServicePayload
) => {
	const response = await axios.put(`${URL}/${id}`, data).then(res => res.data)
	return response.data
}

const deleteCannedService = (id: string) => {
	const url = `/api/internal/enterprise_canned_services/${id}`
	return axios.delete(url)
}

export {
	getCannedService,
	createEnterpriseCannedService,
	saveService,
	deleteCannedService
}
