import { Table, Form, Input, InputNumber, Button, Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'

import { useCurrency } from 'app/hooks/useCurrency'
import { toCents } from 'app/screens/cannedService/helper'
import styles from './styles.module.scss'
import { EnterpriseLaborRate } from 'app/api/Models/CannedServiceModel'
import { useFeatureFlags } from 'app/providers/LaunchDarklyProvider'

import FEATURE_FLAG_NAMES from 'feature-flags.json'

const { Summary } = Table
const { Cell, Row } = Summary

type Props = {
	handleAdd: (payload: EnterpriseLaborRate) => Promise<void>
	addingLaborRate: boolean
}

function TableSummary({ handleAdd, addingLaborRate }: Props) {
	const { t } = useTranslation()
	const { getCurrencySymbol } = useCurrency()
	const [form] = Form.useForm()

	const checkFeatureFlag = useFeatureFlags()
	const excludeFromLaborMatrixFlagEnabled = checkFeatureFlag(
		FEATURE_FLAG_NAMES.EXCLUDE_FROM_LABOR_MATRIX
	)

	const addItem = async () => {
		const values = await form.validateFields().catch(() => null)
		if (values === null) return

		try {
			await handleAdd({
				...values,
				amount_cents: toCents(values.amount_cents)
			})
			form.resetFields()
		} catch (e) {
			if (!e?.response?.data?.errors) throw new Error(e)

			const errors = e.response.data.errors
			const formDataWithErrors = Object.entries(values).map(([key, value]) => {
				return {
					name: key,
					errors: errors[key],
					value
				}
			})
			form.setFields(formDataWithErrors)
		}
	}

	return (
		<>
			{addingLaborRate ? (
				<>
					<Summary>
						<Form form={form} component={false}>
							<Row className={styles.tableSummaryRow}>
								<Cell index={0}>
									<Form.Item
										name="label"
										className={styles.formItem}
										validateTrigger="onBlur"
										rules={[
											{
												required: true,
												message: t('enterprise_settings.labor_rates.error_name')
											},
											() => ({
												validator(_, value) {
													if (
														value.toLowerCase().trim() ===
															'enterprise_standard_labor_rate' ||
														value.toLowerCase().trim() === 'enterprise standard'
													) {
														return Promise.reject(
															t(
																'enterprise_settings.labor_rates.enterprise_standard_label_error'
															)
														)
													} else if (
														value.toLowerCase().trim() === 'standard'
													) {
														return Promise.reject(
															t(
																'enterprise_settings.labor_rates.standard_label_error'
															)
														)
													}
													return Promise.resolve()
												}
											})
										]}>
										<Input
											data-testid="new-row-name"
											placeholder={t(
												'enterprise_settings.labor_rates.empty_name'
											)}
											onPressEnter={addItem}
										/>
									</Form.Item>
								</Cell>
								<Cell index={1}>
									<Form.Item
										name="amount_cents"
										className={styles.formItem}
										validateTrigger="onBlur"
										initialValue={0.0}
										rules={[
											{
												required: true,
												message: t(
													'enterprise_settings.labor_rates.error_amount'
												)
											},
											() => ({
												validator(_, value) {
													if (value < 0) {
														return Promise.reject(
															t(
																'enterprise_settings.labor_rates.error_negative'
															)
														)
													}
													return Promise.resolve()
												}
											})
										]}>
										<InputNumber
											data-testid="new-row-amount"
											type="number"
											precision={2}
											prefix={getCurrencySymbol()}
											step={0.01}
											controls={true}
											placeholder={t(
												'enterprise_settings.labor_rates.empty_price'
											)}
											onPressEnter={addItem}
										/>
									</Form.Item>
								</Cell>
								{excludeFromLaborMatrixFlagEnabled && (
									<Cell index={2}>
										<Form.Item
											name="exclude_from_labor_matrix"
											valuePropName="checked"
											className={styles.formItem}>
											<Checkbox>
												{t('enterprise_settings.labor_rates.excluded')}
											</Checkbox>
										</Form.Item>
									</Cell>
								)}
								<Cell
									index={excludeFromLaborMatrixFlagEnabled ? 3 : 2}
									className={styles.actionColumn}>
									<Button
										onClick={addItem}
										type="link"
										className={styles.customLink}
										data-testid="save-button">
										{t('enterprise_settings.save')}
									</Button>
								</Cell>
							</Row>
						</Form>
					</Summary>
				</>
			) : (
				<></>
			)}
		</>
	)
}

export default TableSummary
