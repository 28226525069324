import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { redirectToLogin } from '@shop-ware/auth-flow'
import {
	getAuthExpireAtValue,
	getAuthRefreshToken,
	getIdentityContext,
	updateAuthInfo,
	clearAuthInfo,
	getAuthConfig
} from 'app/providers/AuthProvider/utils'
import { dayjs } from 'day'
import { BASE_URL, APPLICATION_ID } from 'app/utils/constants'
import { getBaseURL } from './utils'
import { refreshTokens } from '@shop-ware/auth-flow/browser'

interface AxiosConfigCustom extends AxiosRequestConfig {
	requestRetries: number
}

const AUTH_CONFIG = getAuthConfig()

const axiosInstance = axios.create({
	baseURL: getBaseURL(),
	headers: {
		Accept: 'application/json'
	}
})

export const AXIOS_REQUEST_RETRIES = 0

export const setAxiosHeaders = (headers: object) => {
	Object.assign(axiosInstance.defaults.headers.common, headers)
}

export const setTenantSubDomain = (subDomain: string) => {
	if (!subDomain) {
		return
	}
	axiosInstance.defaults.baseURL = axiosInstance.defaults.baseURL?.replace(
		'app.',
		`${subDomain}.`
	)
}

export const clearAxiosAuthSettings = () => {
	Object.assign(axiosInstance.defaults.headers.common, {
		Authorization: '',
		'Refresh-Token': ''
	})
	axiosInstance.defaults.baseURL = BASE_URL
}

axiosInstance.interceptors.response.use(
	response => response,
	async (error: AxiosError) => {
		const axiosConfig: AxiosConfigCustom = error.config as AxiosConfigCustom

		const expireAtNum = getAuthExpireAtValue()
		const dayjsUnix = dayjs().valueOf()
		const errorCode: number | undefined = error.response?.status

		if (errorCode === 401 || expireAtNum < dayjsUnix) {
			try {
				const oldIdentityContext = getIdentityContext()
				const oldRefreshToken = getAuthRefreshToken()
				const {
					accessToken,
					refreshToken,
					identityContext,
					accessTokenExpiration
				} = await refreshTokens(
					oldIdentityContext,
					oldRefreshToken,
					AUTH_CONFIG
				)
				updateAuthInfo({
					accessToken,
					refreshToken,
					identityContext,
					accessTokenExpiration
				})

				axiosConfig.headers = {
					Authorization: `Bearer ${accessToken}`
				}

				setAxiosHeaders({ Authorization: `Bearer ${accessToken}` })
			} catch (e) {
				clearAuthInfo()
				redirectToLogin(APPLICATION_ID, BASE_URL, AUTH_CONFIG)
				return Promise.reject('Unable to refresh tokens')
			}
		}

		if (
			axiosConfig.requestRetries === null ||
			axiosConfig.requestRetries === undefined
		) {
			axiosConfig.requestRetries = AXIOS_REQUEST_RETRIES
		}

		if (axiosConfig.requestRetries <= 0) {
			return Promise.reject(error)
		}

		axiosConfig.requestRetries--
		return axiosInstance(axiosConfig)
	}
)

export default axiosInstance
