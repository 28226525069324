import { Checkbox as AntdCheckbox } from 'antd'
import type { CheckboxProps as AntdCheckboxProps } from 'antd'

export type CheckboxProps = AntdCheckboxProps

const Checkbox = ({ ...props }: CheckboxProps) => (
	<AntdCheckbox
		onKeyPress={e => {
			if (e.key === 'Enter') {
				;(e.target as HTMLElement).click()
			}
		}}
		{...props}
	/>
)

export default Checkbox
