import * as LDClient from 'launchdarkly-js-client-sdk'
import PageLoader from 'app/components/PageLoader'
import { createContext, useContext, useState, useEffect } from 'react'
import { useForcedRerender } from 'app/hooks/useForcedRerender'
import { LAUNCH_DARKLY_CLIENT_SIDE_ID } from 'app/utils/constants'

import type { LDClient as ClientType } from 'launchdarkly-js-client-sdk'

const LaunchDarklyContext = createContext<{
	ldClient: ClientType
}>(undefined)

interface IProps {
	userID?: string
	children: React.ReactNode
}

function LaunchDarklyProvider(props: IProps) {
	const [launchDarklyClient, setLaunchDarklyClient] = useState<ClientType>()
	const forceRerender = useForcedRerender()

	useEffect(() => {
		const user = {
			kind: 'user',
			key: props.userID?.toString() || 'anonymous-user'
		}
		const client: ClientType = LDClient.initialize(
			LAUNCH_DARKLY_CLIENT_SIDE_ID,
			user,
			{
				streaming: true,
				bootstrap: 'localStorage'
			}
		)

		setLaunchDarklyClient(client)

		const onReadyHandler = () => {
			forceRerender()
		}

		const onChangeHandler = () => {
			forceRerender()
		}

		client.on('ready', onReadyHandler)
		client.on('change', onChangeHandler)

		return () => {
			client.off('ready', onReadyHandler)
			client.off('change', onChangeHandler)
			client.close()
		}
	}, [props.userID, forceRerender])

	if (!launchDarklyClient) return <PageLoader />

	return (
		<LaunchDarklyContext.Provider value={{ ldClient: launchDarklyClient }}>
			{props.children}
		</LaunchDarklyContext.Provider>
	)
}

function useLaunchDarkly() {
	const context = useContext(LaunchDarklyContext)

	if (context === undefined) {
		throw new Error(
			'useLaunchDarkly must be used within a LaunchDarklyProvider'
		)
	}
	return context
}

function useLaunchDarklyClient() {
	const context = useContext(LaunchDarklyContext)

	if (context === undefined) {
		throw new Error(
			'useLaunchDarklyClient must be used within a LaunchDarklyProvider'
		)
	}
	return context.ldClient
}

function useFeatureFlags() {
	const context = useContext(LaunchDarklyContext)

	if (context === undefined) {
		throw new Error(
			'useFeatureFlags must be used within a LaunchDarklyProvider'
		)
	}
	return (flagKey: string): boolean => {
		return context.ldClient ? context.ldClient.variation(flagKey, false) : false
	}
}

export {
	LaunchDarklyProvider,
	useLaunchDarkly,
	useFeatureFlags,
	useLaunchDarklyClient,
	LaunchDarklyContext
}
