import {
	DeleteOutlined,
	DownOutlined,
	ExclamationCircleOutlined
} from '@ant-design/icons'
import { Col, Dropdown, Modal, notification } from 'antd'
import type { MenuProps } from 'antd'
import { useTranslation } from 'react-i18next'

import * as cannedServicesApi from 'app/api/enterpriseCannedServices'
import Button from 'app/components/Button'

import styles from './styles.module.scss'

type ActionsProps = {
	selectedcannedServicesIds: string[]
	refreshList: () => void
	numOfAffectedShops: number
	setSelectedcannedServicesIds: (serviceIds: string[]) => void
}

function Actions({
	selectedcannedServicesIds,
	refreshList,
	numOfAffectedShops,
	setSelectedcannedServicesIds
}: ActionsProps) {
	const { t } = useTranslation()
	const [modal, contextHolder] = Modal.useModal()

	const onDeleteHandle = async () => {
		const { service_id } = await cannedServicesApi.deleteCannedServices(
			selectedcannedServicesIds
		)
		setSelectedcannedServicesIds([])
		notification.success({
			key: service_id,
			message: t('canned_services.actions.canned_services_deleted', {
				services: selectedcannedServicesIds.length,
				shops: numOfAffectedShops
			}),
			btn: (
				<Button type="link" onClick={() => refreshList()}>
					{t('canned_services.actions.click_to_refresh')}.
				</Button>
			),
			duration: 0
		})
	}

	const items: MenuProps['items'] = [
		{
			key: 'delete',
			label: t('canned_services.actions.delete_canned_services'),
			icon: <DeleteOutlined />,
			onClick: () => showConfirmationDeleteModal()
		}
	]

	const showConfirmationDeleteModal = () => {
		modal.confirm({
			title: t('canned_services.actions.confirm_delete', {
				services: selectedcannedServicesIds.length,
				shops: numOfAffectedShops
			}),
			maskClosable: true,
			okText: t('canned_services.actions.delete'),
			cancelText: t('canned_services.actions.cancel'),
			onOk: onDeleteHandle,
			icon: <ExclamationCircleOutlined />
		})
	}

	return (
		<Col className={styles.actions}>
			<Dropdown
				trigger={['click']}
				menu={{ items }}
				placement="bottomRight"
				disabled={!selectedcannedServicesIds.length}>
				<Button data-testid="actionsButton">
					{t('canned_services.actions.actions')} <DownOutlined />
				</Button>
			</Dropdown>
			{contextHolder}
		</Col>
	)
}

export default Actions
