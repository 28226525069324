import { useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import {
	Button,
	Dropdown,
	InputNumber,
	Modal,
	Typography,
	Row,
	Col,
	Form
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useCurrency } from 'app/hooks/useCurrency'

import { toDollars } from 'app/screens/cannedService/helper'

import { useToggle } from 'app/hooks/useToggle'

import styles from './styles.module.scss'
import {
	EnterpriseCannedServiceGenericPart,
	CannedService
} from 'app/api/Models/CannedServiceModel'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'

const { Title } = Typography

export type Part = {
	name: string
	quantity: string
	price_cents: number
	max_quantity: string
	max_quantity_charge_per_item_cents: number
	max_cost_excess_multiplier: string
	max_cost_excess_flat_amount_cents: number
	max_cost_price_cents: number
}

export type LimitModalProps = {
	part: EnterpriseCannedServiceGenericPart & { key: string }
}

const LIMIT_ATTRS = [
	'max_quantity',
	'max_cost_excess_multiplier',
	'max_quantity_charge_per_item_cents',
	'max_cost_price_cents',
	'max_cost_excess_flat_amount_cents'
]

function LimitModal({ part }: LimitModalProps) {
	const { t } = useTranslation()
	const { getCurrencySymbol } = useCurrency()

	const [form] = Form.useForm()
	const { updateLimit } = useDataSource(CannedService.GENERIC_PARTS, form)

	const [modalOpen, toggleModal] = useToggle(false)
	const [maxCost, setMaxCost] = useState<string>(
		part['max_cost_excess_multiplier'] ? 'multiplier' : 'flat'
	)

	const [buttonDisabled, setButtonDisabled] = useState(false)

	function cancelModal() {
		form.resetFields(LIMIT_ATTRS)
		toggleModal()
	}

	const hasLimit = LIMIT_ATTRS.some((attr: string) => part[attr])

	async function confirmModal() {
		var didFieldChange = form.isFieldsTouched()
		const newVals = form.getFieldsValue(LIMIT_ATTRS)

		if (maxCost === 'flat' && part['max_cost_excess_multiplier']) {
			newVals['max_cost_excess_multiplier'] = null
			didFieldChange = true
		}

		if (maxCost === 'multiplier' && part['max_cost_excess_flat_amount_cents']) {
			newVals['max_cost_excess_flat_amount_cents'] = null
			didFieldChange = true
		}

		if (newVals['max_cost_excess_multiplier'] === 0) {
			newVals['max_cost_excess_multiplier'] = null
		}

		if (newVals['max_cost_excess_flat_amount_cents'] === 0) {
			newVals['max_cost_excess_flat_amount_cents'] = null
		}

		if (didFieldChange) {
			updateLimit(part, newVals)
		}
		toggleModal()
	}

	const disableButton = () => {
		form
			.validateFields()
			.then(response => {
				setButtonDisabled(false)
			})
			.catch(err => setButtonDisabled(err.errorFields.length > 0))
	}

	const items = [
		{
			key: 'flat',
			label: t('canned_service.limit_modal.flat_cost'),
			onClick: () => {
				setMaxCost('flat')
				setTimeout(disableButton, 300)
			}
		},
		{
			key: 'multiplier',
			label: t('canned_service.limit_modal.multiplier'),
			onClick: () => {
				setMaxCost('multiplier')
				setTimeout(disableButton, 300)
			}
		}
	]

	const getFieldLabel = () => {
		if (maxCost === 'flat') {
			return t('canned_service.limit_modal.amount_field')
		} else if (maxCost === 'multiplier') {
			return t('canned_service.limit_modal.multiplier_field')
		}
	}

	const maxQuantitySection = (
		<div>
			<Typography.Text>
				{t('canned_service.limit_modal.description', { name: part.name })}
			</Typography.Text>
			<Row>
				<Typography.Text>
					<Title level={4}>
						{t('canned_service.limit_modal.max_quant_table')}
					</Title>
					<div className={styles.sectionDesc}>
						{t('canned_service.limit_modal.quant_desc', { name: part.name })}
					</div>
				</Typography.Text>
			</Row>
			{/* titles */}
			<div className={styles.innerContent}>
				<Row>
					<Col span={4}>
						<Typography.Text className={styles.tableHeader}>
							{t('canned_service.limit_modal.quant')}
						</Typography.Text>
					</Col>

					<Col span={4}>
						<Typography.Text className={styles.tableHeader}>
							{t('canned_service.limit_modal.quant_table_header')}
						</Typography.Text>
					</Col>

					<Col span={8}>
						<Typography.Text className={styles.tableHeader}>
							{t('canned_service.limit_modal.charge')}
						</Typography.Text>
					</Col>
				</Row>
				{/* values/inputs */}
				<div className={styles.maxQuantityInputs}>
					<Row>
						<Col span={4}>
							<InputNumber
								data-testid="part-quantity-field"
								disabled={true}
								value={part.quantity}
							/>
						</Col>

						<Col span={4}>
							<Form.Item
								name={'max_quantity'}
								rules={[
									{
										validator(_, value) {
											if (
												!value &&
												form.getFieldValue('max_quantity_charge_per_item_cents')
											) {
												return Promise.reject(
													t('canned_service.limit_modal.max_quantity_error')
												)
											}
											return Promise.resolve()
										}
									}
								]}
								initialValue={part.max_quantity}>
								<InputNumber
									data-testid="max-quantity-field"
									step={0.01}
									precision={2}
									min={0.01}
									onChange={value => form.setFieldValue('max_quantity', value)}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name={'max_quantity_charge_per_item_cents'}
								rules={[
									{
										validator(_, value) {
											if (!value && form.getFieldValue('max_quantity')) {
												return Promise.reject(
													t('canned_service.limit_modal.sell_price_error')
												)
											}
											return Promise.resolve()
										}
									}
								]}
								initialValue={
									part.max_quantity_charge_per_item_cents &&
									toDollars(part.max_quantity_charge_per_item_cents)
								}>
								<InputNumber
									data-testid="max-quantity-charge-field"
									step={0.01}
									prefix={getCurrencySymbol()}
									precision={2}
									min={0.01}
									onChange={value =>
										form.setFieldValue(
											'max_quantity_charge_per_item_cents',
											value
										)
									}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)

	const maxCostSection = (
		<div>
			<Row className={styles.maxCostTitle}>
				<Typography.Text>
					<Title className={styles.maxCostTitle} level={4}>
						{t('canned_service.limit_modal.max_cost_table')}
					</Title>
					<div className={styles.sectionDesc}>
						{maxCost === 'flat'
							? t('canned_service.limit_modal.cost_desc_flat')
							: t('canned_service.limit_modal.cost_desc_multiple')}
					</div>
				</Typography.Text>
			</Row>
			{/* titles */}
			<div className={styles.innerContent}>
				<Row>
					<Col span={4}>
						<Typography.Text className={styles.tableHeader}>
							{t('canned_service.limit_modal.cost_table_header')}
						</Typography.Text>
					</Col>

					<Col span={5}>
						<Typography.Text className={styles.tableHeader}>
							{t('canned_service.limit_modal.cost_type')}
						</Typography.Text>
					</Col>

					<Col span={4}>
						<Typography.Text className={styles.tableHeader}>
							{getFieldLabel()}
						</Typography.Text>
					</Col>
				</Row>
				{/* values/inputs */}
				<div className={styles.maxQuantityInputs}>
					<Row>
						<Col span={4}>
							<Form.Item
								name={'max_cost_price_cents'}
								rules={[
									() => ({
										validator(_, value) {
											if (maxCost === 'flat') {
												if (
													!value &&
													form.getFieldValue(
														'max_cost_excess_flat_amount_cents'
													)
												) {
													return Promise.reject(
														t('canned_service.limit_modal.max_cost_error')
													)
												}
											} else if (maxCost === 'multiplier') {
												if (
													!value &&
													form.getFieldValue('max_cost_excess_multiplier')
												) {
													return Promise.reject(
														t('canned_service.limit_modal.max_cost_error')
													)
												}
											}
											return Promise.resolve()
										}
									})
								]}
								initialValue={
									part.max_cost_price_cents &&
									toDollars(part.max_cost_price_cents)
								}>
								<InputNumber
									data-testid="max-cost-field"
									prefix={getCurrencySymbol()}
									step={0.01}
									precision={2}
									min={0.01}
									onChange={value =>
										form.setFieldValue('max_cost_price_cents', value)
									}
								/>
							</Form.Item>
						</Col>

						<Col span={5}>
							<Dropdown
								trigger={['click']}
								menu={{ items }}
								placement="bottomRight"
								disabled={false}>
								<Button>
									{maxCost === 'flat'
										? t('canned_service.limit_modal.flat_cost')
										: t('canned_service.limit_modal.multiplier')}
									<DownOutlined />
								</Button>
							</Dropdown>
						</Col>

						<Col span={4}>
							{maxCost === 'flat' ? (
								<Form.Item
									name={'max_cost_excess_flat_amount_cents'}
									rules={[
										() => ({
											validator(_, value) {
												if (
													!value &&
													form.getFieldValue('max_cost_price_cents')
												) {
													return Promise.reject(
														t('canned_service.limit_modal.flat_amount_error')
													)
												}
												return Promise.resolve()
											}
										})
									]}
									initialValue={
										part.max_cost_excess_flat_amount_cents &&
										toDollars(part.max_cost_excess_flat_amount_cents)
									}>
									<InputNumber
										data-testid="max-cost-excess-flat-amount-field"
										prefix={getCurrencySymbol()}
										step={0.01}
										precision={2}
										min={0.01}
										onChange={value =>
											form.setFieldValue(
												'max_cost_excess_flat_amount_cents',
												value
											)
										}
									/>
								</Form.Item>
							) : (
								<Form.Item
									name={'max_cost_excess_multiplier'}
									initialValue={part.max_cost_excess_multiplier}
									rules={[
										{
											validator(_, value) {
												if (
													!value &&
													form.getFieldValue('max_cost_price_cents')
												) {
													return Promise.reject(
														t('canned_service.limit_modal.markup_error')
													)
												}
												return Promise.resolve()
											}
										}
									]}>
									<InputNumber
										data-testid="max-cost-excess-multiplier-field"
										addonAfter={'x'}
										step={0.01}
										precision={2}
										min={0.01}
										onChange={value =>
											form.setFieldValue('max_cost_excess_multiplier', value)
										}
									/>
								</Form.Item>
							)}
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)

	return (
		<>
			<Button
				onClick={toggleModal}
				type="link"
				className={styles.customLink}
				data-testid="add-button">
				{hasLimit
					? t('canned_service.edit_limit')
					: t('canned_service.set_limit')}
			</Button>
			<Modal
				open={modalOpen}
				closable={false}
				cancelButtonProps={{ type: 'link' }}
				maskClosable={true}
				title={t('canned_service.limit_modal.title', { name: part.name })}
				onOk={confirmModal}
				okText={t('canned_service.limit_modal.set_limit')}
				okButtonProps={{ disabled: buttonDisabled }}
				cancelText={t('canned_service.limit_modal.cancel')}
				onCancel={cancelModal}
				width={800}
				getContainer=".react-app">
				<Form
					form={form}
					onValuesChange={() => {
						disableButton()
					}}>
					{maxQuantitySection}
					{maxCostSection}
				</Form>
			</Modal>
		</>
	)
}

export default LimitModal
