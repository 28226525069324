import { createContext, useState, useEffect, useCallback } from 'react'
import { Form, Popconfirm, Button, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import EMTable from 'app/components/EMTable'
import EditableCell from './EditableCell'
import TableSummary from './TableSummary'
import type { FormInstance } from 'antd/es/form'
import * as api from 'app/api/categories'
import TrashCan from 'app/assets/icons/FA6/trash-can.svg'
import axios from 'app/api/axios'
import { EnterpriseCategory } from 'app/api/Models/CategoryModel'
import styles from './styles.module.scss'

type EnterpriseSettingsCategoryWithKey = EnterpriseCategory & {
	key: string
}

type Props = {
	addingCategory: boolean
	toggleAddCategory: () => void
}

const { Text } = Typography

export const EditableContext = createContext<FormInstance<any> | null>(null)

const CategoriesTable = ({ addingCategory, toggleAddCategory }: Props) => {
	const [dataSource, setDataSource] = useState<EnterpriseCategory[]>([])
	const [editingCategories, setEditingCategories] = useState([])

	const { t } = useTranslation()
	const [form] = Form.useForm()

	const popConfirmDescription = () => {
		return (
			<div>
				<Text>
					{t('enterprise_settings.categories.delete_description_one')}
				</Text>
				<div>
					<div>
						{t('enterprise_settings.categories.delete_description_two')}
					</div>
					<ul className={styles.popConfirmUl}>
						<li>
							{t('enterprise_settings.categories.delete_description_three')}
						</li>
						<li>
							{t('enterprise_settings.categories.delete_description_four')}
						</li>
					</ul>
				</div>
			</div>
		)
	}

	const popConfirmTitle = () => {
		return (
			<div className={styles.popConfirmTitle}>
				{t('enterprise_settings.categories.delete_row_title')}
			</div>
		)
	}

	const fetchCategories = useCallback(() => {
		;(async function () {
			const response = await api.getCategories()
			const sortedCategories = response.categories.sort((a, b) => a.id - b.id)
			setDataSource(sortedCategories)
		})()
	}, [])

	useEffect(() => {
		fetchCategories()
	}, [fetchCategories])

	const dataSourceWithKeys: EnterpriseSettingsCategoryWithKey[] =
		dataSource.map(item => ({
			...item,
			key: item.id.toString(),
			id: item.id
		}))

	const handleDelete = (id: number) => {
		axios.delete(`/api/internal/categories/${id}`).then(response => {
			fetchCategories()
		})
	}

	const handleAdd = (record: EnterpriseSettingsCategoryWithKey) => {
		axios
			.post('/api/internal/categories', {
				category: { text: record.text }
			})
			.then(response => {
				toggleAddCategory()
				fetchCategories()
			})
	}

	const updateCategory = async (recordId: number) => {
		const values = await form.validateFields()
		axios
			.put(`/api/internal/categories/${recordId}`, {
				category: { text: values.text }
			})
			.then(() => {
				fetchCategories()
				form.resetFields()
			})
		updateEditingCategory(recordId)
	}

	const updateEditingCategory = (recordId: number) => {
		if (editingCategories.includes(recordId)) {
			form.resetFields()
			setEditingCategories([])
		} else {
			form.resetFields()
			setEditingCategories([recordId])
		}
	}

	const COLUMNS = [
		{
			className: styles.titleColumn,
			title: t('enterprise_settings.categories.title'),
			dataIndex: 'text',
			render: (val, record) => (
				<EditableCell
					dataIndex="text"
					record={record}
					value={val}
					type="text"
					editing={editingCategories.includes(record.id)}
					updateCategory={updateCategory}
				/>
			)
		},
		{
			title: t('enterprise_settings.actions'),
			className: styles.actionColumn,
			width: '10%',
			render: (_, record) => (
				<>
					{editingCategories.includes(record.id) ? (
						<Button
							id={record.id}
							onClick={() => updateCategory(record.id)}
							type="link"
							data-testid="save-button">
							{t('enterprise_settings.save')}
						</Button>
					) : (
						<Button
							id={record.id}
							data-testid={`edit-button-${record.id}`}
							onClick={() => updateEditingCategory(record.id)}
							type="link">
							{t('enterprise_settings.edit')}
						</Button>
					)}
					<Popconfirm
						overlayClassName={styles.popConfirm}
						title={popConfirmTitle}
						description={popConfirmDescription}
						okText={t('enterprise_settings.delete')}
						cancelText={t('enterprise_settings.cancel')}
						onConfirm={() => handleDelete(record.id)}
						data-testid={`delete-modal`}>
						<ReactSVG
							src={TrashCan}
							className={styles.deleteIcon}
							data-testid={`delete-icon-${record.id}`}
						/>
					</Popconfirm>
				</>
			)
		}
	]

	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<EMTable
					className={styles.categoriesTable}
					size="small"
					draggable={false}
					dataSource={dataSourceWithKeys}
					columns={COLUMNS}
					rowClassName={() => 'editableRow'}
					scroll={{ x: 'max-content' }}
					summary={() => (
						<TableSummary
							handleAdd={handleAdd}
							numberOfItems={dataSourceWithKeys.length}
							addingCategory={addingCategory}
						/>
					)}
				/>
			</EditableContext.Provider>
		</Form>
	)
}

export default CategoriesTable
