import { useCallback, useState } from 'react'

export function useForcedRerender() {
	const [, setState] = useState(0)

	const forceRerender = useCallback(() => {
		setState(prevState => prevState + 1)
	}, [])

	return forceRerender
}
