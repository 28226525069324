import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactSVG } from 'react-svg'

import DragAndDrop from 'app/assets/icons/FA6/drag-and-drop.svg'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	'data-row-key': string
}

const TableRow = ({ children, ...props }: RowProps) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging
	} = useSortable({
		id: props['data-row-key']
	})

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
		transition,
		...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
	}

	if (!Array.isArray(children)) return <></>
	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes} tabIndex={-1}>
			{React.Children.map(children, child => {
				if ((child as React.ReactElement).key === 'sort') {
					return React.cloneElement(child as React.ReactElement, {
						children: (
							<span ref={setActivatorNodeRef}>
								<ReactSVG
									src={DragAndDrop}
									style={{ touchAction: 'none', cursor: 'move' }}
									{...listeners}
								/>
							</span>
						)
					})
				}
				return child
			})}
		</tr>
	)
}

export default TableRow
