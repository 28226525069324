import { Col, Layout, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import CannedServiceDetails from './cannedServiceDetails'
import ServiceTables from './serviceTables'
import { CannedServicesProvider } from 'app/providers/CannedServicesProvider'
import styles from './styles.module.scss'
import Footer from './footer'

const { Content } = Layout
const { Title } = Typography

const CannedServicePage = () => {
	const { t } = useTranslation()

	return (
		<CannedServicesProvider>
			<Content>
				<Row className={styles.headerTop}>
					<Col flex={1}>
						<Title level={4}>{t('canned_service.page_title')}</Title>
					</Col>
				</Row>
				<Layout className={styles.cannedService}>
					<Content className={styles.content}>
						<CannedServiceDetails />
						<ServiceTables />
					</Content>
				</Layout>
				<Footer />
			</Content>
		</CannedServicesProvider>
	)
}

export default CannedServicePage
