import { BASE_MONOLITH_URL, ALTERNATE_WEB_DOMAINS } from 'app/utils/constants'

import { getHostnameWithoutSubdomains } from 'app/providers/AuthProvider/utils'

const getMonolithUrl = hostName => `https://app.${hostName}`

export const getBaseURL = () => {
	const hostName = getHostnameWithoutSubdomains()
	const alternateDomains = ALTERNATE_WEB_DOMAINS.split(',')
		.map(d => d.trim())
		.filter(d => !!d)

	if (alternateDomains.some(host => host.endsWith(hostName))) {
		return getMonolithUrl(hostName)
	}

	return BASE_MONOLITH_URL
}
