import { Col, Row, Layout } from 'antd'

import CannedServiceTitle from './Title'
import FixedPrice from './FixedPrice'
import LaborRate from './LaborRate'
import Category from './Category'
import PartsOptimizer from './PartsOptimizer'
import ApplyToAllROs from './ApplyToAllROs'

import styles from './styles.module.scss'
import DeleteCannedServiceButton from '../deleteCannedServiceButton'
import ShopLocations from './ShopLocations'
import CreatedAtUpdatedAt from './CreatedAtUpdatedAt'
import ServiceSummary from './ServiceSummary'

const { Content } = Layout

const CannedServiceDetails = () => {
	return (
		<Content className={styles.content}>
			<Row className={styles.createdAtUpdatedAt}>
				<Col>
					<CreatedAtUpdatedAt />
				</Col>
			</Row>
			<Row gutter={[16, 0]}>
				<CannedServiceTitle />
				<Col flex={1} className={styles.deleteButtonColumn}>
					<DeleteCannedServiceButton />
				</Col>
			</Row>
			<FixedPrice />
			<LaborRate />
			<Category />
			<ShopLocations />
			<PartsOptimizer />
			<ApplyToAllROs />
			<ServiceSummary />
		</Content>
	)
}

export default CannedServiceDetails
