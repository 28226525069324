import { useContext, useRef, useEffect } from 'react'
import { Form, Input, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { EditableContext } from './table'
import { EnterpriseCategory } from 'app/api/Models/CategoryModel'

import styles from './styles.module.scss'

const { Text } = Typography

type Type = 'text' | 'number'

type EditableCellProps = {
	dataIndex: string | number
	record: EnterpriseCategory
	value: string | number
	type: Type
	editing: boolean
	updateCategory: (id: number) => void
}

const EditableCell = ({
	dataIndex,
	record,
	value,
	type,
	editing,
	updateCategory
}: EditableCellProps) => {
	const { t } = useTranslation()

	const inputRef = useRef(null)
	const form = useContext(EditableContext)!

	useEffect(() => {
		form.resetFields()
	}, [editing, form])

	return (
		<>
			{editing ? (
				<>
					{type === 'text' && (
						<Form.Item
							initialValue={value}
							name={dataIndex}
							validateTrigger="onBlur"
							className={styles.formItem}
							rules={[
								{
									required: true,
									message: t('enterprise_settings.error')
								}
							]}>
							<Input
								ref={inputRef}
								data-testid={`input-field-${record.id}`}
								className={styles.title}
								onPressEnter={() => updateCategory(record.id)}
								placeholder={t('enterprise_settings.categories.empty_title')}
							/>
						</Form.Item>
					)}
				</>
			) : (
				<Text
					className={styles.editableCell}
					data-testid={`name-field-${record.id}`}>
					{value}
				</Text>
			)}
		</>
	)
}

export default EditableCell
