import { useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Table, Form, Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'

import { CannedService } from 'app/api/Models/CannedServiceModel'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'

import styles from 'app/screens/cannedService/serviceTables/styles.module.scss'

const { Summary } = Table
const { Cell, Row } = Summary

function TableSummary() {
	const { t } = useTranslation()
	const { dataSourceWithKeys, handleAdd } = useDataSource(
		CannedService.INSPECTIONS
	)
	const [form] = Form.useForm()
	const nameRef = useRef(null)

	const numberOfItems = dataSourceWithKeys.length ?? 0

	const addItem = async () => {
		const values = await form.validateFields()
		handleAdd({
			key: (numberOfItems + 1).toString(),
			...values
		})
		form.resetFields()
		nameRef.current!.focus()
	}
	return (
		<Summary>
			<Form form={form} component={false}>
				<Row className={styles.newItemRow}>
					<Cell index={-1} />
					<Cell index={0}>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: t('canned_service.error.min_one_character')
								}
							]}>
							<Input
								ref={nameRef}
								data-testid="new-row-name"
								className={styles.titleCell}
								placeholder={t('canned_service.inspections.empty_title')}
							/>
						</Form.Item>
					</Cell>
					<Cell index={1} />
					<Cell index={2} />
					<Cell index={3} />
					<Cell index={4} />
					<Cell index={5} className={styles.actionColumn}>
						<Button
							onClick={addItem}
							type="link"
							className={styles.customLink}
							data-testid="add-button">
							<PlusOutlined /> {t('canned_service.add')}
						</Button>
					</Cell>
				</Row>
			</Form>
			<Row className={styles.customSummaryRow}>
				<Cell index={0} />
				<Cell index={1} />
				<Cell index={2} />
				<Cell index={4} />
				<Cell index={5} />
				<Cell index={6} />
			</Row>
		</Summary>
	)
}

export default TableSummary
