import Decimal from 'decimal.js'
import { CannedServiceData } from 'app/api/Models/CannedServicesModel'
import axios from 'app/api/axios'

const URL = '/api/internal/enterprise_canned_services'

function getMappedData(data: CannedServiceData[]) {
	const mappedData = data.map(record => {
		const laborRate = record.enterprise_labor_rate
		const labors = record.enterprise_canned_service_labors
		const parts = record.enterprise_canned_service_generic_parts
		const hazmats = record.enterprise_canned_service_hazmats
		const sublets = record.enterprise_canned_service_sublets

		const labor_total_hours = labors.reduce(
			(acc, val) => acc + new Decimal(val.hours).toNumber(),
			0
		)
		const labor_rate = new Decimal(laborRate?.amount_cents).div(100).toNumber()
		const parts_total_price = parts.reduce(
			(acc, val) =>
				acc +
				new Decimal(val.price_cents).div(100).mul(val.quantity).toNumber(),
			0
		)
		const parts_total_quantity = parts.reduce(
			(acc, val) => acc + new Decimal(val.quantity).toNumber(),
			0
		)
		const hazmats_total_price = hazmats.reduce(
			(acc, val) =>
				acc + new Decimal(val.fee_cents).div(100).mul(val.quantity).toNumber(),
			0
		)
		const sublets_total_price = sublets.reduce(
			(acc, val) => acc + new Decimal(val.price_cents).div(100).toNumber(),
			0
		)

		// Calculate total price
		const total_price =
			labor_total_hours * labor_rate +
			parts_total_price +
			hazmats_total_price +
			sublets_total_price

		return {
			...record,
			labor_info: {
				labor_total_hours,
				labor_rate
			},
			parts_info: {
				parts_total_price,
				parts_total_quantity: parts_total_quantity.toFixed(1)
			},
			total_price
		}
	})

	return mappedData
}

const getCannedServices = async () => {
	const response = await axios(URL)
	const data: CannedServiceData[] = response.data
	const mappedData = getMappedData(data)
	return mappedData
}

const deleteCannedServices = async (ids: string[]) => {
	const response = await axios.delete(`${URL}/bulk`, {
		params: { ids }
	})
	return response.data
}

export { deleteCannedServices, getCannedServices, getMappedData }
