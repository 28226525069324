import { PUBLIC_ALLOWED_DOMAINS } from './constants'

function debounce(func, timeout: number = 500) {
	let timer

	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			func.apply(this, args)
		}, timeout)
	}
}

function getNextTabbableElement(): Element | null {
	// Find all tabbable elements on the page
	const tabbableElements = Array.from(
		document.querySelectorAll(
			'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
		)
	)

	// Find the index of the current active item
	const currentIndex = tabbableElements.indexOf(document.activeElement)

	// Find the index of the next tabbable item
	const nextIndex = (currentIndex + 1) % tabbableElements.length
	const nextTabbableElement = tabbableElements[nextIndex]

	if (nextTabbableElement) {
		return nextTabbableElement
	}
	return null
}

function verifyHost(hostname: string) {
	const allowedDomains = PUBLIC_ALLOWED_DOMAINS.split(',')
		.map(d => d.trim())
		.filter(d => !!d)

	if (!allowedDomains.find(d => hostname === d || hostname.endsWith(`.${d}`))) {
		throw new Error(`Host is not allowed ${hostname}`)
	}
}

export { verifyHost, debounce, getNextTabbableElement }
