import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Popover, Space, Tag } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchParams } from 'app/api/Models/CannedServicesModel'
import Button from 'app/components/Button'

import FilterForm from './FilterForm'
import styles from './styles.module.scss'

type FiltersProps = {
	searchInfo: Partial<SearchParams>
	search: (searchParam: Partial<SearchParams>) => void
}

function Filters({ searchInfo, search }: FiltersProps) {
	const { t } = useTranslation()
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

	const filtersActive = Boolean(searchInfo.categories.length)

	return (
		<Popover
			trigger="click"
			placement="bottomLeft"
			overlayClassName={styles.popover}
			open={isPopoverVisible}
			onOpenChange={(newOpen: boolean) => setIsPopoverVisible(newOpen)}
			content={
				<FilterForm
					onFilterChange={search}
					closePopover={() => setIsPopoverVisible(false)}
				/>
			}>
			<Button
				className={styles.filterBtn}
				data-testid="filter-button"
				onClick={() => setIsPopoverVisible(true)}>
				<Space>
					<span>{t('canned_services.filters.filters')}</span>
					{filtersActive && (
						<Tag color="blue" data-testid="number-tag">
							{searchInfo.categories.length}
						</Tag>
					)}
					{isPopoverVisible ? (
						<UpOutlined data-testid="up-icon" />
					) : (
						<DownOutlined data-testid="down-icon" />
					)}
				</Space>
			</Button>
		</Popover>
	)
}

export default Filters
