import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'

type Props = {
	toggleAdd: () => void
}

const AddCategoryButton = ({ toggleAdd }: Props) => {
	const { t } = useTranslation()

	return (
		<>
			<Button
				type="primary"
				data-testid="add-category"
				onClick={() => toggleAdd()}>
				<PlusOutlined />
				{t('enterprise_settings.categories.add_button')}
			</Button>
		</>
	)
}

export default AddCategoryButton
