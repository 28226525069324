import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import styles from './styles.module.scss'

const AddLaborRateButton = props => {
	const { t } = useTranslation()

	return (
		<Button
			className={styles.addButton}
			data-testid="add-labor-rate"
			type="primary"
			onClick={() => props.toggleAdd()}>
			<PlusOutlined />
			{t('enterprise_settings.labor_rates.add_button')}
		</Button>
	)
}

export default AddLaborRateButton
