import { CloseOutlined } from '@ant-design/icons'
import { Checkbox, Row, Divider, Form, Col, Input, Spin } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { SearchParams, Category } from 'app/api/Models/CannedServicesModel'
import * as api from 'app/api/categories'

import SearchInputPrefix from './SearchInputPrefix'
import styles from './styles.module.scss'

type FilterFormProps = {
	onFilterChange: (searchParam: Partial<SearchParams>) => void
	closePopover: () => void
}

function FilterForm({ onFilterChange, closePopover }: FilterFormProps) {
	const { t } = useTranslation()
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [selectedCategories, setSelectedCategories] = useState<number[]>([])
	const { data, isLoading } = useQuery('getCategories', () =>
		api.getCategories()
	)

	const placeHolder =
		selectedCategories.length > 0
			? t('canned_services.filters.category_placeholder_with_value')
			: t('canned_services.filters.category_placeholder')

	const canShowCategory = (cat: Category) => {
		return cat.text.toLowerCase().includes(searchTerm.toLowerCase())
	}

	const onTagClose = (catID: number) => {
		const categories = selectedCategories.filter(selected => selected !== catID)
		setSelectedCategories(categories)
		onFilterChange({ categories })
	}

	const onCategoryChange = (selected: number[]) => {
		setSelectedCategories(selected)
		onFilterChange({ categories: selected })
	}

	if (isLoading) return <Spin style={{ width: '100%' }} />

	return (
		<Form colon={false} name="filter" data-testid="filter-form">
			<Row className={styles.closeIconWrapper}>
				<CloseOutlined onClick={closePopover} data-testid="close-icon" />
			</Row>
			<Divider className={styles.customDividerMedium} />
			<Row className={styles.formInnerContainer}>
				<Col xs={24}>
					<Form.Item
						className={styles.categoryInput}
						labelCol={{ xs: 24 }}
						labelAlign="left"
						label={`${t('canned_services.filters.category')} (${
							selectedCategories.length
						}/${data.categories.length})`}>
						<Input
							data-testid="category-search"
							placeholder={placeHolder}
							prefix={
								<SearchInputPrefix
									selectedCategories={selectedCategories}
									categoriesData={data}
									onTagClose={onTagClose}
								/>
							}
							onChange={v => setSearchTerm(v.currentTarget.value)}
						/>
					</Form.Item>
				</Col>
				<Divider className={styles.customDividerLarge} />
				<Col xs={24}>
					<Checkbox.Group
						className={styles.checkboxGroup}
						value={selectedCategories}
						onChange={onCategoryChange}>
						{data.categories.map(cat => (
							<Checkbox
								key={cat.id}
								style={{ display: !canShowCategory(cat) && 'none' }}
								className={styles.categoryCheckbox}
								value={cat.id}
								data-testid={`category-checkbox-${cat.id}`}>
								{cat.text}
							</Checkbox>
						))}
					</Checkbox.Group>
				</Col>
			</Row>
			<Divider className={styles.customDividerMedium} />
		</Form>
	)
}

export default FilterForm
