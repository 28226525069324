import axios from 'app/api/axios'
import { MetaConfig } from './Models/MetaConfig'

const getMetaConfig = async (): Promise<MetaConfig> => {
	const res = await axios('/api/internal/meta_configuration')
	const data = res.data
	return {
		currentShop: data.current_shop,
		currentTenant: data.current_tenant,
		currentProfile: data.current_profile,
		env: data.env,
		streamChatConfig: data.stream_chat_config,
		csrfToken: data.csrf_token,
		launchDarklyConfig: data.launch_darkly_config,
		sentryConfig: data.sentry_config,
		pusherConfig: data.pusher_config,
		currentBranding: data.current_branding
	}
}

const updateTenant = async (id: number, payload: unknown): Promise<unknown> => {
	const response = axios.put(`/api/internal/tenants/${id}`, {
		tenant: payload
	})
	return response
}

export { getMetaConfig, updateTenant }
