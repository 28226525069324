import { Typography, Tooltip, Col } from 'antd'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'

import Lock from 'app/assets/icons/FA6/lock.svg'
import { toDollars } from 'app/screens/cannedService/helper'
import { useCurrency } from 'app/hooks/useCurrency'

import styles from './styles.module.scss'

const { Text } = Typography

type TotalPriceProps = {
	totalPrice: number
	fixedPrice: number
}

function TotalPrice({ totalPrice, fixedPrice }: TotalPriceProps) {
	const { formatCurrency } = useCurrency()
	const { t } = useTranslation()

	return (
		<>
			{fixedPrice ? (
				<Col className={styles.totalPriceWrapper}>
					<Text>{formatCurrency(String(toDollars(fixedPrice)))}</Text>
					<Tooltip
						title={t('canned_services.table.fixed_price_tooltip_content')}>
						<ReactSVG
							src={Lock}
							className={styles.lockIcon}
							data-testid="lock-icon"
						/>
					</Tooltip>
				</Col>
			) : (
				<Text>{formatCurrency(String(totalPrice))}</Text>
			)}
		</>
	)
}

export default TotalPrice
