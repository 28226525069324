import { ConfigProvider } from 'antd'
import React from 'react'

type IProps = {
	children: React.ReactNode
}

const PRIMARY_BLUE = '#328AC9'
const TEXT_COLOR = '#262626'
const DISABLED_COLOR = '#BFBFBF'
const DEFAULT_BORDER = '#D9D9D9'

function AntThemeProvider(props: IProps) {
	return (
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					colorPrimary: PRIMARY_BLUE,
					colorError: '#ED0802',
					colorWarning: '#FAAD14',
					colorSuccess: '#52C41A',
					colorTextDisabled: DISABLED_COLOR,
					colorTextBase: TEXT_COLOR,
					colorText: TEXT_COLOR,
					colorLink: PRIMARY_BLUE,
					colorLinkHover: PRIMARY_BLUE,
					fontFamily: 'Rubik, sans-serif'
				},
				components: {
					Button: {
						borderRadius: 2
					},
					Select: {
						colorBorder: DEFAULT_BORDER
					},
					Input: {
						colorBorder: DEFAULT_BORDER,
						colorTextPlaceholder: DISABLED_COLOR,
						colorPrimaryBorderHover: '#58A6D6'
					},
					Tabs: {
						margin: 0
					},
					Typography: {
						fontWeightStrong: 500
					}
				}
			}}>
			{props.children}
		</ConfigProvider>
	)
}

export { AntThemeProvider }
