import { Button, Modal } from 'antd'
import { deleteCannedService } from 'app/api/enterpriseCannedService'
import { useState } from 'react'
import { ReactSVG } from 'react-svg'

import TrashCan from 'app/assets/icons/FA6/trash-can.svg'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'

const DeleteCannedServiceButton = () => {
	const { t } = useTranslation()
	const { serviceId } = useParams()
	const { data } = useCanneryServicesContext()

	const shops = data.shops
	const shopCount = shops.length

	const [modalOpen, setModalOpen] = useState(false)

	const modalMessage = () => {
		return t('canned_service.delete_modal_body.shops', { count: shopCount })
	}

	const deleteCannedServiceRequest = async () => {
		await deleteCannedService(serviceId)
		window.location.assign('/')
	}

	return (
		<>
			<Button
				className={styles.deleteButton}
				onClick={() => setModalOpen(true)}>
				<ReactSVG src={TrashCan} className={styles.trashcanIcon} />

				{t('canned_service.deleteButton')}
			</Button>

			<Modal
				title={t('canned_service.deleteModalTitle')}
				centered
				open={modalOpen}
				onOk={() => deleteCannedServiceRequest()}
				onCancel={() => setModalOpen(false)}
				okText={t('canned_service.ok')}
				cancelText={t('canned_service.cancel')}>
				<p>{modalMessage()}</p>
			</Modal>
		</>
	)
}

export default DeleteCannedServiceButton
