import { useEffect } from 'react'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Col, Row } from 'antd'
import Checkbox from 'app/components/Checkbox/index'
import { useTranslation } from 'react-i18next'

import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'

const PartsOptimizer = () => {
	const { t } = useTranslation()
	const {
		isFixedPrice,
		partsOptimizerEnabled,
		setPartsOptimizerEnabled,
		cannedServiceEdited,
		setCannedServiceEdited
	} = useCanneryServicesContext()

	const onChangeHandle = (e: CheckboxChangeEvent) => {
		setPartsOptimizerEnabled(e.target.checked)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	useEffect(() => {
		if (isFixedPrice) setPartsOptimizerEnabled(false)
	}, [isFixedPrice, setPartsOptimizerEnabled])

	return (
		<Row gutter={[8, 0]}>
			<Col span={3} />
			<Col span={18}>
				<Checkbox
					checked={partsOptimizerEnabled}
					disabled={isFixedPrice}
					onChange={onChangeHandle}>
					{t('canned_service.details.parts_optimizer')}
				</Checkbox>
			</Col>
		</Row>
	)
}

export default PartsOptimizer
