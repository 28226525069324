import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import {
	QueryClientProvider,
	QueryClient,
	QueryCache,
	MutationCache
} from 'react-query'

import { defaultErrorHandler } from 'app/api/defaultErrorHandler'
import { AntThemeProvider } from 'app/providers/AntThemeProvider'
import { AuthProvider } from 'app/providers/AuthProvider'
import { LaunchDarklyProvider } from 'app/providers/LaunchDarklyProvider'
import {
	MetaConfigContext,
	MetaConfigProvider
} from 'app/providers/MetaConfigProvider'
import AppRoutes from 'app/routes'

import 'app/theme/theme.scss'
import Navbar from 'app/components/Navbar'
import { verifyHost } from 'app/utils'

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: defaultErrorHandler
	}),
	mutationCache: new MutationCache({
		onError: defaultErrorHandler
	})
})

verifyHost(window.location.hostname)

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AntThemeProvider>
				<AuthProvider>
					<MetaConfigProvider>
						<MetaConfigContext.Consumer>
							{value => (
								<LaunchDarklyProvider
									userID={value.currentProfile.id.toString()}>
									<Router>
										<Navbar>
											<AppRoutes />
										</Navbar>
									</Router>
								</LaunchDarklyProvider>
							)}
						</MetaConfigContext.Consumer>
					</MetaConfigProvider>
				</AuthProvider>
			</AntThemeProvider>
		</QueryClientProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
