import { useEffect, useRef, useState, ReactNode, MutableRefObject } from 'react'
import { Form, Input, Typography, InputNumber } from 'antd'
import classNames from 'classnames'
import { focusConfirmButton } from 'app/screens/cannedService/helper'
import { useCurrency } from 'app/hooks/useCurrency'

import styles from './styles.module.scss'

const { Text } = Typography

export type Type = 'text' | 'number' | 'currency'

type EditableCellProps = {
	rules?: unknown[]
	placeholder?: string
	className?: string
	dataIndex: string
	value: string | number
	type: Type
	customInputRef?: MutableRefObject<HTMLElement>
	prefix?: string
	suffix?: ReactNode
	handleSave: (value: unknown) => void
	onClick: () => void
}

const EditableCell = ({
	rules,
	placeholder,
	className,
	dataIndex,
	value,
	type,
	prefix,
	suffix,
	customInputRef,
	handleSave,
	onClick
}: EditableCellProps) => {
	const [editing, setEditing] = useState(false)
	const { formatCurrency } = useCurrency()

	const inputRef = useRef(null)

	useEffect(() => {
		if (editing) {
			inputRef.current!.focus()
		}
	}, [editing])

	useEffect(() => {
		if (!customInputRef) return
		customInputRef.current = inputRef.current
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputRef.current, customInputRef])

	const handleClick = () => {
		setEditing(!editing)
		onClick()
	}

	const handleKeyPress = event => {
		if (event.key === 'Enter' || event.key === ' ') {
			setEditing(!editing)
			onClick()
		}
	}

	const save = async e => {
		try {
			await handleSave(e.target.value)
			setEditing(false)
			if (!e.relatedTarget) {
				setTimeout(() => {
					inputRef.current!.focus()
				}, 1)
			}
		} catch {
			focusConfirmButton()
		}
	}

	const formatValue = (): string | number => {
		if (value) {
			if (type === 'text') return value
			if (type === 'number') return Number(value).toFixed(2)
			if (type === 'currency') return formatCurrency(value.toString())
		}
		if (type === 'number') return Number(0).toFixed(2)
		if (type === 'currency') return formatCurrency('0.0')
	}

	return (
		<>
			{editing ? (
				<>
					{(type === 'number' || type === 'currency') && (
						<Form.Item name={dataIndex} validateTrigger="onBlur" rules={rules}>
							<InputNumber
								precision={2}
								step={0.01}
								controls={false}
								min={type === 'number' && 0}
								ref={inputRef}
								onPressEnter={save}
								onBlur={save}
								prefix={prefix}
								addonAfter={suffix}
								className={className}
								placeholder={placeholder}
								data-testid={
									type === 'number' ? 'number-field' : 'currency-field'
								}
							/>
						</Form.Item>
					)}
					{type === 'text' && (
						<Form.Item name={dataIndex} validateTrigger="onBlur" rules={rules}>
							<Input
								ref={inputRef}
								className={className}
								onPressEnter={save}
								onBlur={save}
								placeholder={placeholder}
								suffix={suffix}
								data-testid="text-field"
							/>
						</Form.Item>
					)}
				</>
			) : (
				<Text
					ref={inputRef}
					tabIndex={0}
					className={classNames(styles.editableCell, className)}
					onClick={handleClick}
					onKeyDown={handleKeyPress}>
					{formatValue()}
				</Text>
			)}
		</>
	)
}

export default EditableCell
