import axios from 'app/api/axios'
import {
	EnterprisePartTypesData,
	EnterprisePartType,
	EnterpriseMappedPartTypesData
} from './Models/PartsModel'

const mapParts = (
	data: EnterprisePartTypesData
): EnterpriseMappedPartTypesData => {
	return {
		parts: data,
		partOptions: data.results.map((item: EnterprisePartType) => {
			return {
				label: item.name,
				value: String(item.id)
			}
		})
	}
}

const getParts = (
	search_terms: string
): Promise<EnterpriseMappedPartTypesData> =>
	axios(`/api/internal/part_types/?term=${search_terms}`).then(res =>
		mapParts(res.data)
	)

export { getParts }
