import { Col, Layout, Row } from 'antd'
import { useState, useMemo } from 'react'

import {
	SearchParams,
	CannedServiceData,
	CategoryData
} from 'app/api/Models/CannedServicesModel'
import { Shop } from 'app/api/Models/ShopModel'

import { useCannedServicesQuery } from '../hooks/useCannedServices'
import CannedServicesControls from './CannedServicesControls'
import CannedServicesTable from './CannedServicesTable'
import styles from './styles.module.scss'
const { Content } = Layout

function CannedServicesLibrary() {
	const [searchParams, setSearchParams] = useState<SearchParams>({
		searchedText: '',
		categories: []
	})
	const [selectedcannedServicesIds, setSelectedcannedServicesIds] = useState<
		string[]
	>([])
	const [numOfAffectedShops, setNumOfAffectedShops] = useState<number>(0)
	const { data, isLoading, refetch } = useCannedServicesQuery()

	const search = (newSearchParam: Partial<SearchParams>) => {
		const newSearchParams = { ...searchParams, ...newSearchParam }
		setSearchParams(newSearchParams)
		setSelectedcannedServicesIds([])
	}

	const onSelectedcannedServicesHandle = (
		selectedIds: string[],
		selectedRows: CannedServiceData[]
	) => {
		setSelectedcannedServicesIds(selectedIds)
		let shops = []
		selectedRows.forEach(row => {
			shops = [...shops, ...row.shops]
		})
		setNumOfAffectedShops(new Set(shops.map((shop: Shop) => shop.id)).size)
	}

	const filteredData: CannedServiceData[] = useMemo(() => {
		if (!data) return []
		let filteredData = [...data]
		const { searchedText, categories } = searchParams
		if (searchedText) {
			filteredData = filteredData.filter(record =>
				record.title.toLowerCase().includes(searchedText.toLowerCase())
			)
		}
		if (categories.length > 0) {
			filteredData = filteredData.filter(record =>
				record.categories.some((category: CategoryData) =>
					categories.includes(category.category_id)
				)
			)
		}
		return filteredData
	}, [data, searchParams])

	return (
		<Layout className={styles.CannedServicesLibrary}>
			<Content className={styles.content}>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<CannedServicesControls
							loading={isLoading}
							searchInfo={searchParams}
							onSearch={search}
							refetch={refetch}
							selectedcannedServicesIds={selectedcannedServicesIds}
							numOfAffectedShops={numOfAffectedShops}
							numberOfItems={filteredData.length}
							setSelectedcannedServicesIds={setSelectedcannedServicesIds}
						/>
					</Col>
					<Col span={24}>
						<CannedServicesTable
							loading={isLoading}
							data={filteredData}
							onRowSelect={onSelectedcannedServicesHandle}
						/>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}

export default CannedServicesLibrary
