import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { Decimal } from 'decimal.js'
import notification from 'app/components/notification'

import {
	CannedService,
	EnterpriseCannedServicePayload
} from 'app/api/Models/CannedServiceModel'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import styles from './styles.module.scss'

const Footer = () => {
	const { t } = useTranslation()
	const {
		data,
		saveService,
		title,
		selectedShopIds,
		isFixedPrice,
		fixedPrice,
		laborRateId,
		categoryId,
		partsOptimizerEnabled,
		autoApplied,
		cannedServices,
		cannedServiceEdited
	} = useCanneryServicesContext()

	const [modal, element] = Modal.useModal()

	const discardChanges = () => {
		modal.confirm({
			title: t('canned_service.discard_modal.title'),
			content: t('canned_service.discard_modal.content'),
			centered: true,
			onOk: () => window.location.reload(),
			onCancel: Modal.destroyAll,
			icon: <InfoCircleOutlined style={{ color: '#328ac9' }} />,
			okText: t('canned_service.ok'),
			cancelText: t('canned_service.cancel')
		})
	}

	const showModal = () => {
		const numOfRemovedShops = data.shops.filter(
			({ id }) => !selectedShopIds.includes(String(id))
		).length
		const numOfAffectedShops = selectedShopIds.length + numOfRemovedShops

		modal.confirm({
			title: t('canned_service.modal_title'),
			content: t('canned_service.modal_body', {
				count: numOfAffectedShops
			}),
			centered: true,
			onOk: () => updateService(),
			onCancel: Modal.destroyAll,
			icon: <InfoCircleOutlined style={{ color: '#328ac9' }} />,
			okText: t('canned_service.ok'),
			cancelText: t('canned_service.cancel')
		})
	}

	const updateService = () => {
		const filteredLabors = cannedServices[CannedService.LABORS].filter(
			labor => !('_destroy' in labor)
		)

		if (isFixedPrice && filteredLabors.length === 0) {
			notification.error({
				theme: 'dark',
				message: t('canned_service.error.labor_is_not_present')
			})
			return
		}

		const sumOfLaborHours = filteredLabors.reduce(
			(acc, val) => new Decimal(acc).add(val.hours).toNumber(),
			0
		)

		if (isFixedPrice && sumOfLaborHours === 0) {
			notification.error({
				theme: 'dark',
				message: t('canned_service.error.labor_hours_must_be_greater_than_0')
			})
			return
		}

		const data: EnterpriseCannedServicePayload = {
			enterprise_canned_service: {
				title: title,
				fixed_price_cents: isFixedPrice
					? Decimal.mul(fixedPrice, 100).toNumber()
					: null,
				enterprise_labor_rate_id: laborRateId,
				category_id: Number(categoryId) || null,
				shop_ids: selectedShopIds.map(Number),
				gp_wizard_ok: partsOptimizerEnabled,
				auto_applied: autoApplied,
				enterprise_canned_service_labors_attributes:
					cannedServices[CannedService.LABORS],
				enterprise_canned_service_generic_parts_attributes:
					cannedServices[CannedService.GENERIC_PARTS],
				enterprise_canned_service_hazmats_attributes:
					cannedServices[CannedService.HAZMATS],
				enterprise_canned_service_sublets_attributes:
					cannedServices[CannedService.SUBLETS],
				enterprise_canned_service_inspections_attributes:
					cannedServices[CannedService.INSPECTIONS]
			}
		}

		saveService(data)
	}

	return (
		<>
			<Row className={styles.footer}>
				<Button
					onClick={() => discardChanges()}
					disabled={!cannedServiceEdited}
					data-testid="discard-button">
					{t('canned_service.discard')}
				</Button>
				<Button
					type="primary"
					onClick={showModal}
					disabled={!cannedServiceEdited}
					data-testid="save-button">
					{t('canned_service.save')}
				</Button>
			</Row>
			<>{element}</>
		</>
	)
}

export default Footer
