import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useCurrency } from 'app/hooks/useCurrency'

import styles from './styles.module.scss'

const { Text } = Typography

type LaborProps = {
	hoursTotal: string | number
	effectiveRate: string
	fixedPrice: number | null
}

function Labor({ hoursTotal, effectiveRate, fixedPrice }: LaborProps) {
	const { t } = useTranslation()
	const { formatCurrency } = useCurrency()
	hoursTotal = Number(hoursTotal).toFixed(2)

	return (
		<Text className={fixedPrice && styles.fixedPrice}>
			{fixedPrice
				? t('canned_services.table.fixed_price_service')
				: t('canned_services.table.labor_hours_at_rate', {
						hoursTotal,
						effectiveRate: formatCurrency(effectiveRate),
						count: Number(hoursTotal)
					})}
		</Text>
	)
}

export default Labor
