import { useRef } from 'react'
import { Form, Popconfirm, Button } from 'antd'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'

import EMTable from 'app/components/EMTable'
import TableSummary from './TableSummary'
import Editable from 'app/components/Editable'
import TrashCan from 'app/assets/icons/FA6/trash-can.svg'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'
import { CannedService } from 'app/api/Models/CannedServiceModel'
import { focusConfirmButton } from 'app/screens/cannedService/helper'
import useNextTabbableElement from 'app/hooks/useNextTabbableElement'

import styles from 'app/screens/cannedService/serviceTables/styles.module.scss'

const InspectionsTable = () => {
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const {
		dataSourceWithKeys,
		onOrderChanged,
		setField,
		handleSave,
		handleDelete
	} = useDataSource(CannedService.INSPECTIONS, form)
	const { captureNextTabbableElement, focusNextTabbableElemement } =
		useNextTabbableElement()

	const trashButtonRef = useRef(null)

	const columns = [
		{
			key: 'sort',
			className: styles.dragAndDropColumn
		},
		{
			className: styles.titleColumn,
			title: t('canned_service.inspections.title'),
			dataIndex: 'name',
			render: (val, record) => (
				<Editable
					className={styles.titleCell}
					type="text"
					dataIndex="name"
					value={val}
					placeholder={t('canned_service.sublets.empty_title')}
					handleSave={() => handleSave(record, 'name')}
					onClick={() => setField(record, 'name')}
					rules={[
						{
							required: true,
							message: t('canned_service.error.min_one_character')
						}
					]}
				/>
			)
		},
		{ className: styles.normalColumn },
		{ className: styles.normalColumn },
		{ className: styles.normalColumn },
		{ className: styles.normalColumn },
		{
			title: '',
			className: styles.actionColumn,
			render: (_, record) => (
				<Popconfirm
					destroyTooltipOnHide={true}
					title={t('canned_service.delete_row_confirm')}
					okText={t('canned_service.yes')}
					cancelText={t('canned_service.no')}
					onConfirm={() => {
						handleDelete(record.key)
						focusNextTabbableElemement()
					}}
					onOpenChange={open => {
						if (open) {
							captureNextTabbableElement()
							focusConfirmButton()
						} else trashButtonRef.current!.focus()
					}}>
					<Button type="ghost" ref={trashButtonRef}>
						<ReactSVG
							src={TrashCan}
							className={styles.deleteIcon}
							data-testid={`delete-icon-${record.key}`}
						/>
					</Button>
				</Popconfirm>
			)
		}
	]

	return (
		<Form form={form} component={false}>
			<EMTable
				className={styles.serviceTable}
				size="small"
				draggable={true}
				dataSource={dataSourceWithKeys}
				columns={columns}
				rowClassName={() => 'editableRow'}
				onOrderChanged={onOrderChanged}
				scroll={{ x: 'max-content' }}
				summary={() => <TableSummary />}
			/>
		</Form>
	)
}

export default InspectionsTable
