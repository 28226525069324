import { notification as AntdNotification } from 'antd'
import classNames from 'classnames/bind'
import {
	ExclamationCircleFilled,
	CheckCircleFilled,
	InfoCircleFilled
} from '@ant-design/icons'

import styles from './styles.module.scss'

import type { ArgsProps } from 'antd/lib/notification/interface'

const cx = classNames.bind(styles)

type CustomProps = { theme?: 'dark' }
type NotificationProps = ArgsProps & CustomProps

const notification = {
	...AntdNotification,
	error: function (config: NotificationProps) {
		const classes = [
			config?.className,
			cx('notification', { dark: config?.theme === 'dark' })
		].join(' ')

		return AntdNotification.error({
			...config,
			className: classes
		})
	},
	success: function (config: NotificationProps) {
		const classes = [
			config?.className,
			cx('notification', { dark: config?.theme === 'dark' })
		].join(' ')

		return AntdNotification.success({
			...config,
			className: classes,
			icon: <CheckCircleFilled className={(styles.icon, styles.checkIcon)} />
		})
	},
	info: function (config: NotificationProps) {
		const classes = [
			config?.className,
			cx('notification', { dark: config?.theme === 'dark' })
		].join(' ')

		return AntdNotification.info({
			...config,
			className: classes,
			icon: <InfoCircleFilled className={(styles.icon, styles.infoIcon)} />
		})
	},
	warning: function (config: NotificationProps) {
		const classes = [
			config?.className,
			cx('notification', { dark: config?.theme === 'dark' })
		].join(' ')

		return AntdNotification.warning({
			...config,
			className: classes,
			icon: (
				<ExclamationCircleFilled
					className={(styles.icon, styles.warningIcon)}
				/>
			)
		})
	}
}

export default notification
