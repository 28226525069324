import { useState } from 'react'
import { CannedServiceData } from 'app/api/Models/CannedServicesModel'

export function usePagination(data: CannedServiceData[], itemsPerPage: number) {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const totalPage: number = Math.ceil(data.length / itemsPerPage)
	const totalItem: number = data.length

	function currentData() {
		const begin = (currentPage - 1) * itemsPerPage
		const end = begin + itemsPerPage
		return data.slice(begin, end)
	}

	function next() {
		setCurrentPage(currentPage => Math.min(currentPage + 1, totalPage))
	}

	function prev() {
		setCurrentPage(currentPage => Math.max(currentPage - 1, 1))
	}

	return { next, prev, currentData, currentPage, totalPage, totalItem }
}
