import { useRef } from 'react'
import { Form, Typography, Button, Row, Col } from 'antd'
import Popconfirm from 'app/components/Popconfirm/index'
import Checkbox from 'app/components/Checkbox/index'
import { CloseCircleFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'

import { useCurrency } from 'app/hooks/useCurrency'
import EMTAble from 'app/components/EMTable'
import { CannedService } from 'app/api/Models/CannedServiceModel'
import TableSummary from './TableSummary'
import LimitModal from './LimitModal'
import TrashCan from 'app/assets/icons/FA6/trash-can.svg'
import Editable from 'app/components/Editable'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import { useSubtotal } from 'app/screens/cannedService/hooks/useSubtotal'
import {
	toCents,
	toDollars,
	focusConfirmButton
} from 'app/screens/cannedService/helper'
import useNextTabbableElement from 'app/hooks/useNextTabbableElement'

import styles from 'app/screens/cannedService/serviceTables/styles.module.scss'

const { Text } = Typography

const PartsTable = () => {
	const { t } = useTranslation()
	const { formatCurrency, getCurrencySymbol } = useCurrency()
	const { isFixedPrice } = useCanneryServicesContext()
	const { subtotalValidator, invalidFieldKey, setInvalidFieldKey } =
		useSubtotal()
	const [form] = Form.useForm()
	const {
		dataSourceWithKeys,
		onOrderChanged,
		setField,
		handleSave,
		handleDelete
	} = useDataSource(CannedService.GENERIC_PARTS, form)
	const { captureNextTabbableElement, focusNextTabbableElemement } =
		useNextTabbableElement()

	const trashButtonRef = useRef(null)
	const feeCentsRef = useRef(null)
	const quantityRef = useRef(null)

	const columns = [
		{
			key: 'sort',
			className: styles.dragAndDropColumn
		},
		{
			className: styles.titleColumn,
			title: t('canned_service.parts.title'),
			dataIndex: 'name',
			render: val => <Text className={styles.textCell}>{val}</Text>
		},
		{
			className: styles.normalColumn,
			title: t('canned_service.parts.qty'),
			dataIndex: 'quantity',
			render: (val, record) => (
				<Popconfirm
					open={invalidFieldKey === `${record.key}-quantity`}
					overlayClassName={styles.fixedPricePopup}
					showCancel={false}
					title={t('canned_service.error.fixed_price_exceed')}
					okText={t('canned_service.ok_got_it')}
					onConfirm={() => {
						setInvalidFieldKey('')
						setField(record, 'quantity')
						quantityRef.current!.focus()
					}}>
					<Editable
						customInputRef={quantityRef}
						className={classNames(
							styles.normalCell,
							invalidFieldKey === `${record.key}-quantity` && styles.hasError
						)}
						type="number"
						dataIndex="quantity"
						value={val}
						suffix={
							invalidFieldKey === `${record.key}-quantity` && (
								<CloseCircleFilled />
							)
						}
						handleSave={() => {
							if (invalidFieldKey) return Promise.reject()
							return handleSave(record, 'quantity')
						}}
						onClick={() => setField(record, 'quantity')}
						rules={[
							...(isFixedPrice
								? [
										{
											validator: (_, value) => {
												return subtotalValidator({
													name: 'quantity',
													key: record.key,
													otherFieldValue: record.price_cents,
													newValue: value,
													currentValue: record.quantity
												})
											}
										}
									]
								: [])
						]}
					/>
				</Popconfirm>
			)
		},
		{
			className: styles.normalColumn,
			title: isFixedPrice
				? t('canned_service.parts.list')
				: t('canned_service.parts.suggested_list'),
			dataIndex: 'price_cents',
			render: (val, record) => (
				<Popconfirm
					open={invalidFieldKey === `${record.key}-price_cents`}
					overlayClassName={styles.fixedPricePopup}
					showCancel={false}
					title={t('canned_service.error.fixed_price_exceed')}
					okText={t('canned_service.ok_got_it')}
					onConfirm={() => {
						setInvalidFieldKey('')
						setField(record, 'price_cents')
						feeCentsRef.current!.focus()
					}}>
					<Editable
						customInputRef={feeCentsRef}
						className={classNames(
							styles.normalCell,
							invalidFieldKey === `${record.key}-price_cents` && styles.hasError
						)}
						prefix={getCurrencySymbol()}
						suffix={
							invalidFieldKey === `${record.key}-price_cents` && (
								<CloseCircleFilled />
							)
						}
						type="currency"
						dataIndex="price_cents"
						value={toDollars(val)}
						handleSave={value => {
							if (invalidFieldKey) return Promise.reject()
							return handleSave(record, 'price_cents', value)
						}}
						onClick={() => setField(record, 'price_cents')}
						rules={[
							...(isFixedPrice
								? [
										{
											validator: (_, value) => {
												return subtotalValidator({
													name: 'price_cents',
													key: record.key,
													otherFieldValue: record.quantity,
													newValue: toCents(value),
													currentValue: record.price_cents
												})
											}
										}
									]
								: [])
						]}
					/>
				</Popconfirm>
			)
		},
		{
			className: styles.normalColumn,
			title: isFixedPrice
				? t('canned_service.parts.price')
				: t('canned_service.parts.suggested_price'),
			render: (_, record) => (
				<Text className={styles.textCell}>
					{formatCurrency(
						toDollars(
							Number(record.quantity) * Number(record.price_cents)
						).toString()
					)}
				</Text>
			)
		},
		{
			className: styles.taxColumn,
			title: t('canned_service.tax'),
			dataIndex: 'taxable',
			render: (_, record) => (
				<Form.Item
					name={`taxable-${record.key}`}
					valuePropName="checked"
					initialValue={!record.taxable}
					className={styles.normalCell}>
					<Checkbox
						className={styles.taxCell}
						onChange={() => handleSave(record, 'taxable')}
						data-testid={`tax-checkbox-${record.key}`}>
						{t('canned_service.taxable')}
					</Checkbox>
				</Form.Item>
			)
		},
		{
			title: '',
			className: styles.actionColumn,
			render: (_, record) => (
				<Row justify={'end'}>
					<Col>{isFixedPrice && <LimitModal part={record} />}</Col>
					<Col>
						<Popconfirm
							destroyTooltipOnHide={true}
							title={t('canned_service.delete_row_confirm')}
							okText={t('canned_service.yes')}
							cancelText={t('canned_service.no')}
							onConfirm={() => {
								handleDelete(record.key)
								focusNextTabbableElemement()
							}}
							onOpenChange={open => {
								if (open) {
									captureNextTabbableElement()
									focusConfirmButton()
								} else trashButtonRef.current!.focus()
							}}>
							<Button
								type="ghost"
								ref={trashButtonRef}
								className={styles.trashButton}>
								<ReactSVG
									src={TrashCan}
									className={styles.deleteIcon}
									data-testid={`delete-icon-${record.key}`}
								/>
							</Button>
						</Popconfirm>
					</Col>
				</Row>
			)
		}
	]

	return (
		<Form form={form} component={false}>
			<EMTAble
				size="small"
				className={styles.serviceTable}
				draggable={true}
				dataSource={dataSourceWithKeys}
				columns={columns}
				onOrderChanged={onOrderChanged}
				rowClassName={() => 'editableRow'}
				scroll={{ x: 'max-content' }}
				summary={() => <TableSummary />}
			/>
		</Form>
	)
}

export default PartsTable
