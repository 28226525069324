import { createContext, useState } from 'react'
import {
	Form,
	Popconfirm,
	Button,
	Typography,
	notification,
	Tooltip,
	Checkbox
} from 'antd'
import { ReactSVG } from 'react-svg'
import EMTable from 'app/components/EMTable'
import EditableCell from './EditableCell'
import TableSummary from './TableSummary'
import { toDollars, toCents } from 'app/screens/cannedService/helper'
import { useTranslation } from 'react-i18next'
import type { FormInstance } from 'antd/es/form'
import TrashCan from 'app/assets/icons/FA6/trash-can.svg'
import CircleQuestion from 'app/assets/icons/FA6/solid_circle-question.svg'
import { useFeatureFlags } from 'app/providers/LaunchDarklyProvider'
import FEATURE_FLAG_NAMES from 'feature-flags.json'

import {
	EnterpriseLaborRate,
	EnterpriseLaborRatePayload
} from 'app/api/Models/LaborRateModel'

import styles from './styles.module.scss'

const { Text } = Typography

type EnterpriseSettingsLaborRateWithKey = EnterpriseLaborRate & {
	key: string
}

type Props = {
	addingLaborRate: boolean
	createLaborRate: (payload: EnterpriseLaborRatePayload) => Promise<void>
	editLaborRate: (data: {
		id: number
		payload: EnterpriseLaborRatePayload
	}) => Promise<void>
	deleteLaborRate: (id: number) => void
	dataSource: EnterpriseLaborRate[]
}

export const EditableContext = createContext<FormInstance<any> | null>(null)

const LaborRatesTable = ({
	addingLaborRate,
	deleteLaborRate,
	createLaborRate,
	editLaborRate,
	dataSource
}: Props) => {
	const [editingLaborRate, setEditingLaborRate] = useState([])

	const { t } = useTranslation()
	const [form] = Form.useForm()

	const checkFeatureFlag = useFeatureFlags()
	const excludeFromLaborMatrixFlagEnabled = checkFeatureFlag(
		FEATURE_FLAG_NAMES.EXCLUDE_FROM_LABOR_MATRIX
	)

	const dataSourceWithKeys: EnterpriseSettingsLaborRateWithKey[] =
		dataSource.map(item => ({
			...item,
			key: item.id.toString(),
			id: item.id
		}))

	const handleDelete = (record: EnterpriseSettingsLaborRateWithKey) => {
		if (record.enterprise_canned_services.length > 0) {
			return notification.error({
				message: t('enterprise_settings.error_title'),
				description: t('enterprise_settings.labor_rates.association_error', {
					rateName: record.label
				})
			})
		} else {
			deleteLaborRate(record.id)
		}
	}

	const handleAdd = async (record: EnterpriseSettingsLaborRateWithKey) => {
		await createLaborRate({
			enterprise_labor_rate: {
				label: record.label,
				amount_cents: record.amount_cents,
				exclude_from_labor_matrix: record.exclude_from_labor_matrix
			}
		})
	}

	const currentlyEditing = (recordId: number) => {
		return editingLaborRate.includes(recordId)
	}

	const updateEditingLaborRate = (recordId: number) => {
		if (currentlyEditing(recordId)) {
			form.resetFields()
			setEditingLaborRate([])
		} else {
			form.resetFields()
			setEditingLaborRate([recordId])
		}
	}

	const updateLaborRate = async (recordId: number) => {
		const values = await form.validateFields().catch(() => null)
		if (values === null) return

		try {
			await editLaborRate({
				id: recordId,
				payload: {
					enterprise_labor_rate: {
						label: values[`label-${recordId}`],
						amount_cents: toCents(values[`amount_cents-${recordId}`]),
						exclude_from_labor_matrix:
							values[`exclude_from_labor_matrix-${recordId}`]
					}
				}
			})
			form.resetFields()
			updateEditingLaborRate(recordId)
		} catch (e) {
			if (!e?.response?.data?.errors) throw new Error(e)

			const errors = e.response.data.errors
			const formDataWithErrors = Object.entries(values).map(([key, value]) => {
				const keyWithoutId = key.split('-')[0]
				return {
					name: key,
					errors: errors[keyWithoutId],
					value
				}
			})
			form.setFields(formDataWithErrors)
		}
	}

	const COLUMNS = [
		{
			title: t('enterprise_settings.labor_rates.name'),
			dataIndex: 'label',
			render: (val, record) => (
				<>
					{record.label === 'enterprise_standard_labor_rate' ? (
						<div
							className={styles.standardRateContainer}
							data-testid={'enterprise-standard-container'}>
							<Text className={styles.textCell}>
								{t('enterprise_settings.labor_rates.enterprise_standard')}
							</Text>
							<Tooltip
								title={t('enterprise_settings.labor_rates.standard_rate_info')}>
								<ReactSVG
									src={CircleQuestion}
									className={styles.questionIcon}
									data-testid={`question-icon`}
								/>
							</Tooltip>
						</div>
					) : (
						<EditableCell
							dataIndex={`label-${record.id}`}
							record={record}
							value={val}
							type="text"
							editing={editingLaborRate.includes(record.id)}
							updateLaborRate={updateLaborRate}
						/>
					)}
				</>
			)
		},
		{
			title: t('enterprise_settings.labor_rates.rate_price'),
			dataIndex: 'amount_cents',
			render: (val, record) => (
				<EditableCell
					dataIndex={`amount_cents-${record.id}`}
					record={record}
					value={toDollars(val)}
					type="currency"
					editing={currentlyEditing(record.id)}
					updateLaborRate={updateLaborRate}
				/>
			)
		},
		excludeFromLaborMatrixFlagEnabled && {
			title: t('enterprise_settings.labor_rates.exclude_from_labor_matrix'),
			className: styles.excludeFromLaborMatrixColumn,
			dataIndex: 'exclude_from_labor_matrix',
			render: (val, record) => (
				<Form.Item
					name={`exclude_from_labor_matrix-${record.id}`}
					valuePropName="checked"
					initialValue={record.exclude_from_labor_matrix}
					className={styles.formItem}>
					<Checkbox disabled={!currentlyEditing(record.id)}>
						{t('enterprise_settings.labor_rates.excluded')}
					</Checkbox>
				</Form.Item>
			)
		},
		{
			title: t('enterprise_settings.actions'),
			className: styles.actionColumn,
			width: '10%',
			render: (_, record) => (
				<>
					{currentlyEditing(record.id) ? (
						<Button
							id={record.id}
							onClick={() => updateLaborRate(record.id)}
							type="link"
							data-testid="save-button">
							{t('enterprise_settings.save')}
						</Button>
					) : (
						<Button
							id={record.id}
							data-testid={`edit-button-${record.id}`}
							onClick={() => updateEditingLaborRate(record.id)}
							type="link"
							className={styles.customLink}>
							{t('enterprise_settings.edit')}
						</Button>
					)}
					{record.label === 'enterprise_standard_labor_rate' ? (
						<></>
					) : (
						<Popconfirm
							title={t('enterprise_settings.labor_rates.delete_title')}
							okText={t('enterprise_settings.labor_rates.yes')}
							cancelText={t('enterprise_settings.labor_rates.no')}
							onConfirm={() => handleDelete(record)}>
							<ReactSVG
								src={TrashCan}
								className={styles.deleteIcon}
								data-testid={`delete-icon-${record.id}`}
							/>
						</Popconfirm>
					)}
				</>
			)
		}
	].filter(Boolean)

	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<EMTable
					className={styles.laborRatesTable}
					size="small"
					dataSource={dataSourceWithKeys}
					columns={COLUMNS}
					scroll={{ x: 'max-content' }}
					summary={() => (
						<TableSummary
							handleAdd={handleAdd}
							addingLaborRate={addingLaborRate}
						/>
					)}
				/>
			</EditableContext.Provider>
		</Form>
	)
}

export default LaborRatesTable
