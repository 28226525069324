import { getNextTabbableElement } from 'app/utils'
import { useRef } from 'react'

function useNextTabbableElement() {
	const nextTabbableElementRef = useRef(null)

	const captureNextTabbableElement = () => {
		nextTabbableElementRef.current = getNextTabbableElement()
	}

	const focusNextTabbableElemement = () => {
		if (nextTabbableElementRef.current) {
			setTimeout(() => {
				nextTabbableElementRef.current.focus()
			}, 100)
		}
	}

	return {
		captureNextTabbableElement,
		focusNextTabbableElemement
	}
}

export default useNextTabbableElement
