import { PlusOutlined } from '@ant-design/icons'
import { Form, Grid, Input, Modal, Typography } from 'antd'

import Button from 'app/components/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'app/hooks/useToggle'

import { createEnterpriseCannedService } from 'app/api/enterpriseCannedService'
import { useNavigate } from 'react-router-dom'

type EnterpriseCannedService = {
	title: string
}

function CreateButton() {
	const { t } = useTranslation()
	const [modalOpen, toggleModal] = useToggle(false)
	const [title, setTitle] = useState('')
	const [form] = Form.useForm()
	const screens = Grid.useBreakpoint()
	const navigate = useNavigate()

	const createCannedServiceRequest = async () => {
		const entCannedService: EnterpriseCannedService = {
			title: title
		}

		const data = await createEnterpriseCannedService(entCannedService)
		navigate(`/enterprise-cannery/${data.id}`)
	}

	async function onSubmit(values: unknown) {
		await createCannedServiceRequest()
	}

	const newcannedServiceText = t(
		'canned_services.new_canned_service.new_canned_service'
	)

	return (
		<>
			<Button
				type="primary"
				size="large"
				onClick={toggleModal}
				icon={<PlusOutlined />}
				shape={screens.xs ? 'circle' : 'default'}>
				{!screens.xs && newcannedServiceText}
			</Button>
			<Modal
				open={modalOpen}
				cancelButtonProps={{ type: 'link' }}
				maskClosable={true}
				title={t(
					'canned_services.new_canned_service.create_new_canned_service'
				)}
				onOk={form.submit}
				okText={t('canned_services.new_canned_service.next')}
				cancelText={t('canned_services.new_canned_service.cancel')}
				onCancel={toggleModal}
				getContainer=".react-app">
				<Typography.Paragraph>
					{t('canned_services.new_canned_service.description')}
				</Typography.Paragraph>
				<Form
					layout="vertical"
					requiredMark={false}
					form={form}
					onFinish={onSubmit}>
					<Form.Item
						name="title"
						label={t('canned_services.new_canned_service.title')}
						rules={[
							{
								required: true,
								message: t('canned_services.new_canned_service.title_rule')
							}
						]}>
						<Input
							size="large"
							placeholder={t('canned_services.new_canned_service.placeholder')}
							onChange={e => setTitle(e.target.value)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default CreateButton
