import base64 from 'base-64';
function encode(value) {
    return base64.encode(Array(value.length)
        .fill('')
        .map((_, i) => String.fromCharCode(value[i]))
        .join(''));
}
function decode(value) {
    return base64.decode(value);
}
export function base64urlEncode(value) {
    return encode(value)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}
export function base64urlDecode(value) {
    return decode(value.replace(/-/g, '+').replace(/_/g, '/'));
}
