import { SearchOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { SearchParams } from 'app/api/Models/CannedServicesModel'
import Button from 'app/components/Button'

import Actions from './Actions'
import Filters from './Filters'

type CannedServicesControlsProps = {
	onSearch: (searchParam?: Partial<SearchParams>) => void
	refetch: () => void
	searchInfo: Partial<SearchParams>
	loading: boolean
	selectedcannedServicesIds: string[]
	numOfAffectedShops: number
	numberOfItems: number
	setSelectedcannedServicesIds: (serviceIds: string[]) => void
}

function CannedServicesControls({
	onSearch,
	refetch,
	searchInfo,
	loading,
	selectedcannedServicesIds,
	numOfAffectedShops,
	numberOfItems,
	setSelectedcannedServicesIds
}: CannedServicesControlsProps) {
	const { t, i18n } = useTranslation()

	const totalCountFor = (totalCount: number) => {
		const locale = i18n.language || 'en-US'
		const numberFormatter = new Intl.NumberFormat(locale)
		const total = numberFormatter.format(totalCount || 0)

		if (totalCount > 20) {
			return `${20}+`
		} else {
			return total
		}
	}

	const countString = (searchInfo: Partial<SearchParams>) => {
		if (searchInfo.searchedText)
			return t('canned_services.results_for', {
				resultsCount: totalCountFor(numberOfItems),
				searchedText: searchInfo.searchedText
			})
	}

	return (
		<Row data-testid="search-controls">
			<Col span={24}>
				<Form name="search" onFinish={onSearch}>
					<Row gutter={[16, 8]}>
						<Col xs={24} md={12} lg={8}>
							<Form.Item name="searchedText" noStyle>
								<Input
									data-testid="search-input"
									prefix={<SearchOutlined />}
									placeholder={t('canned_services.search_by_text')}
								/>
							</Form.Item>
						</Col>
						<Col>
							<Form.Item noStyle>
								<Button htmlType="submit" type="primary">
									<SearchOutlined />
									{t('canned_services.search')}
								</Button>
							</Form.Item>
						</Col>
						<Col>
							<Filters searchInfo={searchInfo} search={onSearch} />
						</Col>
						<Actions
							selectedcannedServicesIds={selectedcannedServicesIds}
							refreshList={refetch}
							numOfAffectedShops={numOfAffectedShops}
							setSelectedcannedServicesIds={setSelectedcannedServicesIds}
						/>
					</Row>
				</Form>
			</Col>
			{!loading && (
				<Col span={24}>
					<Typography.Text type="secondary">
						{countString(searchInfo)}
					</Typography.Text>
				</Col>
			)}
		</Row>
	)
}

export default CannedServicesControls
