import { Typography, Col, Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useTranslation } from 'react-i18next'

import { useMetaConfig } from 'app/providers/MetaConfigProvider'
import { useMetaConfigMutation } from 'app/providers/MetaConfigProvider/useMetaConfigQuery'

const { Text, Title } = Typography

const Header = () => {
	const { t } = useTranslation()
	const { currentTenant } = useMetaConfig()
	const { mutate: updateTenant } = useMetaConfigMutation()

	const updateRequireCategory = async (e: CheckboxChangeEvent) => {
		updateTenant({ require_categories: e.target.checked })
	}

	return (
		<>
			<Col>
				<Title>{t('enterprise_settings.categories.title')}</Title>
			</Col>
			<Col>
				<Title level={5}>
					{t('enterprise_settings.categories.functionality')}
				</Title>
			</Col>
			<Col>
				<Text>{t('enterprise_settings.categories.description')}</Text>
			</Col>
			<Col>
				<Checkbox
					checked={currentTenant.require_categories}
					onChange={updateRequireCategory}>
					<Text>{t('enterprise_settings.categories.require_categories')}</Text>
				</Checkbox>
			</Col>
		</>
	)
}

export default Header
