import { Typography, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

const { Title, Text } = Typography

type Props = {
	hasLaborRate: boolean
}

const Header = ({ hasLaborRate }: Props) => {
	const { t } = useTranslation()

	return (
		<>
			<Col>
				<Title className={styles.laborRateTitle} level={2}>
					{t('enterprise_settings.labor_rates.title')}
				</Title>
			</Col>
			<Col className={!hasLaborRate ? '' : styles.lowerCol}>
				<Text className={styles.description}>
					{t('enterprise_settings.labor_rates.description')}
				</Text>
				{hasLaborRate === false ? (
					<Col className={styles.helpCol}>
						<Text>
							{t('enterprise_settings.labor_rates.missing_canned_service')}
						</Text>
					</Col>
				) : (
					<></>
				)}
			</Col>
		</>
	)
}

export default Header
