import { Typography, Row } from 'antd'

import CannedServicesLibrary from 'app/screens/cannedServices/CannedServicesLibrary'
import CreateButton from 'app/screens/cannedServices/CreateButton'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const { Title } = Typography

function CannedServicesPage() {
	const { t } = useTranslation()

	return (
		<>
			<Row className={styles.headerTop} justify="space-between">
				<Title className={styles.title} level={3}>
					{t('canned_services.title')}
				</Title>
				<CreateButton />
			</Row>
			<CannedServicesLibrary />
		</>
	)
}

export default CannedServicesPage
