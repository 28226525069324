import {
	createContext,
	ReactNode,
	useEffect,
	useContext,
	useState
} from 'react'
import {
	processLoginResult,
	redirectToLogin,
	logout,
	refreshTokens
} from '@shop-ware/auth-flow'
import { setAxiosHeaders, clearAxiosAuthSettings } from 'app/api/axios'
import { APPLICATION_ID, BASE_URL } from 'app/utils/constants'
import {
	clearAuthInfo,
	getAuthAccessToken,
	getAuthRefreshToken,
	getIdentityContext,
	updateAuthInfo,
	getAuthConfig
} from './utils'
import PageLoader from 'app/components/PageLoader'
import ErrorPage from 'app/components/ErrorPage'
import { useTranslation } from 'react-i18next'

const AUTH_CONFIG = getAuthConfig()

interface AuthContextInterface {
	logoutUser: () => Promise<void>
}

const AuthContext = createContext<AuthContextInterface>({
	logoutUser: () =>
		new Promise(() => {
			console.log('No definition provided')
		})
})

interface AuthProviderProps {
	children: ReactNode | ReactNode[]
}

const AuthProvider = ({ children }: AuthProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [loggedIn, setLoggedIn] = useState(false)
	const [isAuthorizedUser, setIsAuthorizedUser] = useState(true)
	const { t } = useTranslation()
	const clearAuthHeaderInfo = () => {
		clearAuthInfo()
		clearAxiosAuthSettings()
	}

	const logoutUser = async () => {
		setIsLoading(true)
		const refreshToken = getAuthRefreshToken()
		const identityContext = getIdentityContext()

		try {
			await logout(identityContext, refreshToken, AUTH_CONFIG)
			clearAuthHeaderInfo()
			redirectToLogin(APPLICATION_ID, BASE_URL, AUTH_CONFIG)
		} catch {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		/**
		 * Processes the login result by obtaining the access token, refresh token,
		 * access token expiration, and identity context.
		 * it updates the authentication information with the new tokens,
		 * sets Axios headers with the access token, sets the logged-in state to true,
		 * and redirects the user to the base URL.
		 * If any error occurs during the process, it clears Axios authentication settings,
		 * sets the logged-in state to false, and performs a redirect.
		 * @returns {void}
		 */
		const processLogin = async () => {
			try {
				const {
					accessToken,
					refreshToken,
					accessTokenExpiration,
					identityContext
				} = await processLoginResult(AUTH_CONFIG)

				updateAuthInfo({
					accessToken,
					refreshToken,
					accessTokenExpiration,
					identityContext
				})
				setAxiosHeaders({
					Authorization: `Bearer ${accessToken as string}`
				})

				setLoggedIn(true)
				setIsLoading(false)
				window.location.replace(BASE_URL)
			} catch (error) {
				clearAuthHeaderInfo()
				redirectToLogin(APPLICATION_ID, BASE_URL, AUTH_CONFIG)
			}
		}

		/**
		 * Initializes stored tokens for authentication.
		 * Retrieves the stored access token, old identity context, and old refresh token.
		 * if there is no required values present, it will call processLogin()
		 * If all three values exist, attempts to refresh the tokens using the old identity context and refresh token.
		 * If the refresh is successful and the user is authorized, updates the authentication information,
		 * sets Axios headers with the stored access token, and sets the logged-in state to true.
		 * If any error occurs during the process, clears the authentication information,
		 * clears Axios authentication settings, and performs a redirect.
		 * @returns {void}
		 */
		const initializeStoredTokens = async () => {
			const storedAccessToken = getAuthAccessToken()
			const oldIdentityContext = getIdentityContext()
			const oldRefreshToken = getAuthRefreshToken()
			const hasTokens =
				storedAccessToken && oldRefreshToken && oldIdentityContext
			if (!hasTokens) return processLogin()
			try {
				const {
					accessToken,
					refreshToken,
					identityContext,
					accessTokenExpiration
				} = await refreshTokens(
					oldIdentityContext,
					oldRefreshToken,
					AUTH_CONFIG
				)
				updateAuthInfo({
					accessToken,
					refreshToken,
					identityContext,
					accessTokenExpiration
				})
				setAxiosHeaders({
					Authorization: `Bearer ${storedAccessToken}`
				})
				setLoggedIn(true)
				setIsLoading(false)
			} catch (error) {
				// Since the error code is not being exposed, we dont really know why the refreshTokens call fails - https://github.com/shop-ware/platform-web/blob/f557b59468089d57c27b8c21de32c3c4bdf77d28/auth-flow/src/authentication.ts#L62C1-L62C1
				setIsAuthorizedUser(false)
				setIsLoading(false)
			}
		}

		if (!loggedIn) initializeStoredTokens()
	}, [loggedIn])

	if (isLoading) return <PageLoader />

	return (
		<AuthContext.Provider value={{ logoutUser }}>
			{isAuthorizedUser ? (
				children
			) : (
				<ErrorPage
					code={401}
					message={t('enterprise_manager.errors.unauthorized')}
				/>
			)}
		</AuthContext.Provider>
	)
}

function useAuth() {
	const context = useContext(AuthContext)

	if (context === undefined) {
		throw new Error('useAuth must be used within AuthProvider')
	}

	return context
}

export { AuthProvider, useAuth }
