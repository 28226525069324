import { useQueryClient, useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
	CannedService,
	EnterpriseCannedService,
	EnterpriseCannedServicePayload,
	EnterpriseLaborRate
} from 'app/api/Models/CannedServiceModel'

import * as api from 'app/api/enterpriseCannedService'

export const BASE_QUERY_KEY = 'cannedServices'

export const useCannedServiceQuery = (
	callback: (data: EnterpriseCannedService) => void
) => {
	const { serviceId } = useParams()
	return useQuery(
		[BASE_QUERY_KEY, serviceId],
		() => api.getCannedService(serviceId),
		{
			onSuccess: callback,
			refetchOnWindowFocus: false,
			onError: error => {
				window.location.assign('/')
			}
		}
	)
}

export const useCannedServiceMutation = () => {
	const { serviceId } = useParams()
	const queryClient = useQueryClient()

	const onSaveCannedService = async (data: EnterpriseCannedServicePayload) => {
		return await api.saveService(serviceId, data)
	}

	return useMutation(onSaveCannedService, {
		onMutate: async (newCannedServiceData: EnterpriseCannedServicePayload) => {
			// Cancel any outgoing refetches
			// (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries({ queryKey: [BASE_QUERY_KEY, serviceId] })

			// Snapshot the previous value
			const previousCannedServicesData = queryClient.getQueryData([
				BASE_QUERY_KEY,
				serviceId
			]) as EnterpriseCannedService

			// Optimistically update to the new value
			queryClient.setQueryData(
				[BASE_QUERY_KEY, serviceId],
				(old: EnterpriseCannedService) => {
					const {
						enterprise_canned_service_labors_attributes,
						enterprise_canned_service_generic_parts_attributes,
						enterprise_canned_service_hazmats_attributes,
						enterprise_canned_service_sublets_attributes,
						enterprise_canned_service_inspections_attributes,
						category_id,
						enterprise_labor_rate_id,
						shop_ids,
						...rest
					} = newCannedServiceData.enterprise_canned_service

					const cachedLaborRate = queryClient
						.getQueryData<EnterpriseLaborRate[]>('getLaborRates')
						?.find(laborRate => laborRate.id === enterprise_labor_rate_id)
					const laborRateAmountCents = cachedLaborRate
						? cachedLaborRate.amount_cents
						: old.enterprise_labor_rate.amount_cents

					return {
						...old,
						[CannedService.LABORS]: enterprise_canned_service_labors_attributes,
						[CannedService.GENERIC_PARTS]:
							enterprise_canned_service_generic_parts_attributes,
						[CannedService.HAZMATS]:
							enterprise_canned_service_hazmats_attributes,
						[CannedService.SUBLETS]:
							enterprise_canned_service_sublets_attributes,
						[CannedService.INSPECTIONS]:
							enterprise_canned_service_inspections_attributes,
						categories: category_id
							? [{ category_id, tenant_id: old.tenants[0].id }]
							: [],
						enterprise_labor_rate: {
							...old.enterprise_labor_rate,
							id: enterprise_labor_rate_id,
							amount_cents: laborRateAmountCents
						},
						shops: shop_ids.map(id => ({ id })),
						...rest
					}
				}
			)

			// Return a context object with the snapshotted value
			return { previousCannedServicesData }
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newCannedServicesData, context) => {
			queryClient.setQueryData(
				[BASE_QUERY_KEY, serviceId],
				context.previousCannedServicesData
			)
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [BASE_QUERY_KEY, serviceId] })
		}
	})
}
