import { useRef } from 'react'
import { Popconfirm, Form, Typography, Button } from 'antd'
import Checkbox from 'app/components/Checkbox/index'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'

import EMTAble from 'app/components/EMTable'
import { CannedService } from 'app/api/Models/CannedServiceModel'
import TableSummary from './TableSummary'
import Editable from 'app/components/Editable'
import TrashCan from 'app/assets/icons/FA6/trash-can.svg'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import { useCurrency } from 'app/hooks/useCurrency'
import { focusConfirmButton } from 'app/screens/cannedService/helper'
import useNextTabbableElement from 'app/hooks/useNextTabbableElement'

import styles from 'app/screens/cannedService/serviceTables/styles.module.scss'

const { Text } = Typography

const LaborsTable = () => {
	const { t } = useTranslation()
	const { formatCurrency } = useCurrency()
	const { isFixedPrice, laborRateAmount } = useCanneryServicesContext()
	const [form] = Form.useForm()
	const {
		dataSourceWithKeys,
		onOrderChanged,
		setField,
		handleSave,
		handleDelete
	} = useDataSource(CannedService.LABORS, form)
	const { captureNextTabbableElement, focusNextTabbableElemement } =
		useNextTabbableElement()

	const trashButtonRef = useRef(null)

	const columns = [
		{
			key: 'sort',
			className: styles.dragAndDropColumn
		},
		{
			className: styles.titleColumn,
			title: t('canned_service.labors.title'),
			dataIndex: 'name',
			render: (val, record) => (
				<Editable
					className={styles.titleCell}
					type="text"
					dataIndex="name"
					value={val}
					placeholder={t('canned_service.labors.empty_title')}
					handleSave={() => handleSave(record, 'name')}
					onClick={() => setField(record, 'name')}
					rules={[
						{
							required: true,
							message: t('canned_service.error.min_one_character')
						}
					]}
				/>
			)
		},
		{ className: styles.normalColumn },
		{
			className: styles.normalColumn,
			title: t('canned_service.labors.hours'),
			dataIndex: 'hours',
			render: (val, record) => (
				<Editable
					className={styles.normalCell}
					type="number"
					dataIndex="hours"
					value={val}
					handleSave={() => handleSave(record, 'hours')}
					onClick={() => setField(record, 'hours')}
				/>
			)
		},
		...(!isFixedPrice
			? [
					{
						className: styles.normalColumn,
						title: t('canned_service.price'),
						render: (_, record) => (
							<Text className={styles.textCell}>
								{formatCurrency(
									(Number(record.hours) * Number(laborRateAmount)).toString()
								)}
							</Text>
						)
					}
				]
			: [{ className: styles.normalColumn }]),
		{
			className: styles.normalColumn,
			title: t('canned_service.tax'),
			dataIndex: 'taxable',
			render: (_, record) => (
				<Form.Item
					name={`taxable-${record.key}`}
					valuePropName="checked"
					initialValue={!record.taxable}>
					<Checkbox
						className={styles.taxCell}
						onChange={() => handleSave(record, 'taxable')}
						data-testid={`tax-checkbox-${record.key}`}>
						{t('canned_service.taxable')}
					</Checkbox>
				</Form.Item>
			)
		},
		{
			title: '',
			className: styles.actionColumn,
			render: (_, record) => (
				<Popconfirm
					destroyTooltipOnHide={true}
					title={t('canned_service.delete_row_confirm')}
					okText={t('canned_service.yes')}
					cancelText={t('canned_service.no')}
					onConfirm={() => {
						handleDelete(record.key)
						focusNextTabbableElemement()
					}}
					onOpenChange={open => {
						if (open) {
							captureNextTabbableElement()
							focusConfirmButton()
						} else trashButtonRef.current!.focus()
					}}>
					<Button type="ghost" ref={trashButtonRef}>
						<ReactSVG
							src={TrashCan}
							className={styles.deleteIcon}
							data-testid={`delete-icon-${record.key}`}
						/>
					</Button>
				</Popconfirm>
			)
		}
	]

	return (
		<Form form={form} component={false}>
			<EMTAble
				size="small"
				className={styles.serviceTable}
				draggable={true}
				dataSource={dataSourceWithKeys}
				columns={columns}
				onOrderChanged={onOrderChanged}
				rowClassName={() => 'editableRow'}
				scroll={{ x: 'max-content' }}
				summary={() => <TableSummary />}
			/>
		</Form>
	)
}

export default LaborsTable
