import { useRef, useEffect, useState } from 'react'
import { CloseCircleFilled } from '@ant-design/icons'
import classNames from 'classnames'
import { Col, Row, Typography, InputNumber } from 'antd'
import Checkbox from 'app/components/Checkbox/index'
import Popconfirm from 'app/components/Popconfirm/index'
import { useTranslation } from 'react-i18next'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import { useCurrency } from 'app/hooks/useCurrency'
import { useSubtotal } from 'app/screens/cannedService/hooks/useSubtotal'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'

import styles from './styles.module.scss'
import commonStylse from 'app/screens/cannedService/serviceTables/styles.module.scss'

const { Text } = Typography

const FixedPrice = () => {
	const [openPopup, setOpenPopup] = useState<boolean>(false)
	const { t } = useTranslation()
	const { recalculateFixPricingLaborSubtotal } = useSubtotal()
	const { getCurrencySymbol } = useCurrency()
	const {
		isFixedPrice,
		fixedPrice,
		setFixedPrice,
		setIsFixedPrice,
		setFixedPricingLaborSubtotal,
		cannedServiceEdited,
		setCannedServiceEdited
	} = useCanneryServicesContext()

	const inputRef = useRef(null)

	const onChangeHandle = (e: CheckboxChangeEvent) => {
		setFixedPrice(null)
		setIsFixedPrice(e.target.checked)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
		const newFixPricingLaborSubtotal = recalculateFixPricingLaborSubtotal(0)
		setFixedPricingLaborSubtotal(newFixPricingLaborSubtotal)
	}

	const onFixedPriceChangeHandle = e => {
		const value = e.target.value
		const isFixedPricingCheckboxSelected =
			e.relatedTarget?.id === 'fixed-pricing-checkbox' ||
			e.relatedTarget?.id === 'fixed-pricing-text'
		const newFixPricingLaborSubtotal = recalculateFixPricingLaborSubtotal(value)
		const shouldShowPopup =
			(newFixPricingLaborSubtotal < 0 || !value || isNaN(value)) &&
			!isFixedPricingCheckboxSelected
		if (shouldShowPopup) {
			setOpenPopup(true)
			return
		}
		setOpenPopup(false)
		setFixedPrice(value)
		setFixedPricingLaborSubtotal(newFixPricingLaborSubtotal)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	useEffect(() => {
		const newFixPricingLaborSubtotal =
			recalculateFixPricingLaborSubtotal(fixedPrice)
		if (isFixedPrice && newFixPricingLaborSubtotal < 0) {
			inputRef.current!.focus()
		}
	}, [isFixedPrice, fixedPrice, recalculateFixPricingLaborSubtotal])

	return (
		<Col>
			<Row gutter={[8, 0]} className={styles.marginBottom}>
				<Col span={3} />
				<Col span={18}>
					<Checkbox
						id="fixed-pricing-checkbox"
						checked={isFixedPrice}
						onChange={onChangeHandle}>
						<Text tabIndex={-1} id="fixed-pricing-text">
							{t('canned_service.details.fixed_pricing')}
						</Text>
					</Checkbox>
				</Col>
			</Row>
			{isFixedPrice && (
				<Row gutter={[16, 0]} align="middle">
					<Col span={3} className={styles.rightAligned}>
						<Text>{t('canned_service.details.fixed_price')}</Text>
					</Col>
					<Col span={18}>
						<Popconfirm
							open={openPopup}
							overlayClassName={styles.fixedPricePopup}
							showCancel={false}
							title={t('canned_service.error.fixed_price_exceed')}
							okText={t('canned_service.ok_got_it')}
							onConfirm={() => {
								setOpenPopup(false)
								inputRef.current!.focus()
							}}>
							<InputNumber
								className={classNames(
									styles.fixedPriceInput,
									openPopup && commonStylse.hasError
								)}
								ref={inputRef}
								prefix={getCurrencySymbol()}
								step={0.01}
								min={0}
								precision={2}
								value={fixedPrice}
								onBlur={onFixedPriceChangeHandle}
								onPressEnter={onFixedPriceChangeHandle}
								status={openPopup && 'error'}
								addonAfter={openPopup && <CloseCircleFilled />}
								data-testid="fixed-price-input"
							/>
						</Popconfirm>
					</Col>
				</Row>
			)}
		</Col>
	)
}

export default FixedPrice
