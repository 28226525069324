import * as i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enTranslations from 'app/locales/en/react.json'
import esTranslations from 'app/locales/es_MX/react.json'
import ptTranslations from 'app/locales/pt_BR/react.json'

i18n
	// pass the i18n instance to react-i18next.z
	.use(LanguageDetector)
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources: {
			en: {
				react: enTranslations
			},
			'pt-BR': {
				react: ptTranslations
			},
			'es-MX': {
				react: esTranslations
			}
		},
		detection: {
			order: ['querystring', 'htmlTag'],
			lookupQuerystring: 'locale'
		},
		ns: ['react'],
		defaultNS: 'react',
		lng:
			document.documentElement.lang === 'zz'
				? 'en'
				: document.documentElement.lang,
		fallbackLng: 'en',
		supportedLngs: ['en', 'pt-BR', 'es-MX', 'zz'],
		postProcess: ['pseudo'],
		react: {
			useSuspense: false
		}
	})

export default i18n
