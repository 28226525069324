import { useState } from 'react'
import { Col, Typography, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Editable from 'app/components/Editable'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import styles from './styles.module.scss'

const { Text } = Typography

const CannedServiceTitle = () => {
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [form] = Form.useForm()
	const { t } = useTranslation()
	const { title, setTitle, cannedServiceEdited, setCannedServiceEdited } =
		useCanneryServicesContext()

	const updateTitle = async () => {
		const values = await form.validateFields()
		setTitle(values['title'])
		setIsEditing(false)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}
	const onClickHandle = () => {
		form.setFieldValue('title', title)
		setIsEditing(true)
	}

	return (
		<Form form={form} component={false}>
			<Col span={3} className={styles.rightAligned}>
				<Text>{t('canned_service.details.title')}</Text>
			</Col>
			<Col span={18}>
				<Editable
					className={classNames(styles.title, !isEditing && styles.blueTitle)}
					dataIndex="title"
					value={title}
					type="text"
					handleSave={() => updateTitle()}
					onClick={onClickHandle}
					rules={[
						{
							required: true,
							message: t('canned_service.error.required_field', {
								field: 'Title'
							})
						}
					]}
				/>
			</Col>
		</Form>
	)
}

export default CannedServiceTitle
