import { Table, Form, Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'

import { EnterpriseCategory } from 'app/api/Models/CategoryModel'

import styles from './styles.module.scss'

const { Summary } = Table
const { Cell, Row } = Summary

type Props = {
	handleAdd: (newValue: EnterpriseCategory) => void
	numberOfItems: number
	addingCategory: boolean
}

function TableSummary({ handleAdd, numberOfItems, addingCategory }: Props) {
	const { t } = useTranslation()

	const [form] = Form.useForm()

	const addItem = async () => {
		const values = await form.validateFields()
		handleAdd({
			id: (numberOfItems + 1).toString(),
			...values
		})
		form.resetFields()
	}

	return (
		<>
			{addingCategory ? (
				<>
					<Summary>
						<Form form={form} component={false}>
							<Row className={styles.tableSummaryRow}>
								<Cell index={0}>
									<Form.Item
										name="text"
										className={styles.formItem}
										validateTrigger="onBlur"
										rules={[
											{
												required: true,
												message: t('enterprise_settings.error')
											}
										]}>
										<Input
											data-testid="new-row-name"
											placeholder={t(
												'enterprise_settings.categories.empty_title'
											)}
											onPressEnter={addItem}
										/>
									</Form.Item>
								</Cell>
								<Cell index={1} className={styles.actionColumn}>
									<Button
										onClick={addItem}
										type="link"
										className={styles.customLink}
										data-testid="save-button">
										{t('enterprise_settings.save')}
									</Button>
								</Cell>
							</Row>
						</Form>
					</Summary>
				</>
			) : (
				<></>
			)}
		</>
	)
}

export default TableSummary
