import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { dayjs } from 'day'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'

const { Text } = Typography

const CreatedAtUpdatedAt = () => {
	const { t } = useTranslation()
	const { data } = useCanneryServicesContext()

	const serviceCreatedAt = data.created_at
	const serviceCreatedBy = data.created_by_profile.full_name
	const serviceLastUpdatedAt = data.updated_at
	const serviceLastUpdatedBy = data.last_updated_by_profile.full_name
	const laborRateLastUpdatedAt = data.enterprise_labor_rate.updated_at
	const laborRateLastUpdatedBy =
		data.enterprise_labor_rate.last_updated_by_profile.full_name

	const laborRateWasUpdatedMoreRecently =
		new Date(serviceLastUpdatedAt).valueOf() <
		new Date(laborRateLastUpdatedAt).valueOf()
	const lastUpdatedAt = laborRateWasUpdatedMoreRecently
		? laborRateLastUpdatedAt
		: serviceLastUpdatedAt
	const lastUpdatedBy = laborRateWasUpdatedMoreRecently
		? laborRateLastUpdatedBy
		: serviceLastUpdatedBy

	const lastUpdatedAtTimeFromNow = dayjs(lastUpdatedAt).fromNow()
	const lastUpdatedAtText = dayjs(lastUpdatedAt).format('l LT')

	const serviceCreatedAtText = dayjs(serviceCreatedAt).format('l LT')

	return (
		<>
			<Text type="secondary">
				{t('canned_service.details.last_updated_by_at', {
					timeFromNow: lastUpdatedAtTimeFromNow,
					updatedBy: lastUpdatedBy,
					updatedAt: lastUpdatedAtText
				})}
			</Text>
			<Text type="secondary">&nbsp;|&nbsp;</Text>
			<Text type="secondary">
				{t('canned_service.details.created_by_at', {
					createdBy: serviceCreatedBy,
					createdAt: serviceCreatedAtText
				})}
			</Text>
		</>
	)
}

export default CreatedAtUpdatedAt
