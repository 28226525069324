import { useContext, useRef, useEffect } from 'react'
import { Form, Input, Typography, InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCurrency } from 'app/hooks/useCurrency'
import classNames from 'classnames'
import { EnterpriseLaborRate } from 'app/api/Models/LaborRateModel'
import { EditableContext } from './table'

import styles from './styles.module.scss'

const { Text } = Typography

type Type = 'text' | 'number' | 'currency'

type EditableCellProps = {
	dataIndex: string | number
	record: EnterpriseLaborRate
	value: string | number
	type: Type
	editing: boolean
	updateLaborRate: (id: number) => void
}

const EditableCell = ({
	dataIndex,
	record,
	value,
	type,
	editing,
	updateLaborRate
}: EditableCellProps) => {
	const inputRef = useRef(null)
	const form = useContext(EditableContext)!
	const { t } = useTranslation()
	const { formatCurrency, getCurrencySymbol } = useCurrency()

	const formatValue = (): string | number => {
		if (value) {
			if (type === 'text') return value
			if (type === 'currency') return formatCurrency(value.toString())
		}
		if (type === 'currency') return formatCurrency('0.0')
	}

	useEffect(() => {
		form.resetFields()
	}, [editing, form])

	return (
		<>
			{editing ? (
				<>
					{(type === 'number' || type === 'currency') && (
						<Form.Item
							className={styles.formItem}
							name={dataIndex}
							validateTrigger="onBlur"
							initialValue={value}
							rules={[
								{
									required: true,
									message: t('enterprise_settings.labor_rates.error_amount')
								},
								() => ({
									validator(_, value) {
										if (value < 0) {
											return Promise.reject(
												t('enterprise_settings.labor_rates.error_negative')
											)
										}
										return Promise.resolve()
									}
								})
							]}>
							<InputNumber
								precision={2}
								prefix={getCurrencySymbol()}
								data-testid={`input-number-${record.id}`}
								type="number"
								step={0.01}
								controls={true}
								ref={inputRef}
								onPressEnter={() => updateLaborRate(record.id)}
							/>
						</Form.Item>
					)}
					{type === 'text' && (
						<Form.Item
							className={styles.formItem}
							name={dataIndex}
							validateTrigger="onBlur"
							initialValue={value}
							rules={[
								{
									required: true,
									message: t('enterprise_settings.labor_rates.error_name')
								},
								{
									validator(_, value) {
										if (
											value.toLowerCase().trim() ===
												'enterprise_standard_labor_rate' ||
											value.toLowerCase().trim() === 'enterprise standard'
										) {
											return Promise.reject(
												t(
													'enterprise_settings.labor_rates.enterprise_standard_label_error'
												)
											)
										} else if (value.toLowerCase().trim() === 'standard') {
											return Promise.reject(
												t(
													'enterprise_settings.labor_rates.standard_label_error'
												)
											)
										}
										return Promise.resolve()
									}
								}
							]}>
							<Input
								ref={inputRef}
								data-testid={`input-text-${record.id}`}
								onPressEnter={() => updateLaborRate(record.id)}
							/>
						</Form.Item>
					)}
				</>
			) : (
				<Text
					className={classNames(styles.editableCell)}
					data-testid={`${type}-field-${record.id}`}>
					{formatValue()}
				</Text>
			)}
		</>
	)
}

export default EditableCell
