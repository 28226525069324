import { ChangeEventHandler, useState, Fragment, useEffect } from 'react'
import { Col, Row, Typography, Input, Popover, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { ReactSVG } from 'react-svg'

import DropDownIcon from 'app/assets/icons/FA6/dropdown.svg'
import { getShops } from 'app/api/shops'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import notification from 'app/components/notification'
import Popconfirm from 'app/components/Popconfirm'
import Checkbox from 'app/components/Checkbox'

import styles from './styles.module.scss'

const { Text } = Typography

const ShopLocations = () => {
	const [openPopconfirm, setOpenPopconfirm] = useState<boolean>(false)
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
	const [searchTerm, setSearchTerm] = useState<string>('')

	const { t } = useTranslation()
	const {
		data,
		selectedShopIds,
		setSelectedShopIds,
		cannedServiceEdited,
		setCannedServiceEdited
	} = useCanneryServicesContext()

	const { data: shopData } = useQuery('shops', getShops, {
		refetchOnWindowFocus: false
	})

	const shopOptions =
		shopData?.map(shop => {
			return {
				name: shop.identifier,
				id: String(shop.id)
			}
		}) ?? []

	useEffect(() => {
		const inputBox = document.querySelector(
			'#dropdown-input'
		) as HTMLInputElement

		const inputBoxEvent = (event: KeyboardEvent) => {
			if (!isPopoverVisible) return

			const popOver = document.querySelector(
				'.ant-popover [role="tooltip"]'
			) as HTMLElement
			const checkboxes = popOver.querySelectorAll('input')
			const firstCheckbox = checkboxes[0] as HTMLElement
			const lastCheckbox = checkboxes[checkboxes.length - 1] as HTMLElement

			if (event.key === 'Tab') {
				if (event.shiftKey) {
					event.preventDefault()
					lastCheckbox.focus()
				} else if (!event.shiftKey) {
					event.preventDefault()
					firstCheckbox.focus()
				}
			}
		}

		if (isPopoverVisible && !openPopconfirm) {
			inputBox.focus()

			setTimeout(() => {
				const popOver = document.querySelector(
					'.ant-popover [role="tooltip"]'
				) as HTMLElement

				if (popOver) {
					const checkboxes = popOver.querySelectorAll('input')
					const firstCheckbox = checkboxes[0] as HTMLElement

					inputBox.addEventListener('keydown', inputBoxEvent)
					popOver.addEventListener('keydown', event => {
						const checkboxes = popOver.querySelectorAll('input')
						const lastCheckbox = checkboxes[
							checkboxes.length - 1
						] as HTMLElement
						if (event.key === 'Tab') {
							if (event.shiftKey && document.activeElement === firstCheckbox) {
								event.preventDefault()
								inputBox.focus()
							} else if (
								!event.shiftKey &&
								document.activeElement === lastCheckbox
							) {
								event.preventDefault()
								inputBox.focus()
							}
						}
					})
				}
			}, 100)
		}
		return () => {
			inputBox.removeEventListener('keydown', inputBoxEvent)
		}
	}, [isPopoverVisible, openPopconfirm])

	const canShowOption = (option: (typeof shopOptions)[0]) => {
		return option.name.toLowerCase().includes(searchTerm.toLowerCase())
	}

	const filterShops: ChangeEventHandler<HTMLInputElement> = e => {
		if (!isPopoverVisible) setIsPopoverVisible(true)
		setSearchTerm(e.currentTarget.value.trim())
	}

	const toggleAllFiltered = () => {
		if (data.shops.length > 0 && selectedShopIds.length === shopOptions.length)
			return setOpenPopconfirm(true)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
		if (selectedShopIds.length === shopOptions.length)
			return setSelectedShopIds([])
		setSelectedShopIds(
			shopOptions.map((option: (typeof shopOptions)[0]) => option.id)
		)
	}

	const toggleChecked = ({ id, name }: (typeof shopOptions)[0]) => {
		if (!cannedServiceEdited) setCannedServiceEdited(true)
		const currentIndex = selectedShopIds.indexOf(id)
		const newChecked = [...selectedShopIds]
		if (currentIndex === -1) {
			newChecked.push(id)
		} else {
			newChecked.splice(currentIndex, 1)
			if (data.shops.some(shop => String(shop.id) === id)) {
				notification.warning({
					theme: 'dark',
					message: t('canned_service.details.shop_location.deselect_message', {
						name
					}),
					description: t(
						'canned_service.details.shop_location.deselect_description',
						{
							name
						}
					)
				})
			}
		}
		setSelectedShopIds(newChecked)
	}

	const onConfirmHandle = () => {
		setSelectedShopIds([])
		setOpenPopconfirm(false)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	const getContent = () => (
		<>
			<Popconfirm
				open={openPopconfirm}
				overlayClassName={styles.popConfirm}
				title={t('canned_service.details.shop_location.deselect_all_message')}
				description={t(
					'canned_service.details.shop_location.deselect_all_description.shops',
					{
						count: data.shops.length
					}
				)}
				okText={t('canned_service.details.shop_location.yes')}
				cancelText={t('canned_service.details.shop_location.no')}
				onConfirm={() => onConfirmHandle()}
				onCancel={() => setOpenPopconfirm(false)}>
				<Checkbox
					data-testid="all-shops-checkbox"
					className={styles.selectAll}
					checked={selectedShopIds.length === shopOptions.length}
					onClick={() => toggleAllFiltered()}>
					{t('canned_service.details.shop_location.select_all', {
						count: shopOptions.length
					})}
				</Checkbox>
			</Popconfirm>
			<Divider className={styles.customDivider} />
			<Row className={styles.shopList}>
				{shopOptions.map(option => {
					return (
						<Fragment key={option.id}>
							{canShowOption(option) && (
								<Col data-testid={`shop-${option.id}`}>
									<Checkbox
										data-testid={`shop-${option.id}-checkbox`}
										className={styles.shopCheckbox}
										checked={selectedShopIds.includes(option.id)}
										onClick={() => toggleChecked(option)}>
										{option.name}
									</Checkbox>
								</Col>
							)}
						</Fragment>
					)
				})}
			</Row>
		</>
	)

	return (
		<>
			<Row gutter={[16, 0]} align="middle">
				<Col span={3} className={styles.rightAligned}>
					<Text>
						{t('canned_service.details.shop_location.shop_locations')}
					</Text>
				</Col>
				<Col span={18}>
					<Popover
						trigger="click"
						showArrow={false}
						placement="bottomLeft"
						overlayClassName={styles.popover}
						open={isPopoverVisible}
						onOpenChange={setIsPopoverVisible}
						destroyTooltipOnHide={true}
						content={getContent()}>
						<Col
							data-testid="shop-locations-field"
							className={styles.dropdownParent}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									setIsPopoverVisible(!isPopoverVisible)
								}
							}}
							onClick={() => setIsPopoverVisible(!isPopoverVisible)}>
							<Input
								id={'dropdown-input'}
								placeholder={t(
									'canned_service.details.shop_location.assign_to_locations'
								)}
								onChange={filterShops}
							/>
							<Col className={styles.tagWrapper}>
								{selectedShopIds.length > 0 && (
									<Col className={styles.shopTag}>
										{t('canned_service.details.shop_location.selected', {
											count: selectedShopIds.length
										})}
									</Col>
								)}
								<ReactSVG src={DropDownIcon} />
							</Col>
						</Col>
					</Popover>
				</Col>
			</Row>
		</>
	)
}

export default ShopLocations
