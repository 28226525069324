import Decimal from 'decimal.js'

const CURRENCY_FIELD_NAMES = [
	'price_cents',
	'fee_cents',
	'max_quantity_charge_per_item_cents',
	'max_cost_excess_flat_amount_cents',
	'max_cost_price_cents'
]

function isCurrencyField(dataIndex: string): boolean {
	return CURRENCY_FIELD_NAMES.includes(dataIndex)
}

function toDollars(number: number): number {
	return new Decimal(number).div(100).toNumber()
}

function toCents(number: number): number {
	return new Decimal(number).mul(100).toNumber()
}

function focusConfirmButton() {
	// Need to wait for the popup to show in the DOM
	setTimeout(() => {
		const popConfirm = document.querySelector(
			'.ant-popconfirm-buttons'
		) as HTMLElement

		if (popConfirm) {
			const buttons = popConfirm.querySelectorAll('button')
			const firstButton = buttons[0] as HTMLElement
			const lastButton = buttons[buttons.length - 1] as HTMLElement
			firstButton.focus()
			popConfirm.addEventListener('keydown', event => {
				if (event.key === 'Tab') {
					if (document.activeElement === firstButton) {
						event.preventDefault()
						lastButton.focus()
					} else if (document.activeElement === lastButton) {
						event.preventDefault()
						firstButton.focus()
					}
				}
			})
		}
	}, 100)
}

export { isCurrencyField, toDollars, toCents, focusConfirmButton }
