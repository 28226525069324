import { useQueryClient, useMutation, useQuery } from 'react-query'

import { useMetaConfig } from 'app/providers/MetaConfigProvider'
import * as api from 'app/api/metaConfig'

export const QUERY_KEY = 'getMetaConfig'

export const useMetaConfigQuery = callback => {
	return useQuery(QUERY_KEY, () => api.getMetaConfig(), {
		onSuccess: callback,
		refetchOnWindowFocus: false
	})
}

export const useMetaConfigMutation = () => {
	const queryClient = useQueryClient()
	const { currentTenant } = useMetaConfig()

	const onUpdateTenant = async data => {
		return await api.updateTenant(currentTenant.id, data)
	}

	return useMutation(onUpdateTenant, {
		onMutate: async newTenantData => {
			// Cancel any outgoing refetches
			// (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries({ queryKey: QUERY_KEY })

			// Snapshot the previous value
			const previousMetaConfigData = queryClient.getQueryData(QUERY_KEY)

			// Optimistically update to the new value
			queryClient.setQueryData(QUERY_KEY, (old: any) => ({
				...old,
				currentTenant: { ...currentTenant, ...newTenantData }
			}))

			// Return a context object with the snapshotted value
			return { previousMetaConfigData }
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newTenantData, context) => {
			queryClient.setQueryData(QUERY_KEY, context.previousMetaConfigData)
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: QUERY_KEY })
		}
	})
}
