import axios from 'axios';
import { Logger } from '@shop-ware/logger';
import { AuthFlowError } from './error.js';
const logger = new Logger('authentication');
const auth = axios.create({
    timeout: 30000
});
export async function token(servicesUrl, { applicationId, code, codeVerifier }) {
    const body = {
        applicationId,
        code,
        codeVerifier
    };
    try {
        const { data } = await auth.post(`${servicesUrl}/auth/v1/token`, body);
        return data;
    }
    catch (err) {
        logger.error('Failed exchanging the auth code for tokens', err.message);
        throw new AuthFlowError('TOKEN_EXCHANGE_FAILED', 'Failed exchanging the auth code for tokens');
    }
}
export async function verifyIssuer(servicesUrl, params) {
    try {
        const { data } = await auth.get(`${servicesUrl}/auth/v1/verifyIssuer`, {
            params
        });
        return data;
    }
    catch (err) {
        logger.error('Failed verifying the issuer', err.message);
        throw new AuthFlowError('ISSUER_VERIFY_FAILED', 'Failed verifying the issuer');
    }
}
export async function refreshTokens(servicesUrl, { applicationId, tenantId, userId }, refreshToken) {
    try {
        const body = { applicationId, tenantId, userId, refreshToken };
        const { data } = await auth.post(`${servicesUrl}/auth/v1/refreshTokens`, body);
        return data;
    }
    catch (err) {
        logger.error('Failed refreshing tokens', err.message);
        throw new AuthFlowError('REFRESH_TOKENS_FAILED', 'Failed refreshing the tokens');
    }
}
export async function logout(servicesUrl, { applicationId, tenantId, userId }, refreshToken) {
    try {
        const body = { applicationId, tenantId, userId, refreshToken };
        await auth.post(`${servicesUrl}/auth/v1/logout`, body);
    }
    catch (err) {
        logger.error('Failed logging out', err.message);
        throw new AuthFlowError('LOGOUT_FAILED', 'Failed logging out');
    }
}
