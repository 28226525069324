import { CurrentTenant } from './MetaConfig'
import { Shop } from './ShopModel'

export type EnterpriseCannedServiceBase = {
	id: number
	name: string
	tenant_id?: any
	enterprise_canned_service_id: number
	created_by_profile: {
		full_name: string
	}
	last_updated_by_profile: {
		full_name: string
	}
	created_at: string
	updated_at: string
	row_order: number
	_destroy?: boolean
}

export type EnterpriseLaborRate = {
	id: number
	label: string
	tenant_id?: any
	amount_cents: number
	created_by_profile: {
		full_name: string
	}
	last_updated_by_profile: {
		full_name: string
	}
	created_at: string
	updated_at: string
}

export type EnterpriseCannedServiceLabor = EnterpriseCannedServiceBase & {
	taxable: boolean
	hours: string
}

export type EnterpriseCannedServiceGenericPart = EnterpriseCannedServiceBase & {
	taxable: boolean
	part_type_id: number
	price_cents: number
	quantity: string
	max_cost_excess_flat_amount_cents?: number
	max_cost_excess_multiplier?: number
	max_cost_price_cents?: number
	max_quantity?: string
	max_quantity_charge_per_item_cents?: number
	min_part_line_price_cents?: number
}

export type EnterpriseCannedServiceHazmat = EnterpriseCannedServiceBase & {
	id: number
	quantity: string
	fee_cents: number
	taxable: boolean
}

export type EnterpriseCannedServiceSublet = EnterpriseCannedServiceBase & {
	price_cents: number
	taxable: boolean
}

export type EnterpriseCannedService = {
	id: number
	title: string
	fixed_price_cents: number | null
	categories: EnterpriseCategory[]
	created_by_profile: {
		full_name: string
	}
	last_updated_by_profile: {
		full_name: string
	}
	created_at: string
	updated_at: string
	gp_wizard_ok: boolean
	auto_applied: boolean
	shops: Shop[]
	tenants: CurrentTenant[]
	enterprise_labor_rate: EnterpriseLaborRate
	enterprise_canned_service_labors: EnterpriseCannedServiceLabor[]
	enterprise_canned_service_sublets: EnterpriseCannedServiceSublet[]
	enterprise_canned_service_inspections: EnterpriseCannedServiceBase[]
	enterprise_canned_service_hazmats: EnterpriseCannedServiceHazmat[]
	enterprise_canned_service_generic_parts: EnterpriseCannedServiceGenericPart[]
}

export type EnterpriseCategory = {
	tenant_id: number
	category_id: number | null
}

export type EnterpriseCannedServices =
	| EnterpriseCannedServiceLabor
	| EnterpriseCannedServiceSublet
	| EnterpriseCannedServiceHazmat
	| EnterpriseCannedServiceGenericPart
	| EnterpriseCannedServiceBase

export type EnterpriseCannedServicesWithKey = EnterpriseCannedServices & {
	key: string
}

export enum CannedService {
	HAZMATS = 'enterprise_canned_service_hazmats',
	LABORS = 'enterprise_canned_service_labors',
	INSPECTIONS = 'enterprise_canned_service_inspections',
	GENERIC_PARTS = 'enterprise_canned_service_generic_parts',
	SUBLETS = 'enterprise_canned_service_sublets'
}

export type EnterpriseCannedServiceLineItemGroups = {
	[CannedService.LABORS]: EnterpriseCannedServiceLabor[]
	[CannedService.GENERIC_PARTS]: EnterpriseCannedServiceGenericPart[]
	[CannedService.HAZMATS]: EnterpriseCannedServiceHazmat[]
	[CannedService.SUBLETS]: EnterpriseCannedServiceSublet[]
	[CannedService.INSPECTIONS]: EnterpriseCannedServiceBase[]
}

export type EnterpriseCannedServicePayload = {
	enterprise_canned_service: {
		title: string
		enterprise_canned_service_labors_attributes: EnterpriseCannedServiceLabor[]
		enterprise_canned_service_generic_parts_attributes: EnterpriseCannedServiceGenericPart[]
		enterprise_canned_service_hazmats_attributes: EnterpriseCannedServiceHazmat[]
		enterprise_canned_service_sublets_attributes: EnterpriseCannedServiceSublet[]
		enterprise_canned_service_inspections_attributes: EnterpriseCannedServiceBase[]
		category_id: number | null
		enterprise_labor_rate_id: number
		shop_ids: number[]
		fixed_price_cents: number | null
		gp_wizard_ok: boolean
		auto_applied: boolean
	}
}
