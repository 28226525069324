import React from 'react'
import { Col, Row, Typography, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { useQuery } from 'react-query'
import DropDownn from 'app/assets/icons/FA6/dropdown.svg'
import * as api from 'app/api/categories'
import styles from 'app/screens/cannedService/cannedServiceDetails/styles.module.scss'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import { CategoryOption } from 'app/api/Models/CategoryModel'

const { Text } = Typography

const Category = () => {
	const { t } = useTranslation()
	const {
		setCategoryId,
		categoryId,
		cannedServiceEdited,
		setCannedServiceEdited
	} = useCanneryServicesContext()
	const { data } = useQuery('getCategories', api.getCategories)

	const getOptions = (): CategoryOption[] => {
		if (!data) return []
		return [
			{
				label: t('canned_service.none'),
				value: ''
			},
			...data.categoriesOptions
		]
	}

	const handleChange = (value: string) => {
		setCategoryId(value)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	return (
		<Row gutter={[16, 0]} align="middle">
			<Col span={3} className={styles.rightAligned}>
				<Text>{t('canned_service.details.category')}</Text>
			</Col>
			<Col span={18}>
				<Select
					showSearch
					filterOption={(input, option) =>
						(option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
					}
					dropdownMatchSelectWidth={false}
					className={styles.customSelect}
					onChange={handleChange}
					options={getOptions()}
					value={categoryId || ''}
					suffixIcon={<ReactSVG src={DropDownn} />}
				/>
			</Col>
		</Row>
	)
}

export default Category
