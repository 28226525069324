import { Layout, Col, Row } from 'antd'
import { useState } from 'react'
import Header from 'app/screens/EnterpriseSettings/laborRates/header'
import LaborRatesTable from 'app/screens/EnterpriseSettings/laborRates/table'
import styles from './styles.module.scss'
import AddLaborRateButton from './addLaborRateButton'
import {
	getLaborRates,
	createLaborRate,
	updateLaborRate,
	deleteLaborRate
} from 'app/api/laborRate'
import { useMutation, useQuery } from 'react-query'
import { EnterpriseLaborRatePayload } from 'app/api/Models/LaborRateModel'

const { Content } = Layout

function LaborRates() {
	const [addingLaborRate, setAddingLaborRate] = useState<boolean>(false)

	const { data, refetch } = useQuery('laborRates', getLaborRates)
	const { mutateAsync: addLaborRate } = useMutation(createLaborRate, {
		onSuccess: () => {
			setAddingLaborRate(false)
		},
		onSettled: refetch
	})
	const { mutateAsync: editLaborRate } = useMutation(
		({ id, payload }: { id: number; payload: EnterpriseLaborRatePayload }) =>
			updateLaborRate(id, payload),
		{ onSettled: refetch }
	)
	const { mutate: destroyLaborRate } = useMutation(deleteLaborRate, {
		onSettled: refetch
	})

	const hasLaborRate = data?.length > 0
	const dataSource =
		data?.sort((a, b) => {
			// Put standard labor rate first, otherwise sort by id
			if (a.label === 'enterprise_standard_labor_rate') return -1
			if (b.label === 'enterprise_standard_labor_rate') return 1
			return a.id - b.id
		}) ?? []

	const toggleAddLaborRate = () => {
		setAddingLaborRate(!addingLaborRate)
	}

	return (
		<Layout className={styles.laborRates}>
			<Content className={styles.content}>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<Header hasLaborRate={hasLaborRate} />
					</Col>
					{hasLaborRate ? (
						<>
							<Col span={24}>
								<LaborRatesTable
									addingLaborRate={addingLaborRate}
									createLaborRate={addLaborRate}
									editLaborRate={editLaborRate}
									deleteLaborRate={destroyLaborRate}
									dataSource={dataSource}
								/>
							</Col>
							<AddLaborRateButton toggleAdd={toggleAddLaborRate} />
						</>
					) : (
						<></>
					)}
				</Row>
			</Content>
		</Layout>
	)
}

export default LaborRates
