import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import type { TabsProps } from 'antd'
import Categories from 'app/screens/EnterpriseSettings/categories'
import LaborRates from 'app/screens/EnterpriseSettings/laborRates'
import { useNavigate, useParams } from 'react-router-dom'

function NavTabs() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { activeTab } = useParams()

	const updateURL = (activeKey: string) => {
		navigate(`/enterprise-settings/${activeKey}`)
	}

	const items: TabsProps['items'] = [
		{
			key: 'labor-rates',
			label: t('enterprise_settings.labor_rates_tab'),
			children: <LaborRates />
		},
		{
			key: 'categories',
			label: t('enterprise_settings.categories_tab'),
			children: <Categories />
		}
	]

	const invalidActiveTab =
		items.find(item => item.key === activeTab) === undefined
	if (invalidActiveTab) navigate('/enterprise-settings/labor-rates')

	return (
		<Tabs
			items={items}
			size="large"
			onChange={updateURL}
			defaultActiveKey={activeTab}
		/>
	)
}

export default NavTabs
