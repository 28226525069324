import axios from 'app/api/axios'
import {
	EnterpriseCategory,
	EnterpriseMappedCategoryData
} from './Models/CategoryModel'

const mapCategories = (
	data: EnterpriseCategory[]
): EnterpriseMappedCategoryData => {
	return {
		categories: data,
		categoriesMap: Object.assign(
			{},
			...data.map(cat => ({ [cat.id]: cat.text }))
		),
		categoriesOptions: data.map(cat => ({
			label: cat.text,
			value: String(cat.id)
		}))
	}
}

const getCategories = async (): Promise<EnterpriseMappedCategoryData> => {
	const response = await axios('/api/internal/categories')
	return mapCategories(response.data)
}

export { getCategories }
