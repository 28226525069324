import { Routes, Route, Navigate } from 'react-router-dom'
import CannedServicePage from 'app/screens/cannedService'
import EnterpriseSettings from 'app/screens/EnterpriseSettings'
import CannedServicesPage from 'app/screens/cannedServices'

export default function AppRoutes() {
	return (
		<Routes>
			<Route
				path="/*"
				element={<Navigate to="/enterprise-cannery" replace={true} />}
			/>
			<Route path="/enterprise-cannery" element={<CannedServicesPage />} />
			<Route
				path="/enterprise-cannery/:serviceId"
				element={<CannedServicePage />}
			/>
			<Route
				path="/enterprise-settings"
				element={
					<Navigate to="/enterprise-settings/labor-rates" replace={true} />
				}
			/>
			<Route
				path="/enterprise-settings/:activeTab"
				element={<EnterpriseSettings />}
			/>
		</Routes>
	)
}
