import { useState, useMemo, useRef } from 'react'
import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons'
import {
	Table,
	Form,
	Typography,
	InputNumber,
	Button,
	Spin,
	Select
} from 'antd'
import Checkbox from 'app/components/Checkbox/index'
import Popconfirm from 'app/components/Popconfirm/index'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import { useCurrency } from 'app/hooks/useCurrency'
import { CannedService } from 'app/api/Models/CannedServiceModel'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import type { PartTypeOption } from 'app/api/Models/PartsModel'
import { debounce } from 'app/utils'
import { toCents } from 'app/screens/cannedService/helper'
import * as api from 'app/api/parts'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'

import styles from 'app/screens/cannedService/serviceTables/styles.module.scss'

const { Text } = Typography
const { Summary } = Table
const { Cell, Row } = Summary

function TableSummary() {
	const [invalidFieldKey, setInvalidFieldKey] = useState('')
	const [isSearching, setIsSearching] = useState(false)
	const [error, setError] = useState<string>('')
	const [selectedOption, setSelectedOption] = useState<any>({})
	const [options, setOptions] = useState<PartTypeOption[]>([])

	const { t } = useTranslation()
	const { formatCurrency, getCurrencySymbol } = useCurrency()
	const { subtotals, isFixedPrice, fixedPricinglaborSubtotal } =
		useCanneryServicesContext()
	const { dataSourceWithKeys, handleAdd } = useDataSource(
		CannedService.GENERIC_PARTS
	)
	const [form] = Form.useForm()

	const quantityRef = useRef(null)
	const priceCentsRef = useRef(null)
	const nameRef = useRef(null)

	const total = subtotals[CannedService.GENERIC_PARTS] ?? 0

	const addItem = async () => {
		if (invalidFieldKey) return
		if (!selectedOption) {
			setError(t('canned_service.parts.error_message'))
			return
		}
		const values = await form.validateFields()
		const numberOfItems = dataSourceWithKeys.length ?? 0
		handleAdd({
			...values,
			name: values.name.label,
			key: (numberOfItems + 1).toString(),
			price_cents: toCents(values.price_cents),
			quantity: Number(values.quantity),
			part_type_id: values.name.value,
			taxable: !values.taxable
		})
		form.resetFields()
		setSelectedOption('')
		nameRef.current!.focus()
	}

	const onSearchHandle = (searchText: string) => {
		if (searchText.length < 3) {
			setSelectedOption('')
			setError('')
			setOptions([])
			return
		}
		setIsSearching(true)
		setSelectedOption('')
		search(searchText)
	}

	const search = useMemo(
		() =>
			debounce(async (searchText: string) => {
				const res = await api.getParts(searchText)
				setOptions(res.partOptions)
				setIsSearching(false)
			}),
		[]
	)

	const onSelect = (value: string, option) => {
		setSelectedOption(option)
		setError('')
	}

	const validator = async (field: string) => {
		const quantity = await form.getFieldValue('quantity')
		const price_cents = await form.getFieldValue('price_cents')
		const newFixPricingLaborSubtotal =
			fixedPricinglaborSubtotal - price_cents * quantity
		if (newFixPricingLaborSubtotal >= 0) {
			setInvalidFieldKey('')
			return Promise.resolve()
		}
		setInvalidFieldKey(field)
		return Promise.reject(new Error(''))
	}

	return (
		<Summary>
			<Form form={form} component={false}>
				<Row className={styles.newItemRow}>
					<Cell index={-1} />
					<Cell index={0}>
						<Form.Item className={styles.titleCell} name="name">
							<Select
								ref={nameRef}
								options={options}
								onSelect={onSelect}
								filterOption={false}
								labelInValue={true}
								notFoundContent={null}
								showSearch
								onSearch={onSearchHandle}
								suffixIcon={
									isSearching ? <Spin size="small" /> : <SearchOutlined />
								}
							/>
						</Form.Item>
						{error && <Text className={styles.errorMessage}>{error}</Text>}
					</Cell>
					<Cell index={1}>
						<Popconfirm
							open={invalidFieldKey === 'quantity'}
							overlayClassName={styles.fixedPricePopup}
							showCancel={false}
							title={t('canned_service.error.fixed_price_exceed')}
							okText={t('canned_service.ok_got_it')}
							onConfirm={() => {
								setInvalidFieldKey('')
								quantityRef.current!.focus()
							}}>
							<Form.Item
								name="quantity"
								initialValue={1.0}
								validateTrigger="onChange"
								rules={[
									...(isFixedPrice
										? [
												{
													validator: async () => validator('quantity')
												}
											]
										: [])
								]}>
								<InputNumber
									ref={quantityRef}
									min={1}
									step={0.01}
									controls={false}
									addonAfter={
										invalidFieldKey === 'quantity' && <CloseCircleFilled />
									}
									className={classNames(
										styles.normalCell,
										invalidFieldKey === 'quantity' && styles.hasError
									)}
									onBlur={e => {
										if (!e.target.value) {
											form.setFieldValue('quantity', 1)
										}
									}}
									data-testid="new-row-quantity"
								/>
							</Form.Item>
						</Popconfirm>
					</Cell>
					<Cell index={2}>
						<Popconfirm
							open={invalidFieldKey === 'price_cents'}
							overlayClassName={styles.fixedPricePopup}
							showCancel={false}
							title={t('canned_service.error.fixed_price_exceed')}
							okText={t('canned_service.ok_got_it')}
							onConfirm={() => {
								setInvalidFieldKey('')
								priceCentsRef.current!.focus()
							}}>
							<Form.Item
								name="price_cents"
								initialValue={0.0}
								validateTrigger="onChange"
								rules={[
									...(isFixedPrice
										? [
												{
													validator: async () => validator('price_cents')
												}
											]
										: [])
								]}>
								<InputNumber
									ref={priceCentsRef}
									step={0.01}
									controls={false}
									prefix={getCurrencySymbol()}
									addonAfter={
										invalidFieldKey === 'price_cents' && <CloseCircleFilled />
									}
									className={classNames(
										styles.normalCell,
										invalidFieldKey === 'price_cents' && styles.hasError
									)}
									onBlur={e => {
										if (!e.target.value) {
											form.setFieldValue('price_cents', 0)
										}
									}}
									data-testid="new-row-list"
								/>
							</Form.Item>
						</Popconfirm>
					</Cell>
					<Cell index={3} />
					<Cell index={4}>
						<Form.Item
							name="taxable"
							valuePropName="checked"
							initialValue={false}>
							<Checkbox>{t('canned_service.taxable')}</Checkbox>
						</Form.Item>
					</Cell>
					<Cell index={5} className={styles.actionColumn}>
						<Button
							onClick={addItem}
							type="link"
							className={styles.customLink}
							data-testid="add-button">
							<PlusOutlined /> {t('canned_service.add')}
						</Button>
					</Cell>
				</Row>
			</Form>
			<Row className={styles.customSummaryRow}>
				<Cell index={0} />
				<Cell index={1} />
				<Cell index={2} />
				<Cell index={3}>
					<Text strong>{t('canned_service.parts.subtotal')}</Text>
				</Cell>
				<Cell index={4}>
					<Text strong>{formatCurrency(total.toString())}</Text>
				</Cell>
				<Cell index={5} />
				<Cell index={6} />
			</Row>
		</Summary>
	)
}

export default TableSummary
