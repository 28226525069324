import React, { useContext, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { setDayjsDefaults } from 'day'
import * as i18n from 'i18next'
import Pseudo from 'i18next-pseudo'

import PageLoader from 'app/components/PageLoader'
import { MetaConfig } from 'app/api/Models/MetaConfig'
import { useMetaConfigQuery } from 'app/providers/MetaConfigProvider/useMetaConfigQuery'

const MetaConfigContext = createContext<MetaConfig>(null)

const MetaConfigProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	const { ready, t } = useTranslation()

	const { data } = useMetaConfigQuery(config => {
		const tenantLocale = config?.currentTenant?.default_locale
		setDayjsDefaults({
			shopTimeZone: config?.currentShop?.time_zone_js,
			tenantLocale: tenantLocale
		})
		// Check if the tenant locale is 'zz' and enable pseudo accordingly. Change the language based on tenant locale if it is not 'zz'
		if (tenantLocale === 'zz') {
			i18n.use(new Pseudo({ enabled: true }))
		} else {
			i18n.changeLanguage(tenantLocale)
		}
		document.title = t('enterprise_manager.document_title')
		document.documentElement.setAttribute('lang', tenantLocale)
	})

	if (data && ready) {
		return (
			<MetaConfigContext.Provider value={data}>
				{children}
			</MetaConfigContext.Provider>
		)
	}

	return <PageLoader />
}

function useMetaConfig() {
	const context: MetaConfig | undefined = useContext(MetaConfigContext)

	if (context === undefined) {
		throw new Error('useMetaConfig must be used within a MetaConfigProvider')
	}

	return context
}

export { MetaConfigProvider, useMetaConfig, MetaConfigContext }
