/**
 * Custom Error class thrown by the auth flow library
 *
 * Codes include:
 *
 * CONFIGURATION
 * There was a problem with the supplied configuration
 *
 * NOT_AUTHORIZED
 * The user is not authorized the application
 *
 * ISSUER_INVALID
 * The issuer of the identity token is not valid
 *
 * ISSUER_VERIFY_FAILED
 * The verification of the issuer of the identity token failed
 *
 * AUDIENCE_UNKNOWN
 * The audience of the identity token is not valid
 *
 * STATE_MISMATCH
 * The state data for the flow did not match
 *
 * SHA256_FAILED
 * The sha256 hash for the challenge code failed
 *
 * PUBLIC_KEY_IMPORT_FAILED
 * The public key could not be imported for token verification
 *
 * TOKEN_DECODE_FAILED
 * The decode of the identity token failed
 *
 * TOKEN_VERIFY_FAILED
 * The verification of the identity token failed
 *
 * TOKEN_EXCHANGE_FAILED
 * Retrieving the tokens using the supplied code failed
 *
 * REFRESH_TOKENS_FAILED
 * Refreshing the tokens failed
 *
 * LOGOUT_FAILED
 * Logging the user out failed
 *
 */
export class AuthFlowError extends Error {
    code;
    constructor(code, message) {
        super(message);
        this.code = code;
    }
}
