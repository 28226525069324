import type { FormInstance } from 'antd/es/form'

import {
	CannedService,
	EnterpriseCannedServicesWithKey
} from 'app/api/Models/CannedServiceModel'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import { useSubtotal } from 'app/screens/cannedService/hooks/useSubtotal'
import {
	toDollars,
	toCents,
	isCurrencyField
} from 'app/screens/cannedService/helper'

export function useDataSource(
	type: CannedService | string,
	form?: FormInstance
) {
	const {
		subtotals,
		setSubtotals,
		setFixedPricingLaborSubtotal,
		cannedServices,
		setCannedServices,
		cannedServiceEdited,
		setCannedServiceEdited
	} = useCanneryServicesContext()
	const { getSubtotal, getFixedPricingLaborSubtotal } = useSubtotal()

	const cannedServicesWithKeys: EnterpriseCannedServicesWithKey[] =
		cannedServices[type].map(item => ({
			...item,
			key: item.key?.toString() || item.id?.toString()
		}))

	const dataSourceWithKeys: EnterpriseCannedServicesWithKey[] =
		cannedServicesWithKeys
			.filter(item => !item._destroy)
			.sort((current, next) => current.row_order - next.row_order)

	const setField = (
		record: EnterpriseCannedServicesWithKey,
		dataIndex: string
	) => {
		form.setFieldsValue({
			[dataIndex]: isCurrencyField(dataIndex)
				? toDollars(record[dataIndex])
				: record[dataIndex]
		})
	}

	const handleDelete = (key: React.Key) => {
		const newData = cannedServicesWithKeys
		const index = newData.findIndex(item => item.key === key)
		const item = newData[index]

		if (item.id) {
			item._destroy = true
		} else {
			newData.splice(index, 1)
		}

		setCannedServices(prev => ({ ...prev, [type]: newData }))
		const newSubtotal = toDollars(getSubtotal(type, newData))
		setSubtotals({ ...subtotals, [type]: newSubtotal })
		setFixedPricingLaborSubtotal(
			getFixedPricingLaborSubtotal(type, newSubtotal)
		)

		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	const handleAdd = (record: EnterpriseCannedServicesWithKey) => {
		const newData = [...cannedServicesWithKeys, record]
		resetRowOrder(newData)
		setCannedServices(prev => ({ ...prev, [type]: newData }))
		const newSubtotal = toDollars(getSubtotal(type, newData))
		setSubtotals({ ...subtotals, [type]: newSubtotal })
		setFixedPricingLaborSubtotal(
			getFixedPricingLaborSubtotal(type, newSubtotal)
		)

		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	const handleSave = async (
		record: EnterpriseCannedServicesWithKey,
		field: string,
		newValue?: unknown
	) => {
		if (isCurrencyField(field) && !newValue) {
			form.setFieldValue(field, 0)
		}
		const values = await form.validateFields()
		let row = { ...record }
		if (field === 'taxable') {
			row = { ...record, taxable: !values[`taxable-${record.key}`] }
		} else {
			row = {
				...record,
				[field]: isCurrencyField(field) ? toCents(values[field]) : values[field]
			}
		}
		const newData = [...cannedServicesWithKeys]
		const index = newData.findIndex(item => row.key === item.key)
		const item = newData[index]
		newData.splice(index, 1, {
			...item,
			...row
		})
		setCannedServices(prev => ({ ...prev, [type]: newData }))
		const newSubtotal = toDollars(getSubtotal(type, newData))
		setSubtotals({ ...subtotals, [type]: newSubtotal })
		setFixedPricingLaborSubtotal(
			getFixedPricingLaborSubtotal(type, newSubtotal)
		)

		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	const updateLimit = async (
		record: EnterpriseCannedServicesWithKey,
		newLimit: object
	) => {
		let row = { ...record }

		for (const field in newLimit) {
			row[field] =
				newLimit[field] && isCurrencyField(field)
					? toCents(newLimit[field])
					: newLimit[field]
		}

		const newData = [...cannedServicesWithKeys]
		const index = newData.findIndex(item => row.key === item.key)
		const item = newData[index]
		newData.splice(index, 1, {
			...item,
			...row
		})
		setCannedServices(prev => ({ ...prev, [type]: newData }))
		const newSubtotal = toDollars(getSubtotal(type, newData))
		setSubtotals({ ...subtotals, [type]: newSubtotal })
		setFixedPricingLaborSubtotal(
			getFixedPricingLaborSubtotal(type, newSubtotal)
		)

		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	const resetRowOrder = (records: EnterpriseCannedServicesWithKey[]) => {
		for (let i = 0; i < records.length; i++) {
			records[i].row_order = i
		}
	}

	const onOrderChanged = (records: EnterpriseCannedServicesWithKey[]) => {
		const recordsToDestroy = cannedServicesWithKeys.filter(
			cs => cs._destroy === true
		)

		setCannedServices(prev => ({
			...prev,
			[type]: records.concat(recordsToDestroy)
		}))
		resetRowOrder(records)
		if (!cannedServiceEdited) setCannedServiceEdited(true)
	}

	return {
		setField,
		handleSave,
		handleDelete,
		onOrderChanged,
		handleAdd,
		updateLimit,
		dataSourceWithKeys
	}
}
