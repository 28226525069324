import { Layout, Tooltip, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import CannerySvg from 'app/assets/icons/Custom/cannery.svg'
import SolidGearSvg from 'app/assets/icons/FA6/solid_gear.svg'
import SolidArrowRightFromBracketSvg from 'app/assets/icons/FA6/solid_arrow-right-from-bracket.svg'
import { ReactSVG } from 'react-svg'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.scss'
import classnames from 'classnames'
import { useAuth } from 'app/providers/AuthProvider'

const { Content, Sider } = Layout

function Navbar({ children }) {
	const { t } = useTranslation()
	const { logoutUser } = useAuth()

	return (
		<Layout>
			<Sider className={styles.slider} width="64">
				<div className={styles.container}>
					<Tooltip title={t('navigation.enterprise_cannery')} placement="right">
						<NavLink to="/enterprise-cannery" className={styles.iconContainer}>
							<ReactSVG
								src={CannerySvg}
								className={styles.icon}
								data-testid="cannery-svg"
							/>
						</NavLink>
					</Tooltip>
					<Tooltip
						title={t('navigation.enterprise_settings')}
						placement="right">
						<NavLink to="/enterprise-settings" className={styles.iconContainer}>
							<ReactSVG
								src={SolidGearSvg}
								className={styles.icon}
								data-testid="gear-svg"
							/>
						</NavLink>
					</Tooltip>

					<Tooltip title={t('navigation.sign_out')} placement="right">
						<Button
							type="link"
							className={classnames(styles.iconContainer, styles.marginTop)}
							onClick={logoutUser}>
							<ReactSVG
								src={SolidArrowRightFromBracketSvg}
								className={styles.icon}
								data-testid="sign-out-svg"
							/>
						</Button>
					</Tooltip>
				</div>
			</Sider>
			<Content>{children}</Content>
		</Layout>
	)
}

export default Navbar
