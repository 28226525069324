import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useCurrency } from 'app/hooks/useCurrency'

import styles from './styles.module.scss'

const { Text } = Typography

export type PartsProps = {
	partsTotalQty: string
	partsTotalPrice: number
}

function Parts({ partsTotalQty, partsTotalPrice }: PartsProps) {
	const { t } = useTranslation()
	const { formatCurrency } = useCurrency()

	const noPartsText = t('canned_services.table.no_parts_text')

	const partsText = t('canned_services.table.parts_text', {
		partsTotalQty,
		partsTotalPrice: formatCurrency(partsTotalPrice.toString())
	})

	return (
		<Text className={styles.partTag}>
			{parseInt(partsTotalQty) === 0 ? noPartsText : partsText}
		</Text>
	)
}

export default Parts
