import { notification } from 'antd'

import i18n from 'i18n'

export function getDefaultErrorMessage(error) {
	const ERRORS = {
		TRY_AGAIN: i18n.t('enterprise_manager.errors.try_again'),
		SESSION_EXPIRED: i18n.t('enterprise_manager.errors.session_expired'),
		ERROR_PROCESSING: i18n.t('enterprise_manager.errors.error_processing'),
		EXTERNAL_SERVICE: i18n.t('enterprise_manager.errors.external_service')
	}
	if (!error?.response) {
		return ERRORS.TRY_AGAIN
	}

	switch (error.response.status) {
		case 401:
			return ERRORS.SESSION_EXPIRED
		case 422:
			return ERRORS.ERROR_PROCESSING
		case 504:
			return ERRORS.EXTERNAL_SERVICE
		default:
			return ERRORS.TRY_AGAIN
	}
}

export function defaultErrorHandler(e) {
	const errorMessage = getDefaultErrorMessage(e)
	notification.error({
		message: i18n.t('enterprise_manager.errors.error'),
		description: errorMessage
	})
}
