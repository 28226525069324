import { Logger } from '@shop-ware/logger';
import * as defaultStorage from './storage.js';
import * as defaultNavigation from './navigation.js';
import { createState, createCodeVerifier, createCodeChallenge, createCodeChallengeMethod } from './code.js';
import { createUrl } from './url.js';
import { token as authToken, verifyIssuer as authVerifyIssuer, refreshTokens as authRefreshTokens, logout as authLogout } from './authentication.js';
import { AuthFlowError } from './error.js';
import { parsePermissions, hasPermission } from './permissions.js';
const logger = new Logger('auth-flow');
const DEFAULT_CONFIG = {
    baseUrls: {
        authWeb: '',
        services: ''
    },
    options: {
        verifyIdentityToken: true
    },
    storage: defaultStorage,
    navigation: defaultNavigation
};
export function platform(platformConfig) {
    const { random, jwt } = platformConfig;
    /**
     * Checks to see if the user has a specific permission
     */
    const isAuthorized = (identityContext, type, name, level) => hasPermission(identityContext.permissions)(type, name, level);
    const verifyConfiguration = ({ baseUrls }) => {
        logger.info('Using configuration', { baseUrls });
        if (!baseUrls?.authWeb) {
            throw new AuthFlowError('CONFIGURATION', 'Missing auth web base url');
        }
        if (!baseUrls?.services) {
            throw new AuthFlowError('CONFIGURATION', 'Missing services base url');
        }
    };
    const createIdentityContext = async (baseUrls, options, applicationId, identityToken) => {
        const issuerParams = jwt.getIssuer(identityToken);
        let payload;
        if (options.verifyIdentityToken) {
            const { valid, publicKey, clients } = await authVerifyIssuer(baseUrls.services, { applicationId, ...issuerParams });
            if (!valid) {
                throw new AuthFlowError('ISSUER_INVALID', 'Issuer of token is not valid, it may have been tampered with');
            }
            const audience = clients.find(c => c.applicationId === applicationId)?.clientId;
            if (!audience) {
                throw new AuthFlowError('AUDIENCE_UNKNOWN', 'Could not determine audience from issuer');
            }
            payload = await jwt.verifyToken(identityToken, publicKey, audience);
        }
        else {
            payload = jwt.decodeToken(identityToken);
        }
        const permissions = parsePermissions(payload['shop-ware:permissions']);
        return {
            applicationId,
            tenantId: payload['shop-ware:tenant'],
            userId: payload['cognito:username'],
            subdomain: payload['shop-ware:subdomain'],
            email: payload.email,
            firstName: payload.given_name,
            permissions
        };
    };
    /**
     * Performs a redirect to the auth web front so a user can authenticate
     *
     * This should be called by the application on initial load if the user needs to be authenticated.
     * This is generally determined based on whether or not there is a current set of valid tokens available
     * for the browser session.
     *
     */
    const redirectToLogin = async (applicationId, redirectUrl, config) => {
        const { baseUrls, storage, navigation } = { ...DEFAULT_CONFIG, ...config };
        verifyConfiguration({ baseUrls });
        const responseType = 'code';
        const state = createState(random);
        const codeVerifier = createCodeVerifier(random);
        const codeChallengeMethod = createCodeChallengeMethod();
        const codeChallenge = await createCodeChallenge(codeVerifier);
        await Promise.all([
            storage.set('applicationId', applicationId),
            storage.set('codeVerifier', codeVerifier),
            storage.set('state', state)
        ]);
        const url = createUrl(baseUrls.authWeb, {
            applicationId,
            redirectUrl,
            responseType,
            state,
            codeChallenge,
            codeChallengeMethod
        });
        navigation.redirect(url);
    };
    /**
     * Handle the redirect from the auth web front end and returns the user identity, access token, and refresh token
     *
     * This should be called in the handler code for the redirectUrl that was passed in the previous step. It will exchange the auth code provided by auth web for a set of
     * tokens and then verify the identity token and provide the caller with an identity context and a set of tokens.
     */
    const processLoginResult = async (config) => {
        const { baseUrls, options, storage, navigation } = {
            ...DEFAULT_CONFIG,
            ...config,
            options: { ...DEFAULT_CONFIG.options, ...config.options }
        };
        verifyConfiguration({ baseUrls });
        const params = navigation.getParams();
        const state = params.get('state');
        const code = params.get('code');
        const [applicationId, codeVerifier, originalState] = await Promise.all([
            storage.get('applicationId'),
            storage.get('codeVerifier'),
            storage.get('state')
        ]);
        if (state !== originalState) {
            throw new AuthFlowError('STATE_MISMATCH', 'State does not match the original state provided');
        }
        await Promise.all([
            storage.clear('applicationId'),
            storage.clear('codeVerifier'),
            storage.clear('state')
        ]);
        const { identityToken, accessToken, refreshToken, expirationInSeconds } = await authToken(baseUrls.services, {
            applicationId,
            code,
            codeVerifier
        });
        const identityContext = await createIdentityContext(baseUrls, options, applicationId, identityToken);
        if (!isAuthorized(identityContext, 'application', applicationId, 'access')) {
            throw new AuthFlowError('NOT_AUTHORIZED', 'User is not authorized to application');
        }
        return {
            identityContext,
            identityToken,
            identityTokenExpiration: jwt.getExpiration(identityToken),
            accessToken,
            accessTokenExpiration: jwt.getExpiration(accessToken),
            refreshToken,
            expirationInSeconds
        };
    };
    /**
     * Gets new tokens using a valid refresh token.  An entire identity context is returned and
     * should replace the current one.
     */
    const refreshTokens = async (currentIdentityContext, currentRefreshToken, config) => {
        const { baseUrls, options } = {
            ...DEFAULT_CONFIG,
            ...config,
            options: { ...DEFAULT_CONFIG.options, ...config.options }
        };
        verifyConfiguration({ baseUrls });
        const { identityToken, accessToken, refreshToken, expirationInSeconds } = await authRefreshTokens(baseUrls.services, currentIdentityContext, currentRefreshToken);
        const identityContext = await createIdentityContext(baseUrls, options, currentIdentityContext.applicationId, identityToken);
        if (!isAuthorized(identityContext, 'application', currentIdentityContext.applicationId, 'access')) {
            throw new AuthFlowError('NOT_AUTHORIZED', 'User is not authorized to application');
        }
        return {
            identityContext,
            identityToken,
            identityTokenExpiration: jwt.getExpiration(identityToken),
            accessToken,
            accessTokenExpiration: jwt.getExpiration(accessToken),
            refreshToken,
            expirationInSeconds
        };
    };
    /**
     * Logs the user out by invalidating the refresh token.  You must still dispose of
     * your current tokens and identity context after logout to ensure they can't be
     * used as they will still be valid until expiration
     */
    const logout = async (currentIdentityContext, currentRefreshToken, config) => {
        const { baseUrls } = { ...DEFAULT_CONFIG, ...config };
        verifyConfiguration({ baseUrls });
        await authLogout(baseUrls.services, currentIdentityContext, currentRefreshToken);
    };
    return {
        redirectToLogin,
        processLoginResult,
        refreshTokens,
        logout,
        isAuthorized
    };
}
