import { decodeJwt, decodeProtectedHeader, importSPKI, jwtVerify } from 'jose';
import { AuthFlowError } from '../error.js';
import { Logger } from '@shop-ware/logger';
const logger = new Logger('browser-jwt');
const ALGORITHM = 'RS256';
export function getIssuer(token) {
    try {
        const { iss } = decodeJwt(token);
        const { kid } = decodeProtectedHeader(token);
        return { issuer: iss, keyId: kid };
    }
    catch (err) {
        logger.error('Could not decode jwt token', err);
        throw new AuthFlowError('TOKEN_DECODE_FAILED', 'Token decode failed');
    }
}
export function decodeToken(token) {
    try {
        const payload = decodeJwt(token);
        return payload;
    }
    catch (err) {
        logger.error('Could not decode jwt token', err);
        throw new AuthFlowError('TOKEN_DECODE_FAILED', 'Token decode failed');
    }
}
export async function verifyToken(token, publicKeyStr, audience) {
    let publicKey;
    try {
        publicKey = await importSPKI(publicKeyStr, ALGORITHM);
    }
    catch (err) {
        logger.error('Importing public key failed', err);
        throw new AuthFlowError('PUBLIC_KEY_IMPORT_FAILED', 'Public key import failed');
    }
    try {
        const { payload } = await jwtVerify(token, publicKey, {
            algorithms: [ALGORITHM],
            audience,
            clockTolerance: 600
        });
        return payload;
    }
    catch (err) {
        logger.error('Token verification failed', err);
        throw new AuthFlowError('TOKEN_VERIFY_FAILED', 'Token verification failed');
    }
}
export function getExpiration(token) {
    try {
        const { exp } = decodeJwt(token);
        return exp * 1000;
    }
    catch (err) {
        logger.error('Could not decode jwt token', err);
        throw new AuthFlowError('TOKEN_DECODE_FAILED', 'Token decode failed');
    }
}
