import { SearchOutlined } from '@ant-design/icons'
import { Tag, Typography } from 'antd'

import { Category } from 'app/api/Models/CannedServicesModel'

type SearchInputPrefixProps = {
	selectedCategories: number[]
	categoriesData: {
		categories: Category[]
		categoriesMap: Record<number, string>
	}
	onTagClose: (nunber: number) => void
}

function SearchInputPrefix({
	selectedCategories,
	onTagClose,
	categoriesData
}: SearchInputPrefixProps) {
	if (!selectedCategories.length) return <SearchOutlined />

	return (
		<>
			{selectedCategories.map((catID: number) => (
				<Tag
					key={catID}
					closable={true}
					onClose={() => onTagClose(catID)}
					data-testid={`category-tag-${catID}`}>
					<Typography.Text ellipsis>
						{categoriesData.categoriesMap[catID]}
					</Typography.Text>
				</Tag>
			))}
		</>
	)
}

export default SearchInputPrefix
