import {
	Empty,
	Typography,
	Pagination,
	Table,
	Row,
	TableColumnsType,
	PaginationProps
} from 'antd'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'

import { CannedServiceData } from 'app/api/Models/CannedServicesModel'
import SolidPen from 'app/assets/icons/FA6/solid-pen.svg'
import Button from 'app/components/Button'
import { usePagination } from 'app/hooks/usePagination'
import TotalPrice from './TotalPrice'
import Labor from './Labor'
import Parts from './Parts'
import styles from './styles.module.scss'

const { Text } = Typography

const DEFAULT_PAGE_SIZE = 10

type CannedServicesTableProps = {
	data: CannedServiceData[]
	onRowSelect: (
		selectedRowKeys: string[],
		selectedRows: CannedServiceData[]
	) => void
	loading: boolean
}

function CannedServicesTable({
	data,
	onRowSelect,
	loading
}: CannedServicesTableProps) {
	const { t } = useTranslation()
	const { next, prev, currentData, currentPage, totalPage, totalItem } =
		usePagination(data, DEFAULT_PAGE_SIZE)

	const COLUMNS: TableColumnsType<CannedServiceData> = [
		{
			className: styles.titleColumn,
			title: t('canned_services.table.title'),
			dataIndex: 'title',
			render: title => <Text>{title}</Text>
		},
		{
			title: t('canned_services.table.labor'),
			dataIndex: 'labor_info',
			render: (labor_info, record) => (
				<Labor
					hoursTotal={labor_info.labor_total_hours}
					effectiveRate={labor_info.labor_rate}
					fixedPrice={record.fixed_price_cents}
				/>
			)
		},
		{
			title: t('canned_services.table.parts'),
			dataIndex: 'parts_info',
			render: parts_info => (
				<Parts
					partsTotalQty={parts_info.parts_total_quantity}
					partsTotalPrice={parts_info.parts_total_price}
				/>
			)
		},
		{
			title: (
				<Text className={styles.totalPriceHeader}>
					{t('canned_services.table.total_price')}
				</Text>
			),
			dataIndex: 'total_price',
			render: (total_price, record) => (
				<TotalPrice
					totalPrice={total_price}
					fixedPrice={record.fixed_price_cents}
				/>
			)
		},
		{
			title: '',
			render: (_, record) => (
				<Link
					to={`/enterprise-cannery/${record.id}`}
					data-testid={`edit-icon-${record.id}`}
					className={styles.editLink}>
					<ReactSVG src={SolidPen} className={styles.editIcon} />
				</Link>
			)
		}
	]

	const itemRender: PaginationProps['itemRender'] = (_, type) => {
		if (type === 'prev') {
			return (
				<Button onClick={prev}>
					{t('canned_services.pagination.previous')}
				</Button>
			)
		}
		if (type === 'next') {
			return (
				<Button onClick={next}>{t('canned_services.pagination.next')}</Button>
			)
		}

		if (type === 'page') {
			return ''
		}
	}

	const rowSelection = {
		placement: 'start',
		onChange: onRowSelect
	}

	return (
		<div className={styles.customTable}>
			<Table
				rowKey="id"
				dataSource={currentData()}
				loading={loading}
				pagination={false}
				columns={COLUMNS}
				locale={{
					emptyText: (
						<Empty description={t('canned_services.table.empty_text')} />
					)
				}}
				rowSelection={rowSelection}
				scroll={{ x: 'max-content' }}
			/>
			<Pagination
				simple
				showSizeChanger={false}
				key="pagination"
				current={currentPage}
				pageSize={DEFAULT_PAGE_SIZE}
				total={totalItem}
				itemRender={itemRender}
			/>
			<Row justify="center">
				<Text className={styles.customPageCount} type="secondary">
					{t('canned_services.pagination.page_number', {
						currentPage: currentPage,
						totalPage: totalPage
					})}
				</Text>
			</Row>
		</div>
	)
}

export default CannedServicesTable
