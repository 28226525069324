import { Logger } from '@shop-ware/logger';
import { sha256 } from './crypto.js';
import { base64urlEncode } from './base64.js';
import { AuthFlowError } from './error.js';
const logger = new Logger('code');
export function createCodeVerifier(random) {
    return random.getValue(64);
}
export async function createCodeChallenge(codeVerifier) {
    try {
        const hash = await sha256(codeVerifier);
        return base64urlEncode(hash);
    }
    catch (err) {
        logger.error('Failed to create sha256 hash', err);
        throw new AuthFlowError('SHA256_FAILED', 'Failed to create sha256 hash');
    }
}
export function createCodeChallengeMethod() {
    return 'S256';
}
export function createState(random) {
    return random.getValue(32);
}
