import { useState, useRef } from 'react'
import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Table, Form, Input, Typography, InputNumber, Button } from 'antd'
import Popconfirm from 'app/components/Popconfirm/index'
import Checkbox from 'app/components/Checkbox/index'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useCurrency } from 'app/hooks/useCurrency'
import { CannedService } from 'app/api/Models/CannedServiceModel'
import { useCanneryServicesContext } from 'app/providers/CannedServicesProvider'
import { toCents } from 'app/screens/cannedService/helper'
import { useDataSource } from 'app/screens/cannedService/hooks/useDataSource'

import styles from 'app/screens/cannedService/serviceTables/styles.module.scss'

const { Text } = Typography
const { Summary } = Table
const { Cell, Row } = Summary

function TableSummary() {
	const [invalidFieldKey, setInvalidFieldKey] = useState('')
	const { t } = useTranslation()
	const { formatCurrency, getCurrencySymbol } = useCurrency()
	const { subtotals, isFixedPrice, fixedPricinglaborSubtotal } =
		useCanneryServicesContext()
	const { dataSourceWithKeys, handleAdd } = useDataSource(CannedService.SUBLETS)
	const [form] = Form.useForm()

	const priceCentsRef = useRef(null)
	const nameRef = useRef(null)

	const total = subtotals[CannedService.SUBLETS] ?? 0

	const addItem = async () => {
		if (invalidFieldKey) return
		const values = await form.validateFields()
		const numberOfItems = dataSourceWithKeys.length ?? 0
		handleAdd({
			...values,
			key: (numberOfItems + 1).toString(),
			row_order: numberOfItems + 1,
			price_cents: toCents(values.price_cents),
			taxable: !values.taxable
		})
		form.resetFields()
		nameRef.current!.focus()
	}

	const validator = async (field: string) => {
		const price_cents = await form.getFieldValue('price_cents')
		const newFixPricingLaborSubtotal = fixedPricinglaborSubtotal - price_cents
		if (newFixPricingLaborSubtotal >= 0) {
			setInvalidFieldKey('')
			return Promise.resolve()
		}
		setInvalidFieldKey(field)
		return Promise.reject(new Error(''))
	}

	return (
		<Summary>
			<Form form={form} component={false}>
				<Row className={styles.newItemRow}>
					<Cell index={-1} />
					<Cell index={0}>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: t('canned_service.error.min_one_character')
								}
							]}>
							<Input
								ref={nameRef}
								data-testid="new-row-name"
								className={styles.titleCell}
								placeholder={t('canned_service.sublets.empty_title')}
							/>
						</Form.Item>
					</Cell>
					<Cell index={1} />
					<Cell index={2} />
					<Cell index={3}>
						<Popconfirm
							open={invalidFieldKey === 'price_cents'}
							overlayClassName={styles.fixedPricePopup}
							showCancel={false}
							title={t('canned_service.error.fixed_price_exceed')}
							okText={t('canned_service.ok_got_it')}
							onConfirm={() => {
								setInvalidFieldKey('')
								priceCentsRef.current!.focus()
							}}>
							<Form.Item
								name="price_cents"
								initialValue={0.0}
								validateTrigger="onChange"
								rules={[
									...(isFixedPrice
										? [
												{
													validator: async () => validator('price_cents')
												}
											]
										: [])
								]}>
								<InputNumber
									ref={priceCentsRef}
									step={0.01}
									controls={false}
									prefix={getCurrencySymbol()}
									addonAfter={
										invalidFieldKey === 'price_cents' && <CloseCircleFilled />
									}
									className={classNames(
										styles.normalCell,
										invalidFieldKey === 'price_cents' && styles.hasError
									)}
									onBlur={e => {
										if (!e.target.value) {
											form.setFieldValue('price_cents', 0)
										}
									}}
									data-testid="new-row-price"
								/>
							</Form.Item>
						</Popconfirm>
					</Cell>
					<Cell index={4}>
						<Form.Item
							name="taxable"
							valuePropName="checked"
							initialValue={false}>
							<Checkbox>{t('canned_service.taxable')}</Checkbox>
						</Form.Item>
					</Cell>
					<Cell index={5} className={styles.actionColumn}>
						<Button
							onClick={addItem}
							type="link"
							className={styles.customLink}
							data-testid="add-button">
							<PlusOutlined /> {t('canned_service.add')}
						</Button>
					</Cell>
				</Row>
			</Form>
			<Row className={styles.customSummaryRow}>
				<Cell index={0} />
				<Cell index={1} />
				<Cell index={2} />
				<Cell index={3}>
					<Text strong>{t('canned_service.sublets.subtotal')}</Text>
				</Cell>
				<Cell index={4}>
					<Text strong>{formatCurrency(total.toString())}</Text>
				</Cell>
				<Cell index={5} />
				<Cell index={6} />
			</Row>
		</Summary>
	)
}

export default TableSummary
