export function parsePermissions(permissionClaim) {
    const permissions = (permissionClaim || '')
        .split(' ')
        .map(p => p.trim())
        .filter(p => !!p)
        .map(p => {
        const [type, name, level] = p.trim().split(':');
        return [type?.trim(), name?.trim(), level?.trim()];
    })
        .filter(p => !!p[0] && !!p[1] && !!p[2])
        .reduce((permissions, [type, name, level]) => {
        permissions[type] = permissions[type] || {};
        permissions[type][name] = permissions[type][name] || [];
        permissions[type][name].push(level);
        return permissions;
    }, {});
    return permissions;
}
export function hasPermission(permissions) {
    return (type, name, level) => !!permissions[type]?.[name]?.includes(level);
}
