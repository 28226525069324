import { Button as AntdButton } from 'antd'
import classNames from 'classnames/bind'

import styles from './styles.module.scss'

import type { ButtonProps as AntdButtonProps } from 'antd'

const cx = classNames.bind(styles)

type AntdButtonType = AntdButtonProps['type']
type CustomButtonProps = {
	multiline?: boolean
	selected?: boolean
	type?: 'success' | AntdButtonType
}
export type ButtonProps = Omit<AntdButtonProps, 'type'> & CustomButtonProps

const Button = ({ className, multiline, ...props }: ButtonProps) => (
	<AntdButton
		className={cx('btn', className, {
			success: props.type === 'success',
			multiline,
			selected: !!props.selected,
			default: props.type === 'default' || !props.type
		})}
		{...props}
		type={props.type as AntdButtonType} // NOTE: This typing is INCORRECT since "success" is not a valid type. Will need to be updated.
	/>
)

export default Button
