import HazmatsFeeTable from './HazmatsFeeTable'
import LaborsTable from './LaborsTable'
import InspectionsTable from './InspectionsTable'
import SubletTable from './SubletTable'
import PartsTable from './PartsTable'

const ServiceTables = () => {
	return (
		<>
			<LaborsTable />
			<PartsTable />
			<HazmatsFeeTable />
			<SubletTable />
			<InspectionsTable />
		</>
	)
}

export default ServiceTables
