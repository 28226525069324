import type { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core'
import { ColumnType } from 'antd/lib/table'
import { TableProps } from 'antd/lib/table'
import { DndContext } from '@dnd-kit/core'
import {
	arrayMove,
	SortableContext,
	verticalListSortingStrategy
} from '@dnd-kit/sortable'

import { Table } from 'antd'
import TableRow from './TableRow'

export interface TableRecord {
	id: unknown
	key: unknown
	[dataKey: string]: unknown
}
interface ShopWareTableColumnType extends ColumnType<TableRecord> {
	render?: (
		value: unknown,
		record: TableRecord,
		index: number
	) => React.ReactNode
}

interface EMTableProps extends TableProps<TableRecord> {
	dataSource: TableRecord[]
	draggable?: boolean
	columns?: ShopWareTableColumnType[]
	onOrderChanged?: (dataSource: TableRecord[]) => void
}

const EMTable = ({
	onOrderChanged,
	dataSource,
	draggable,
	...rest
}: EMTableProps) => {
	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id !== over?.id) {
			const activeIndex = dataSource.findIndex(i => i.key === active.id)
			const overIndex = dataSource.findIndex(i => i.key === over?.id)
			const sortedData = arrayMove(dataSource, activeIndex, overIndex)
			onOrderChanged && onOrderChanged(sortedData)
		}
	}
	return (
		<DndContext onDragEnd={onDragEnd}>
			<SortableContext
				items={dataSource.map(i => i.key) as UniqueIdentifier[]}
				strategy={verticalListSortingStrategy}>
				<Table
					components={
						draggable && {
							body: {
								row: TableRow
							}
						}
					}
					rowKey="key"
					dataSource={dataSource}
					pagination={false}
					{...rest}
				/>
			</SortableContext>
		</DndContext>
	)
}

export default EMTable
