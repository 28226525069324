import { IdentityContext } from '@shop-ware/auth-flow/browser'
import { AUTH_APP_URL, SERVICE_BASE_URL, SST_STAGE } from 'app/utils/constants'
import { isManualDnsStage } from '@shop-ware/utils/stage'

const ACCESS_TOKEN_KEY = 'accessToken'
const REFRESH_TOKEN_KEY = 'refreshToken'
const EXPIRE_AT_KEY = 'expireAt'
const IDENTITY_CONTEXT = 'identityContext'
const LOGIN_CNAME = 'login'

export const getAuthAccessToken = () => {
	return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export const getAuthRefreshToken = () => {
	return localStorage.getItem(REFRESH_TOKEN_KEY)
}

export const getAuthExpireAtValue = () => {
	const value = localStorage.getItem(EXPIRE_AT_KEY)
	return parseInt(value)
}

export const getIdentityContext = () => {
	const identityContext = localStorage.getItem(IDENTITY_CONTEXT)
	return JSON.parse(identityContext)
}

export const setAuthAccessToken = (authAccessToken: string) => {
	return localStorage.setItem(ACCESS_TOKEN_KEY, authAccessToken)
}

export const setAuthRefreshToken = (authRefreshToken: string) => {
	return localStorage.setItem(REFRESH_TOKEN_KEY, authRefreshToken)
}

export const setAuthExpireAtValue = (expireAtNum: number) => {
	return localStorage.setItem(EXPIRE_AT_KEY, expireAtNum.toString())
}

export const setIdentityContext = (identityContext: IdentityContext) => {
	return localStorage.setItem(IDENTITY_CONTEXT, JSON.stringify(identityContext))
}

export const clearAuthInfo = () => {
	localStorage.removeItem(ACCESS_TOKEN_KEY)
	localStorage.removeItem(REFRESH_TOKEN_KEY)
	localStorage.removeItem(EXPIRE_AT_KEY)
	localStorage.removeItem(IDENTITY_CONTEXT)
}

type updateAuthInfoOptions = {
	accessToken: string
	refreshToken: string
	identityContext: IdentityContext
	accessTokenExpiration: number
}
export const updateAuthInfo = ({
	accessToken,
	refreshToken,
	identityContext,
	accessTokenExpiration
}: updateAuthInfoOptions) => {
	setAuthAccessToken(accessToken.toString())
	setAuthRefreshToken(refreshToken.toString())
	setIdentityContext(identityContext)
	setAuthExpireAtValue(accessTokenExpiration)
}

export const getHostnameWithoutSubdomains = (subdomains = 1) => {
	const hostname = window.location.hostname
	const hostnameParts = hostname.split('.')
	return hostnameParts.slice(subdomains).join('.')
}

export const hostnameHasStage = () => {
	return window.location.hostname.startsWith(`${SST_STAGE}.`)
}

const getManualDnsLoginUrl = () => {
	return `${
		window.location.protocol
	}//${LOGIN_CNAME}.${getHostnameWithoutSubdomains()}`
}

const getManagedDnsLoginUrl = () => {
	const isLocalhost =
		window.location.hostname.includes('localhost') ||
		window.location.hostname.includes('127.0.0.1')

	if (isLocalhost) {
		return AUTH_APP_URL
	}

	if (hostnameHasStage()) {
		return `${
			window.location.protocol
		}//${SST_STAGE}.${LOGIN_CNAME}.${getHostnameWithoutSubdomains(2)}`
	}

	return getManualDnsLoginUrl()
}

const getLoginUrl = () => {
	if (
		isManualDnsStage(SST_STAGE) ||
		(SST_STAGE === 'dev' &&
			window.location.hostname.endsWith('.shop-ware-wl.io'))
	) {
		return getManualDnsLoginUrl()
	}

	return getManagedDnsLoginUrl()
}

export const getAuthConfig = () => {
	return {
		baseUrls: {
			authWeb: getLoginUrl(),
			services: SERVICE_BASE_URL
		}
	}
}
