import { Typography, Layout } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import NavTabs from './NavTabs'

const { Title } = Typography

const { Content } = Layout

function EnterpriseSettings() {
	const { t } = useTranslation()

	return (
		<Content className={styles.enterpriseSettings}>
			<Title className={styles.enterpriseTitle} level={3}>
				{t('enterprise_settings.title')}
			</Title>
			<NavTabs />
		</Content>
	)
}

export default EnterpriseSettings
