import { Col, Row, Spin } from 'antd'

import styles from './styles.module.scss'

export default function PageLoader() {
	return (
		<Row
			className={styles.pageLoader}
			justify="center"
			align="middle"
			data-testid="config-loader">
			<Col className={styles.col} span={1}>
				<Spin size="large" />
			</Col>
		</Row>
	)
}
