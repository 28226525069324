import { Button, Col, Row, Typography } from 'antd'

import { useAuth } from 'app/providers/AuthProvider'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

const { Text } = Typography
type ErrorPageProps = {
	code: number
	message: string
}

export default function ErrorPage({ code, message }: ErrorPageProps) {
	const { t } = useTranslation()
	const { logoutUser } = useAuth()

	return (
		<Row
			className={styles.errorPage}
			justify="center"
			align="middle"
			data-testid="error-page">
			<Col className={styles.col} span={24}>
				<Text className={styles.code}>{code}</Text>
				<Text className={styles.message}>{message}</Text>
				<Row justify="center" align="middle" gutter={8}>
					<Col>
						<Button type="primary" onClick={logoutUser}>
							{t('navigation.sign_out')}
						</Button>
					</Col>
					<Col>
						<Button type="default" onClick={() => window.location.reload()}>
							{t('navigation.reload')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
