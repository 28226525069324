import { Layout, Col, Row } from 'antd'
import { useState } from 'react'
import Header from 'app/screens/EnterpriseSettings/categories/header'
import Table from 'app/screens/EnterpriseSettings/categories/table'
import styles from './styles.module.scss'
import AddCategoryButton from './addCategoryButton'

const { Content } = Layout

function Categories() {
	const [addingCategory, setAddingCategory] = useState(false)

	const toggleAddCategory = () => {
		setAddingCategory(!addingCategory)
	}

	return (
		<Layout className={styles.categories}>
			<Content className={styles.content}>
				<Row gutter={[0, 24]}>
					<Col span={24}>
						<Header />
					</Col>
					<Col span={24}>
						<Table
							addingCategory={addingCategory}
							toggleAddCategory={toggleAddCategory}
						/>
					</Col>
					<AddCategoryButton toggleAdd={toggleAddCategory} />
				</Row>
			</Content>
		</Layout>
	)
}

export default Categories
