import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMetaConfig } from 'app/providers/MetaConfigProvider'

export function useCurrency() {
	const { currentTenant } = useMetaConfig()
	const { i18n } = useTranslation()

	const currencyCode = currentTenant.currency_code || 'USD'
	const currencyLocale = i18n.language || 'en-US'

	const formatter = useMemo(() => {
		return new Intl.NumberFormat(currencyLocale, {
			style: 'currency',
			currency: currencyCode
		})
	}, [currencyCode, currencyLocale])

	const returnFunc = useCallback(
		(value: string) => formatter.format(parseFloat(value)),
		[formatter]
	)

	const getCurrentCurrencySymbol = useCallback(() => {
		switch (currencyCode) {
			case 'brl':
				return 'R$'
			default:
				return '$'
		}
	}, [currencyCode])

	return {
		formatCurrency: returnFunc,
		getCurrencySymbol: getCurrentCurrencySymbol
	}
}
