import React, {
	useContext,
	useState,
	createContext,
	useEffect,
	useCallback
} from 'react'
import type { Dispatch, SetStateAction } from 'react'

import {
	useCannedServiceQuery,
	useCannedServiceMutation
} from './hooks/useCannedServicesQuery'
import PageLoader from 'app/components/PageLoader'
import {
	EnterpriseCannedService,
	CannedService,
	EnterpriseCannedServiceHazmat,
	EnterpriseCannedServiceLabor,
	EnterpriseCannedServiceSublet,
	EnterpriseCannedServiceGenericPart,
	EnterpriseCannedServiceLineItemGroups
} from 'app/api/Models/CannedServiceModel'
import { toDollars } from 'app/screens/cannedService/helper'

export type CanneryServicesContextType = {
	cannedServices: EnterpriseCannedServiceLineItemGroups
	setCannedServices: Dispatch<
		SetStateAction<EnterpriseCannedServiceLineItemGroups>
	>
	fixedPricinglaborSubtotal: number
	setFixedPricingLaborSubtotal: Dispatch<SetStateAction<number>>
	laborRateAmount: number
	setLaborRateAmount: Dispatch<SetStateAction<number | string>>
	laborRateId: number
	setLaborRateId: Dispatch<SetStateAction<number>>
	isFixedPrice: boolean
	setIsFixedPrice: Dispatch<SetStateAction<boolean>>
	fixedPrice: number | null
	subtotals: {}
	setSubtotals: Dispatch<SetStateAction<Record<string, number>>>
	setFixedPrice: Dispatch<SetStateAction<number | string>>
	data: EnterpriseCannedService
	refetch: () => void
	saveService: (data: unknown) => void
	title: string
	setTitle: Dispatch<SetStateAction<string>>
	categoryId: string | null
	setCategoryId: Dispatch<SetStateAction<string | null>>
	selectedShopIds: string[]
	setSelectedShopIds: Dispatch<SetStateAction<string[]>>
	partsOptimizerEnabled: boolean
	setPartsOptimizerEnabled: Dispatch<SetStateAction<boolean>>
	autoApplied: boolean
	setAutoApplied: Dispatch<SetStateAction<boolean>>
	cannedServiceEdited: boolean
	setCannedServiceEdited: Dispatch<SetStateAction<boolean>>
}

type CannedServicesProviderProps = {
	children: React.ReactElement | React.ReactElement[]
}

const CanneryServicesContext = createContext<CanneryServicesContextType>(null)

function CannedServicesProvider({ children }: CannedServicesProviderProps) {
	const [cannedServices, setCannedServices] =
		useState<EnterpriseCannedServiceLineItemGroups>(null)
	const [fixedPricinglaborSubtotal, setFixedPricingLaborSubtotal] = useState(0)
	const [laborRateAmount, setLaborRateAmount] = useState(0)
	const [laborRateId, setLaborRateId] = useState(0)
	const [subtotals, setSubtotals] = useState<Record<string, number>>({})

	const { mutate: saveService } = useCannedServiceMutation()

	const { data, isLoading, refetch } = useCannedServiceQuery(data => {
		setTitle(data.title)
		setSelectedShopIds(data.shops.map(shop => shop.id.toString()))
		setFixedPrice(data.fixed_price_cents)
		setIsFixedPrice(data.fixed_price_cents !== null)
		setCategoryId(data.categories[0]?.category_id?.toString())
		setPartsOptimizerEnabled(data.gp_wizard_ok)
		setAutoApplied(data.auto_applied)
		setLaborRateId(data.enterprise_labor_rate.id)
		setLaborRateAmount(toDollars(data.enterprise_labor_rate.amount_cents))
		if (cannedServiceEdited) setCannedServiceEdited(false)
	})

	// Updatable attributes
	const [title, setTitle] = useState<string>('')
	const [isFixedPrice, setIsFixedPrice] = useState(false)
	const [fixedPrice, setFixedPrice] = useState(null)
	const [categoryId, setCategoryId] = useState<string | null>(null)
	const [selectedShopIds, setSelectedShopIds] = useState<string[]>([])
	const [partsOptimizerEnabled, setPartsOptimizerEnabled] = useState(false)
	const [autoApplied, setAutoApplied] = useState(false)
	const [cannedServiceEdited, setCannedServiceEdited] = useState<boolean>(false)

	const value = {
		cannedServices,
		setCannedServices,
		fixedPricinglaborSubtotal,
		setFixedPricingLaborSubtotal,
		laborRateAmount,
		setLaborRateAmount,
		laborRateId,
		setLaborRateId,
		isFixedPrice,
		setIsFixedPrice,
		fixedPrice,
		setFixedPrice,
		subtotals,
		setSubtotals,
		data,
		saveService,
		refetch,
		title,
		setTitle,
		categoryId,
		setCategoryId,
		selectedShopIds,
		setSelectedShopIds,
		partsOptimizerEnabled,
		setPartsOptimizerEnabled,
		autoApplied,
		setAutoApplied,
		cannedServiceEdited,
		setCannedServiceEdited
	}

	const initializeSubtotals = useCallback(() => {
		const hazmatsSubtotal = data[CannedService.HAZMATS].reduce(
			(acc: number, item: EnterpriseCannedServiceHazmat) =>
				acc + Number(item.quantity) * Number(item.fee_cents),
			0
		)
		const laborsSubtotal = data[CannedService.LABORS].reduce(
			(acc: number, item: EnterpriseCannedServiceLabor) =>
				acc +
				Number(item.hours) * Number(data?.enterprise_labor_rate?.amount_cents),
			0
		)
		const subletsSubtotal = data[CannedService.SUBLETS].reduce(
			(acc: number, item: EnterpriseCannedServiceSublet) =>
				acc + Number(item.price_cents),
			0
		)
		const partsSubtotal = data[CannedService.GENERIC_PARTS].reduce(
			(acc: number, item: EnterpriseCannedServiceGenericPart) =>
				acc + Number(item.quantity) * Number(item.price_cents),
			0
		)
		if (data.fixed_price_cents) {
			const laborSubtotal =
				data.fixed_price_cents -
				(hazmatsSubtotal + subletsSubtotal + partsSubtotal)
			setFixedPricingLaborSubtotal(toDollars(laborSubtotal))
		}
		setSubtotals({
			[CannedService.HAZMATS]: toDollars(hazmatsSubtotal),
			[CannedService.LABORS]: toDollars(laborsSubtotal),
			[CannedService.SUBLETS]: toDollars(subletsSubtotal),
			[CannedService.GENERIC_PARTS]: toDollars(partsSubtotal)
		})
	}, [data, setSubtotals])

	useEffect(() => {
		if (!data) return
		if (data.fixed_price_cents != null) {
			setFixedPrice(toDollars(data.fixed_price_cents))
			setIsFixedPrice(true)
		}
		setLaborRateAmount(toDollars(data?.enterprise_labor_rate?.amount_cents))
		initializeSubtotals()
		setCannedServices({
			[CannedService.HAZMATS]: data[CannedService.HAZMATS],
			[CannedService.LABORS]: data[CannedService.LABORS],
			[CannedService.SUBLETS]: data[CannedService.SUBLETS],
			[CannedService.GENERIC_PARTS]: data[CannedService.GENERIC_PARTS],
			[CannedService.INSPECTIONS]: data[CannedService.INSPECTIONS]
		})
	}, [data, initializeSubtotals])

	useEffect(() => {
		const preventUnload = (event: BeforeUnloadEvent) => {
			if (!cannedServiceEdited) return
			const message = 'Are you sure you want to leave?' // Translation is not working/needed here
			event.preventDefault()
			event.returnValue = message
		}
		window.addEventListener('beforeunload', preventUnload)
		return () => {
			window.removeEventListener('beforeunload', preventUnload)
		}
	}, [cannedServiceEdited])

	return (
		<>
			{isLoading || !cannedServices ? (
				<PageLoader />
			) : (
				<CanneryServicesContext.Provider value={value}>
					{children}
				</CanneryServicesContext.Provider>
			)}
		</>
	)
}

function useCanneryServicesContext() {
	const context: CanneryServicesContextType = useContext(CanneryServicesContext)

	if (context === undefined) {
		throw new Error(
			'useCanneryServicesContext must be used within a CanneryServicesProvider'
		)
	}

	return context
}

export { CannedServicesProvider, useCanneryServicesContext }
