export const APPLICATION_ID = 'enterprise-manager'
export const BASE_URL = `${window.location.protocol}//${window.location.host}/`
export const AUTH_APP_URL =
	process.env.REACT_APP_AUTH_APP_WEB_URL || 'https://dev.login.shop-ware.io'
export const SERVICE_BASE_URL =
	process.env.REACT_APP_SERVICE_BASE_URL || 'https://dev.services.shop-ware.io'
export const BASE_MONOLITH_URL =
	process.env.REACT_APP_BASE_MONOLITH_URL || 'https://app.shop-ware.io'
export const LAUNCH_DARKLY_CLIENT_SIDE_ID =
	process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ||
	'628c2a336e296d14d9e8ba65'
export const PUBLIC_ALLOWED_DOMAINS =
	process.env.REACT_APP_PUBLIC_ALLOWED_DOMAINS || ''
export const SST_STAGE = process.env.REACT_APP_SST_STAGE || ''
export const ALTERNATE_WEB_DOMAINS =
	process.env.REACT_APP_ALTERNATE_WEB_DOMAINS || ''
