import { useEffect } from 'react'
import { Popconfirm as AntdPopconfirm } from 'antd'
import type { PopconfirmProps as AntdPopconfirmProps } from 'antd'
import { focusConfirmButton } from 'app/screens/cannedService/helper'

const Popconfirm = ({ ...props }: AntdPopconfirmProps) => {
	useEffect(() => {
		if (props.open) {
			focusConfirmButton()
		}
	}, [props.open])

	return <AntdPopconfirm destroyTooltipOnHide={true} {...props} />
}

export default Popconfirm
